import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var bookingFilterRes;
export const bookingFilterTodo = createAsyncThunk('bookingFilter', async (data) => {

    console.log("data", data)

    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'booking_list',
            data: {
                "userid": data ? data.values.user && data.values.user ? data.values.user : '' : '',
                "projectid": data ? data.values.projectName && data.values.projectName ? data.values.projectName : '' : '',
                "propertyid": data ? data.values.propertyNumber && data.values.propertyNumber ? data.values.propertyNumber : '' : '',
                "customerid": data ? data.values.customer && data.values.customer ? data.values.customer : '' : '',
                "startDate": data ? data.fromdate : '',
                // "startDate": '',
                // "endDate": '',
                "endDate": data ? data.enddate : '',
                "bstatus": data ? data.values.status : '',
                "cid": '',
                "rejectednot":''

            },

            headers: { "Content-Type": "application/json" }
        })
        return bookingFilterRes = res.data

    } catch (error) {
        return bookingFilterRes = error.response.data
    }
});

const bookingFilterSlice = createSlice({
    name: 'bookingFilter',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(bookingFilterTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(bookingFilterTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default bookingFilterSlice.reducer; 
