import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var userListRes;
export const userListTodo = createAsyncThunk('userList', async () => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'users_list',
            data:{
                
                    "type": ""
                
            },
           
            headers: { "Content-Type": "application/json" }
        })
        return userListRes = res.data

    } catch (error) {
        return userListRes = error.response.data
    }
});

const userListSlice = createSlice({
    name: 'userList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(userListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(userListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default userListSlice.reducer; 