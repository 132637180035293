import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var updateCommissionTargetRes;
export const updateCommissionTargetTodo = createAsyncThunk('updateCommissionTarget', async (data) => {
    console.log("data--", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'update_target',
            data: data,
            headers: { "Content-Type": "application/json" }
        })
        return updateCommissionTargetRes = res.data

    } catch (error) {
        return updateCommissionTargetRes = error.response.data
    }
})