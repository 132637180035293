import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var userLoginRes;
export const userLoginTodo = createAsyncThunk('userLogin', async (data) => {

    // console.log("+id+", data)

    const res = await axios({
        method: "post",
        // mode: 'cors',
        url: process.env.REACT_APP_API_URL + 'adminuser_login',
        data: {
            "username": data.username,
            "password": data.password
        },
        crossDomain: true,
        headers: { "Content-Type": "application/json" }
    })
    return userLoginRes = res.data
})


const userLoginSlice = createSlice({
    name: 'userLogin',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(userLoginTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userLoginTodo', state.data.response)
        });
        builder.addCase(userLoginTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default userLoginSlice.reducer