
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { UserEmployeListRes, UserEmployeListTodo } from '../../redux/slices/user/UserEmployeList';
import { employeDesignationTodo } from '../../redux/slices/employeDesignation';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { userDeleteRes, userDeleteTodo, userInactiveRes, userInactiveTodo } from '../../redux/slices/user/UserInactiveDelete';
import { toast } from 'react-toastify';
import { userInactiveSchema } from '../../schemas';
import { BallTriangle } from 'react-loader-spinner';

export default function UserDeletePopup(props) {
    const { userDelete, setUserDelete, userData, bool, setBool } = props;
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const userId = userData && userData.id
    const userEmployeData = useSelector(state => state && state.userEmployeList && state.userEmployeList.data && state.userEmployeList.data.data)
    // console.log(userEmployeData, 'datatab')

    const initialValues = {
        data: userEmployeData && userEmployeData.map(val => (
            {
                "user_id": val && val.downUser && val.downUser.id,
                "user_name": val && val.downUser && val.downUser.username,
                "designation_name": val && val.downUser && val.downUser.master_designation && val.downUser.master_designation.designation,
                "employer_id": "",
                "employee_data": val && val.UpUser,
            }
        ))
    }

    // console.log(initialValues, 'initial')

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: userInactiveSchema,
        enableReinitialize: true,

        onSubmit: (values, action) => {
            const formData = values.data.map(item => ({
                user_id: item.user_id.toString(),
                employer_id: item.employer_id
            }));
            // console.log("new", formData)
            dispatch(userDeleteTodo({ 'values': formData, "userId": userId })).then(() => userInctive_res())
            setLoading(true)
            action.resetForm();

        },
    })

    const userInctive_res = () => {
        if (userDeleteRes && userDeleteRes.success) {
            toast.success(userDeleteRes && userDeleteRes.message, { position: 'bottom-right' })
            setTimeout(() => {
                setUserDelete(false);
            }, 1000);
            setBool(!bool)
            setLoading(false)
        } else {
            toast.error(userDeleteRes && userDeleteRes.message, { position: 'bottom-right' })
        }
    }

    useEffect(() => {
        setLoading(true)
        dispatch(UserEmployeListTodo(userId)).then(() => useremploy_res())
    }, [userDelete == true])

    const useremploy_res = () => {
        if (UserEmployeListRes && UserEmployeListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    return (
        <>
        <div className={userDelete ? 'modal fade show d-block' : 'modal fade show d-none'} style={{ background: 'rgba(0,0,0,0.8)' }}>
                <div class="modal-dialog  modal-dialog-center modal-lg">
                    <div class="modal-content pt-3 pb-5 ps-3 pe-3">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel"></h5>
                            <h5 style={{ color: "#105684" }} className='text-center'>User Delete </h5>
                            <button type="button" class="btn-close" onClick={() => { setUserDelete(false); }}></button>
                        </div>
                        <div class="modal-body ">
                            {loading ?
                                <div className='d-flex justify-content-center mt-5'>
                                    <BallTriangle
                                        height={100}
                                        width={100}
                                        radius={5}
                                        color="#105684"
                                        ariaLabel="ball-triangle-loading"
                                        wrapperclassName="justify-content-center"
                                        wrapperStyle={{}}
                                        visible={true}
                                    />
                                </div>
                                :

                                <FormikProvider value={formik}>
                                    <div className=''>
                                        <form onSubmit={(e) => { e.preventDefault(); formik.handleSubmit(); }}>
                                            {userEmployeData && userEmployeData.length ?
                                                <FieldArray
                                                    name="data"
                                                    render={(arrayHelpers) => (
                                                        <>
                                                            {formik && formik.values && formik.values.data && formik.values.data.map((field, index) => (

                                                                <div className="row" key={index}>
                                                                    <div className="col-md-4 mb-3" key={index}>
                                                                        {index == 0 ?
                                                                            <label htmlFor="user_name" style={{ color: "#105684", fontSize: '16px' }} className="form-label gh_label">User</label>
                                                                            : ''}
                                                                        <input type="text"
                                                                            className="form-control gh_form"
                                                                            name={`data[${index}].user_name`}
                                                                            value={formik.values.data[index].user_name}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            readOnly
                                                                        />
                                                                    </div>

                                                                    <div className="col-md-4 mb-3" key={index}>
                                                                        {index == 0 ?
                                                                            <label htmlFor="designation_name" style={{ color: "#105684", fontSize: '16px' }} className="form-label gh_label">Designation</label>
                                                                            : ''}
                                                                        <input type="text"
                                                                            className="form-control gh_form"
                                                                            name={`data[${index}].designation_name`}
                                                                            value={formik.values.data[index].designation_name}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            readOnly

                                                                        />
                                                                    </div>

                                                                    <div className="col-md-4 mb-3" key={index}>
                                                                        {index == 0 ?
                                                                            <label htmlFor="employer_id" style={{ color: "#105684", fontSize: '16px' }} className="form-label gh_label">Select Employee</label>
                                                                            : ''}

                                                                        <select className="form-select gh_form"
                                                                            name={`data[${index}].employer_id`}
                                                                            value={formik.values.data[index].employer_id}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                        >
                                                                            <option value=''>Select</option>
                                                                            {formik.values.data[index].employee_data && formik.values.data[index].employee_data.map((value, index) =>
                                                                                <option key={index} value={value.id}>{value.username}</option>
                                                                            )}
                                                                        </select>
                                                                        {formik.touched.data && formik.touched.data[index] && formik.touched.data[index].employer_id && formik.errors.data && formik.errors.data[index] && formik.errors.data[index].employer_id ?
                                                                            (<span className='text-danger form_label'> {formik.errors.data[index].employer_id}</span>) : null
                                                                        }
                                                                    </div>

                                                                </div>


                                                            ))}
                                                        </>
                                                    )}
                                                /> :
                                                <div className='d-flex justify-content-center mt-5'>
                                                    <h4 >No Team Member Found </h4>
                                                </div>}

                                            <div className='mt-3 d-flex justify-content-end'>
                                                <button type='submit' className='btn btn-success gh_btn'>Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </FormikProvider>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
