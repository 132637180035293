import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { userBookingListRes, userBookingListTodo } from '../../redux/slices/user/userBookingList';
import DataTbl from '../../table/table';
import { useNavigate } from 'react-router';
import { FiMonitor } from "react-icons/fi";
import { BallTriangle } from 'react-loader-spinner'
import { userEarningListRes, userEarningListTodo } from '../../redux/slices/user/userEarningList';


const UserBookingList = () => {

    const state = useLocation()
    var userId = state && state.state && state.state.id

    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();
    const userDetailsLink = (value) => {
        navigate('../BookingDetails', { state: value })
    }
    const dispatch = useDispatch();
    const data = useSelector(state => state && state.userEarningList && state.userEarningList.data && state.userEarningList.data.data && state.userEarningList.data.data.rows)
    const totalBookingData = useSelector(state => state && state.userBookingList && state.userBookingList.data && state.userBookingList.data.data && state.userBookingList.data.data[2])
    console.log("data", data)

    const totalCharges = data && data.reduce((acc, val) => {
        // console.log("acc",acc)
        const rate = parseFloat(val.commission_amt) || 0;
        const dealRate = parseFloat(val.booking_master.booking_amount) || 0;
        // const quantity = parseFloat(val.quantity) || 0;

        return {
            "total_cummission": acc.total_cummission + rate,
            "total_dealAmt": acc.total_dealAmt + dealRate,
            // "total_dealAmt": acc.sub_total + grand_subTotal,
        }
        return acc
    }
        ,
        {
            total_cummission: 0,
            total_dealAmt: 0,

        }
    )
    console.log("totalCharges", totalCharges)


    const exportcolumn = ['booking_master.project_master.project_name', 'booking_master.customer.name', "fk_property_id", "booking_master.booking_amount", 'commission_amt', "commission_percent", "added_date", "booking_master.booking_status"]

    const [columnDefs1, setColumnDefs1] = useState([
        {
            headerName: 'S.No.',
            valueGetter: 'node.rowIndex + 1',
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '100px'
        },
        {
            headerName: "Project Name",
            field: "booking_master.project_master.project_name",
            wrapText: true,
            cellRenderer: params => <span style={{ textTransform: 'capitalize' }}>{params && params.data && params.data.booking_master && params && params.data && params.data.booking_master && params.data.booking_master.project_master && params.data.booking_master.project_master.project_name}</span>,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '200px'
        },

        {
            headerName: "Customer",
            field: "booking_master.customer.name",
            cellRenderer: params => <span style={{ textTransform: 'capitalize' }}>{params && params.data && params.data.booking_master && params && params.data && params.data.booking_master && params.data.booking_master.customer && params.data.booking_master.customer.name}</span>,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '150px'
        },
        {
            headerName: "Plot No.",
            field: "property_detail.property_number",
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '100px'
        },
        {
            headerName: "Deal Amount",
            field: "booking_master.booking_amount",
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: 120
        },
        {
            headerName: "Commission Amount",
            field: "commission_amt",
            // cellRenderer: params => params && params.data && params.data.booking_receipt && params.data.booking_receipt.receipt_amt,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: 130
        },
        {
            headerName: "Commission ",
            field: "commission_percent",
            cellRenderer: params => <span>{params && params.data && params.data && params.data.commission_percent}%</span>
            ,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '130px'
        },
        {
            headerName: "Booking Date",
            field: "booking_master.booking_date",
            cellRenderer: params => params?.data?.booking_master?.booking_date === null ? '-': <span style={{ textTransform: 'capitalize' }}>{new Date(params?.data?.booking_master?.booking_date).toLocaleDateString('en-GB')}</span>,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '130px'
        },

        {
            headerName: "Receipt Date",
            cellRenderer: params => new Date(params && params.data && params.data.added_date).toLocaleDateString('en-GB'),
            field: "added_date",
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: 130
        },

        {
            headerName: "Booking Status",
            field: "booking_master.booking_status",
            wrapText: true,
            cellRenderer: params => <span style={{ textTransform: "capitalize" }} className={params && params.data && params.data.booking_master && params.data.booking_master.booking_status === 'available' ? 'status_availabel' : (params && params.data && params.data.booking_master && params.data.booking_master.booking_status) === 'hold' ? 'status_hold' : (params && params.data && params.data.booking_master && params.data.booking_master.booking_status) === 'booked' ? 'status_booked' : (params && params.data && params.data.booking_master && params.data.booking_master.booking_status) === 'rejected' ? 'status_rejected' : ''} >
                {params && params.data && params.data.booking_master && params.data.booking_master.booking_status}</span>,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '150px'
        },

        {
            headerName: "Action",
            wrapText: true,
            cellRenderer: params => <div className='viewEdit_btn'>

                {/* <span><BiSolidEdit /></span> */}
                <span onClick={() => userDetailsLink(params.data)}><FiMonitor /></span>

            </div>,
            autoHeight: true,
            width: '130px',
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },

    ])

    const userBookingList = () => {
        if (userEarningListRes && userEarningListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)

        }
    }

    useEffect(() => {
        setLoading(true)
        dispatch(userEarningListTodo(userId)).then(() => userBookingList())
        dispatch(userBookingListTodo(userId))
    }, [])

    return (
        <>
            <div className="container-fluid">
                {loading ?

                    <div className='d-flex justify-content-center mt-5'>
                        <BallTriangle
                            height={100}
                            width={100}
                            radius={5}
                            color="#105684"
                            ariaLabel="ball-triangle-loading"
                            wrapperclassName="justify-content-center"
                            wrapperStyle={{}}
                            visible={true}
                        />
                    </div>

                    :
                    <div>

                        <div className="row">

                            <div className="col-md-4">
                                <div className="card totl_review_section text-center " >
                                    <div className='d-flex justify-content-between align-items-center '>
                                        <p>Total Booking</p>
                                        <span>{data?.length}</span>
                                    </div>
                                </div>

                            </div>

                            <div className="col-md-4">
                                <div className="card totl_review_section text-center " >
                                    <div className='d-flex justify-content-between align-items-center '>
                                        <p>Total Deal </p>
                                        <span>{totalCharges?.total_dealAmt}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card totl_review_section text-center " >
                                    <div className='d-flex justify-content-between align-items-center '>
                                        <p>Total Comm. </p>
                                        <span>{totalCharges?.total_cummission}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {data && data.length ?
                            <div className="row">
                                <div className="col-md-12 mt-2">

                                    <div className=''>
                                        <DataTbl
                                            data={data ? data : ''}
                                            columnDefs={columnDefs1}
                                            exportcolumn={exportcolumn}
                                        />
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='empty_data_msg text-center mt-5'>
                                <h4>User Booking List Not Found</h4>
                            </div>}
                    </div>
                }
            </div>
        </>
    );
}

export default UserBookingList;
