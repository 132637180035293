import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addCommissionRes;
export const addCommissionTodo = createAsyncThunk('addCommission', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'create_commission',
            data: { 
                "target_id":data.target,
                "designation_id":data.designation,
                "designation_uid":data.user_designation,
                "commission":data.commission,
                "status":"active",
                "added_by":JSON.parse(localStorage.getItem('user_data')).userId
            },
            headers: { "Content-Type": "application/json" }
        })
        return addCommissionRes = res.data

    } catch (error) {
        return addCommissionRes = error.response.data
    }
})