import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";



export var addProjectRes;
export const addProjectTodo = createAsyncThunk('updateProject', async (data) => {

    console.log("UpdateProject--", data)

    const res = await axios({
        method: "post",
        // mode: 'cors',
        url: process.env.REACT_APP_API_URL + 'create_project',
        data: data,
        crossDomain: true,
        headers: {  "Content-Type": "multipart/form-data"}
    })
    return addProjectRes = res.data
})

