import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var userInactiveRes;
export const userInactiveTodo = createAsyncThunk('userInactive', async (data) => {

    // console.log("userInactive", data)

    const res = await axios({
        method: "post",
        // mode: 'cors',
        url: process.env.REACT_APP_API_URL + 'user_inactive_delete',
        data: {
            "userid":data.userId.toString(),
            "data":data.values,
            "status": "inactive"
    },
        crossDomain: true,
        headers: { "Content-Type": "application/json" }
    })
return userInactiveRes = res.data
})


export var userDeleteRes;
export const userDeleteTodo = createAsyncThunk('userDelete', async (data) => {

    // console.log("userDelete", data)

    const res = await axios({
        method: "post",
        // mode: 'cors',
        url: process.env.REACT_APP_API_URL + 'user_inactive_delete',
        data: {
            "userid":data.userId.toString(),
            "data":data.values,
            "status": "deleted"
    },
        crossDomain: true,
        headers: { "Content-Type": "application/json" }
    })
return userDeleteRes = res.data
})