import './App.css';
import '/node_modules/bootstrap/dist/css/bootstrap.css'
import '/node_modules/bootstrap/dist/js/bootstrap.js'
import '../src/gaganHoms_css/login.css'
import '../src/gaganHoms_css/dashboard.css'
import '../src/gaganHoms_css/privacy_policy.css'
import Login from './login/login';
import { Routes, Route } from 'react-router';
import Dashboard from './gaganHomes_view/dashboard';
import ProtectedRoutes from './ProtectedRoute';
import AddProject from './gaganHomes_view/project/addProject';
import Property from './gaganHomes_view/Property/property';
import ProjectList from './gaganHomes_view/project/projectList';
import AddUser from './gaganHomes_view/User/AddUser';
import UserList from './gaganHomes_view/User/UserList';
import AddProperty from './gaganHomes_view/Property/AddProperty';
import PropertyList from './gaganHomes_view/Property/PropertyList';
import CommissionList from './gaganHomes_view/Commission/CommissionList';
import AddCommission from './gaganHomes_view/Commission/AddCommission';
import BookingList from './gaganHomes_view/Booking/BookingList';
import ProjectDetails from './gaganHomes_view/project/projectDetails';
import PropertyDetails from './gaganHomes_view/Property/propertyDetails';
import UserDetails from './gaganHomes_view/User/userDetails';
import CommissionDetails from './gaganHomes_view/Commission/commisionDetails';
import BookingDetails from './gaganHomes_view/Booking/bookingDetails';
import AddForm from './gaganHomes_view/Property/addForm';
import UpdateProject from './gaganHomes_view/project/updateProject';
import AddProjectCommission from './gaganHomes_view/project/addProjectCommission';
import UpdateUser from './gaganHomes_view/User/updateUser';
import CommissionListUpdate from './gaganHomes_view/Commission/CommissionListUpdate';
import CustomerList from './gaganHomes_view/User/customerList';
import ReportSection from './gaganHomes_view/Booking/reportSection';
import CustomerDetails from './gaganHomes_view/User/customerDetails';
import MyCommission from './gaganHomes_view/Booking/myCommission';
import GaganHomes_PrivacyPolicy from './gaganHomes_view/GaganHomes_PrivacyPolicy';
import TeamTree from './gaganHomes_view/TeamTree';
import UpdateCustomer from './gaganHomes_view/User/UpdateCustomer';




function App() {
  return (
    <>
      <Routes>
        <Route path="/Login" element={<Login />} />
        <Route path='/privacy-policy' element={<GaganHomes_PrivacyPolicy/>} />
        <Route path='/' element={<ProtectedRoutes />} >
          <Route path="/" element={<Dashboard />} />
          <Route path='/Dashboard' element={<Dashboard />} />
          <Route path='/AddProject' element={<AddProject />} />
          <Route path='/Property' element={<Property />} />
          <Route path='/ProjectList' element={<ProjectList />} />
          <Route path='/addProjectCommssion' element={<AddProjectCommission/>} />
          <Route path='/AddUser' element={<AddUser />} />
          <Route path='/userlist' element={<UserList />} />
          <Route path='/addproperty' element={<AddProperty />} />
          <Route path='/propertylist' element={<PropertyList />} />
          {/* <Route path='/commissionlist' element={<CommissionList />} /> */}
          <Route path='/commissionlist' element={<CommissionListUpdate/>} />
          <Route path='/addcommission' element={<AddCommission />} />
          <Route path='/bookinglist' element={<BookingList />} />
          <Route path='/projectDetails' element={<ProjectDetails />} />
          <Route path='/propertyDetails' element={<PropertyDetails />} />
          <Route path='/UserDetails' element={<UserDetails />} />
          <Route path='/CommissionDetails' element={<CommissionDetails />} />
          <Route path='/BookingDetails' element={<BookingDetails />} />
          <Route path='/AddForm' element={<AddForm/>} />
          <Route path='/UpdateProject' element={<UpdateProject/>} />
          <Route path='/UpdateUser' element={<UpdateUser/>} />
          <Route path='/customerList' element={<CustomerList/>} />
          <Route path='/update-customer' element={<UpdateCustomer/>} />
          <Route path='/customerDetails' element={<CustomerDetails/>} />
          <Route path='/reportSection' element={<ReportSection/>} />
          <Route path='/myCommission' element={<MyCommission/>} />
          <Route path='/team-tree' element={<TeamTree/>} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
