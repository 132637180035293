import { useFormik } from 'formik';
import React from 'react'
import { updateDealAmtSchema } from '../../schemas';
import { useDispatch } from 'react-redux';
import { updateDealAmtRes, updateDealAmtTodo } from '../../redux/slices/booking/updateDealAmt';
import { toast } from 'react-toastify';

export default function UpdateDealAmount(props) {
    const { dealAmt, setDealAmt ,bAmt } = props;
    const { bool, setBool } = props;
    var bookingData = props.bookingData;
    const dispatch = useDispatch()
    console.log("bAmt", bAmt)

    var booking_id = bookingData && bookingData.id
    var project_id = bookingData && bookingData.fk_project_id
    var property_id = bookingData && bookingData.fk_property_id

    const initialValues = {
        deal_amt: bAmt && bAmt,
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: updateDealAmtSchema,
        enableReinitialize: true,
        onSubmit: (values, action) => {
            dispatch(updateDealAmtTodo({values,booking_id,project_id,property_id})).then(() => dealAmt_res())
        },
    })

    const dealAmt_res = () => {
        if (updateDealAmtRes && updateDealAmtRes.success) {
            toast.success(updateDealAmtRes && updateDealAmtRes.message, { position: "bottom-right" })
            setBool(!bool)
            setTimeout(() => {
                setDealAmt(false)
            }, 1000);
        } else {
            toast.error(updateDealAmtRes && updateDealAmtRes.message, { position: "bottom-right" })
            setBool(!bool)
        }
    }

    return (
        <>
            <div className="container-fuild">
                <div className={dealAmt ? 'modal fade show d-block' : 'modal fade show d-none'} style={{ background: 'rgba(0,0,0,0.8)' }}>
                    <div class="modal-dialog  modal-dialog-centered modal-md">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel"></h5>
                                <h5 style={{ color: "#105684" }} className='text-center'>Update Deal Amount</h5>
                                <button type="button" class="btn-close" onClick={() => { setDealAmt(false); }}></button>
                            </div>
                            <div class="modal-body p-4">
                                <form onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <div className="col-md-12 gh_formWrapper">
                                            <label htmlFor="deal_amt" className="form-label gh_label">Deal Amount </label>
                                            <input type="text"
                                                className="form-control gh_form"
                                                name='deal_amt'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.deal_amt}
                                            />
                                            {errors.deal_amt && touched.deal_amt ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.deal_amt}</span>) : null}
                                        </div>
                                    </div>
                                    <div className='mt-3 d-flex justify-content-end'>
                                        <button type='submit' className='btn btn-success gh_btn'>Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
