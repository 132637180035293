import React from 'react';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { projectListTodo } from '../../redux/slices/projectList';
import { addPropertyRes, addPropertyTodo } from '../../redux/slices/addProperty';
import { ToastContainer, toast } from 'react-toastify';
import { addPropertyValidation } from '../../schemas';

const AddProperty = (props) => {

    const { addProperty, setAddProperty } = props;
    const projectId = props.proId;
    const { bool, setBool } = props;
    // console.log("propsssss",projectId)
    const dispatch = useDispatch()

    var added_by = JSON.parse(localStorage.getItem('user_data')).userId

    // console.log("added_by", added_by)

    const initialValues = {

        property_num: '',
        property_width: '',
        property_length: '',
        property_cost: '',
    };




    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: addPropertyValidation,
        onSubmit: (values, action) => {
            console.log(values)
            dispatch(addPropertyTodo({values,projectId,added_by})).then(() => addPropertyfun())
            action.resetForm();
        },
    })


    const addPropertyfun = () => {
        if (addPropertyRes && addPropertyRes.success) {
            toast.success(addPropertyRes && addPropertyRes.message, { position: "bottom-right" });
            setBool(!bool)
            setAddProperty(false)
        } else {
            toast.error(addPropertyRes && addPropertyRes.message, { position: "bottom-right" });
        }
    }



    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className={addProperty ? 'modal fade show d-block' : 'modal fade show d-none'} style={{ background: 'rgba(0,0,0,0.8)' }}>
                            <div class="modal-dialog  modal-dialog-centered modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel"></h5>
                                        <h5 style={{ color: "#105684" }} className='text-center'>Add Plot </h5>
                                        <button type="button" class="btn-close" onClick={() => { setAddProperty(false); }}></button>
                                    </div>
                                    <div class="modal-body ps-5 pe-5 pe-2 pb-2">
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-6 gh_formWrapper">
                                                    <label htmlFor="property_num" className="form-label gh_label">Plot Number</label>
                                                    <input type="text"
                                                        className="form-control gh_form"
                                                        name='property_num'
                                                        id="property_num"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.property_num}
                                                    />
                                                    {errors.property_num && touched.property_num ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.property_num}</span>) : null}
                                                </div>
                                                <div className="col-6 mt-2gh_formWrapper">
                                                    <label htmlFor="property_length" className="form-label gh_label">Plot Length</label>
                                                    <input type="text"
                                                        className="form-control gh_form"
                                                        name='property_length'
                                                        id="property_length"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.property_length}
                                                    />

                                                    {errors.property_length && touched.property_length ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.property_length}</span>) : null}
                                                </div>
                                                <div className="col-6 gh_formWrapper">
                                                    <label htmlFor="property_width" className="form-label gh_label">Plot Width</label>
                                                    <input type="text"
                                                        className="form-control gh_form"
                                                        name='property_width'
                                                        id="property_width"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.property_width}
                                                    />

                                                    {errors.property_width && touched.property_width ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.property_width}</span>) : null}
                                                </div>

                                                <div className="col-6 mt-2 gh_formWrapper">
                                                    <label htmlFor="property_cost" className="form-label gh_label">Cost</label>
                                                    <input type="text"
                                                        className="form-control gh_form"
                                                        name='property_cost'
                                                        id="property_cost"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.property_cost}

                                                    />

                                                    {errors.property_cost && touched.property_cost ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.property_cost}</span>) : null}
                                                </div>
                                                <div className='text-end mt-2'>
                                                    <button type='submit' className='btn btn-success gh_btn'>Submit</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <ToastContainer /> */}
        </>
    );
}

export default AddProperty;
