import React from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'

export default function GaganHomes_PrivacyPolicy() {
    return (
        <>
            <div className="container-fluid">
                <div className="row ">
                    <div className="col-md-12 p-0">
                        <div className='gagan_privacy_header'>
                            <div className='text-start sidebar-logo-section'>
                                <img src='../gagan_home_favicon_png.png' alt="" className='text-center img-fluid sidebar_logo ' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row p-3">
                    <div className="col-md-12">
                        <div className='form_head'  >
                            <h4 style={{ color: "#105684" }}>Privacy Policy </h4>
                        </div>
                        <div className='CRM_view'>
                            <PerfectScrollbar>
                                <div class="theme-title-three  pb-150 p-4">
                                    <h2 class="main-title">Privacy Policy</h2>
                                    <p>This Privacy Policy describes our applications' policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You. </p>
                                    <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we're collecting it and how it will be used.We don't share any personally identifying information publicly or with third-parties, except when required by law. </p>
                                    <p> Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ from those from Your jurisdiction. </p>
                                    <p> The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies. </p>
                                    <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of Your data and other personal information. </p>
                                    <p> If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
                                        Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
                                        The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security. </p>
                                    <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy. </p>
                                    <p> You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page. </p>
                                    <p>This policy is effective from the date 28.11.2022. </p>

                                </div>
                            </PerfectScrollbar>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
