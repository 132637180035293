import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var updateDealAmtRes;
export const updateDealAmtTodo = createAsyncThunk('updateDealAmt', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'update_booking_amount',
            data: {
                "booking_id":data && data.booking_id,
                "project_id":data && data.project_id,
                "property_id":data && data.property_id,
                "booking_amount":data && data.values && data.values.deal_amt,
                "modified_by": JSON.parse(localStorage.getItem('user_data')).userId
            },
            headers: { "Content-Type": "application/json" }
        })
        return updateDealAmtRes = res.data

    } catch (error) {
        return updateDealAmtRes = error.response.data
    }
});

