import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var stateListRes;
export const stateListTodo = createAsyncThunk('stateList', async (data) => {
    console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'booking_list',
            data: {
                "cid":data.cid
            },
            headers: { "Content-Type": "application/json" }
        })
        return stateListRes = res.data

    } catch (error) {
        return stateListRes = error.response.data
    }
});

const stateListSlice = createSlice({
    name: 'stateList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(stateListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(stateListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default stateListSlice.reducer; 
