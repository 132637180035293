import * as Yup from "yup";

export const addProjectValidation = Yup.object({
    project_name: Yup.string().required("Please Enter Project Name"),
    // project_description: Yup.string().required("Please Enter Project Description"),
    // project_size: Yup.string().required("Please Enter size"),
    // company_name: Yup.string().required("Please Enter Company Name"),
    // number: Yup.string().required("Please Enter Rera Number"),
    project_status: Yup.string().required("Please Select Status"),
    total_plots: Yup.string().required("Please Enter Plots"),
    // locality: Yup.string().required("Please Enter Location"),
    // sub_locality: Yup.string().required("Please Enter Sub Locality"),
    // street: Yup.string().required("Please Enter Street"),
    // latitude: Yup.string().min(2).max(25).required("Please Enter Latitude"),
    // longitude: Yup.string().required("Please Enter Longitude"),
    project_image: Yup.string().required("Select Project Image"),
    // property_image: Yup.string().min(2).max(55).required("Select Property Image"),
    state_list: Yup.string().required("Select State List"),
    city_list: Yup.string().required("Select City List"),
})

export const addPropertyValidation = Yup.object({

    property_num: Yup.string().required("Please Enter Property Number"),
    // property_size: Yup.string().required("Please Enter Property Size"),
    property_width: Yup.string().required("Please Enter Property Width"),
    property_length: Yup.string().required("Please Enter Property Length"),
    property_cost: Yup.string().required("Please Enter Property Cost"),
  
})

export const addUserValidation = Yup.object({
    user_name: Yup.string().required("Please Enter User Name"),
    mobile_num: Yup.string().required("Please Enter Mobile Number"),
    // dob: Yup.string().required("Please Enter DOB"),
    // email: Yup.string().required("Please Enter Email"),
    // password: Yup.string().required("Please Enter Password"),
    designation_id: Yup.string().required("Please Select Designation"),
    employer_id: Yup.string().required("Please Select Employer"),
    // state: Yup.string().required("Please Select State"),
    // city: Yup.string().required("Please Select City"),
    // address: Yup.string().required("Please Enter Address"),
    gender: Yup.string().required("Please Select Gender"),
})

export const updateUserValidation = Yup.object({
    user_name: Yup.string().required("Please Enter User Name"),
    mobile_num: Yup.string().required("Please Enter Mobile Number"),
    // dob: Yup.string().required("Please Enter DOB"),
    // email: Yup.string().required("Please Enter Email"),
    designation_id: Yup.string().required("Please Select Designation"),
    employer_id: Yup.string().required("Please Select Employer"),
    // state: Yup.string().required("Please Select State"),
    // city: Yup.string().required("Please Select City"),
    // address: Yup.string().required("Please Enter Address"),
    gender: Yup.string().required("Please Select Gender"),
})
export const updateCustomerValidation = Yup.object({
    customer_name: Yup.string().required("Please Enter Customer Name"),
    mobile_num: Yup.string().required("Please Enter Mobile Number"),
    // email: Yup.string().required("Please Enter Email"),
    state: Yup.string().required("Please Select State"),
    city: Yup.string().required("Please Select City"),

})

export const addCommissionValidation = Yup.object({
    target: Yup.string().required("Please Select Target"),
    designation: Yup.string().required("Please Select Designation"),
    user_designation: Yup.string().required("Please Select User Designation"),
    commission: Yup.string().required("Please Enter Commission"),
})

export const userPasswordSchema = Yup.object({
    new_psw: Yup.string().required('Password is required'),
    confirm_psw: Yup.string()
       .oneOf([Yup.ref('new_psw'), null], 'Passwords must match')
}) 

export const bookingInstallmentSchema = Yup.object({
    recipet_amt: Yup.string().required("Please Enter Amount"),
    date: Yup.string().required("Please Select Date "),
    payment_typ: Yup.string().required("Please Select  Payment Type"),
    // payment_status: Yup.string().required("Please Select  Payment Status"),
}) 

export const reportSection = Yup.object({
    projectName: Yup.string().required("Please Enter Project Name"),
    propertyNumber: Yup.string().required("Please Enter Property Name "),
    customer: Yup.string().required("Please Enter Customer"),
    user: Yup.string().required("Please Enter User Name"),
    status: Yup.string().required("Please Select  Status"),
    // payment_status: Yup.string().required("Please Select  Payment Status"),
}) 

export const updateCommissionTargetSchema = Yup.object().shape({
    data: Yup.array().of(
        Yup.object().shape({
            from_target: Yup.number().required("Enter Target"),
            to_target: Yup.number().required("Enter Target"),
        
        })
    )
})

export const userInactiveSchema = Yup.object().shape({
    data: Yup.array().of(
        Yup.object().shape({
            employer_id: Yup.string().required(" Select Employe"),
        
        })
    )
})

export const adminPasswordSchema = Yup.object({
    new_psw: Yup.string().required('Password is required'),
    confirm_psw: Yup.string()
    .oneOf([Yup.ref('new_psw'), null], 'Passwords must match')

}) 

export const updateDealAmtSchema = Yup.object({
    deal_amt: Yup.string().required("Please Enter Deal Amount"),
}) 
export const updateReciptDateSchema = Yup.object({
    // recipet_amt: Yup.string().required("Please Enter Amount"),
    recpt_date: Yup.string().required("Please Select Date "),
    payment_typ: Yup.string().required("Please Select  Payment Type"),
}) 

export const userActiveSchema = Yup.object({
    employe_id: Yup.string().required(" Select Employe"),
})