import React, { useEffect, useState } from 'react';
import DataTbl from '../../table/table';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { bookingInstallmentListRes, bookingInstallmentListTodo } from '../../redux/slices/booking/bookingInstallmentList';
import { BallTriangle } from 'react-loader-spinner'
import { BiSolidEdit } from 'react-icons/bi';
import ReceiptDateUpdate from './ReceiptDateUpdate';


const BookingInstallmentList = (props) => {
    const [ bool, setBool ] = useState(false);
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()
    const state = useLocation()
    var bookingId = state && state.state && state.state.id
    const data = useSelector(state => state && state.bookingInstallmentList && state.bookingInstallmentList.data && state.bookingInstallmentList.data.data && state.bookingInstallmentList.data.data.receipt_data)
    // console.log("inst",data)
    const [recieptDate, setRecieptDate] = useState(false)
    const [installmentData, setInstallmentData] = useState('')

    const receiptDateupd = (data) => {
        setRecieptDate(true)
        setInstallmentData(data)
    }

    const exportcolumn = ['receipt_amt', 'transaction_id', "receipt_date", "type", "status"]

    const [columnDefs1, setColumnDefs1] = useState([
        {
            headerName: 'S.No.',
            valueGetter: 'node.rowIndex + 1',
            wrapText: true,
            maxWidth: 90,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '200px'
        },
        {
            headerName: "Receipt Amount",
            field: "receipt_amt",
            cellRenderer: params => params && params.data && params.data.receipt_amt,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '160px'
        },
        {
            headerName: "Transaction Id",
            field: "transaction_id",
            cellRenderer: params => params && params.data && params.data && params.data.transaction_id
            ,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '150px'
        },
        {
            headerName: "Receipt Date",
            cellRenderer: params => new Date(params && params.data && params.data && params.data.receipt_date).toLocaleDateString('en-GB'),
            field: "receipt_date",
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '150px'
        },
        {
            headerName: "Payment Type",
            cellRenderer: params => params && params.data && params.data && params.data.type,
            field: "type",
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '150px'
        },
        {
            headerName: "Status",
            field: "status",
            cellRenderer: params => <span style={{ textTransform: "capitalize" }} className={params.data && params.data.status === 'pending' ? 'status_hold' : params.data && params.data.status === 'rejected' ? 'status_rejected' : params.data && params.data.status === 'paid' ? 'status_booked' : ''} >
                {params && params.data && params.data && params.data.status}</span>,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '120px'
        },
        {
            headerName: "Action",
            wrapText: true,
            cellRenderer: params => <div className='viewEdit_btn'>
                <span ><BiSolidEdit onClick={() => receiptDateupd(params.data)} style={{ color: '#105684', cursor: "pointer" }} /></span>

            </div>,
            autoHeight: true,
            width: '100px',
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },

    ])

    useEffect(() => {
        setLoading(true)
        dispatch(bookingInstallmentListTodo(bookingId)).then(() => bookinstInstallment())
    }, [bool])

    const bookinstInstallment = () => {
        if (bookingInstallmentListRes && bookingInstallmentListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)

        }
    }


    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        {loading ?

                            <div className='d-flex justify-content-center mt-5'>
                                <BallTriangle
                                    height={100}
                                    width={100}
                                    radius={5}
                                    color="#105684"
                                    ariaLabel="ball-triangle-loading"
                                    wrapperclassName="justify-content-center"
                                    wrapperStyle={{}}
                                    visible={true}
                                />
                            </div>
                            :
                            data ?
                                <div className='wrapper'>
                                    <DataTbl
                                        data={data ? data : ''}
                                        columnDefs={columnDefs1}
                                        exportcolumn={exportcolumn}
                                    />
                                </div> :
                                <div className='empty_data_msg text-center mt-5'>
                                    <h4>Installment List Not Found</h4>
                                </div>
                        }
                    </div>
                </div>
            </div>
            <ReceiptDateUpdate recieptDate={recieptDate} setRecieptDate={setRecieptDate} installmentData={installmentData}  bool={bool} setBool={setBool}/>
        </>
    );
}

export default BookingInstallmentList;
