import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { FaDownload } from "react-icons/fa";
import '../../src/gaganHoms_css/table.css'
// import "../lms_css/dataTbl.css";

export default function DataTbl(props) {

    // console.log("props", props)

    const gridRef = useRef()

    const CellClickedListener = useCallback(e => {
        // console.log("CellClickedListener", e)
    })

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
        filter: 'true',
    }), [])

    const onBtnExport = useCallback(() => {
        gridRef.current.api.setQuickFilter(
            document.getElementById('filter-text-box').value
        );
    }, []);

        // console.log("props++", props.exportcolumn)

    const onBtnExport2 = useCallback(() => {
        var params = {
            columnKeys: props.exportcolumn
        };
        gridRef.current.api.exportDataAsCsv(params);
    }, []);

    const paginationNumberFormatter = useCallback((params) => {
        return params.value.toLocaleString();
    }, []);

    // const isRowSelectable = useMemo(() => {
    //     var params = {
    //         columnKeys: ['SERIAL', 'Posted By', 'Course', "Subject", "Date"]
    //     };
    //     gridRef.current.api.isRowSelectable(params)
    //   }, []);

    // const onSelectionChanged = useCallback((params) => {
    //     const selectedData = gridRef.current.api.getSelectedRows();
    //     props.value('employee', selectedData)
    // }, []);

    const onSelectionChanged = (even) => {
        var selectValue = even.api.getSelectedRows()
        // props.value(selectValue)
    }

    return (
        <>
            <div className='row'>
                <div className="col text-start">
                    <div className='mb-2'>
                        <input
                            type="text"
                            id="filter-text-box"
                            placeholder="Search"
                            onInput={onBtnExport}
                            className="form-control gh_form w-50"
                        />
                    </div>
                </div>
                <div className="col text-end">
                    <button onClick={onBtnExport2} className='tblData_export_btn'><FaDownload /></button>
                </div>
            </div>
            <div style={{ height: '60vh', width: '100%' }}>
                <AgGridReact
                    className='ag-theme-alpine'
                    ref={gridRef}
                    onCellClicked={CellClickedListener}
                    rowData={props.data}
                    pagination={true}
                    paginationPageSize={10}
                    columnDefs={props.columnDefs}
                    defaultColDef={defaultColDef}
                    // exportcolumn={exportcolumn}
                    // rowSelection='multiple'
                    animateRows={true}
                    paginationNumberFormatter={paginationNumberFormatter}
                    onSelectionChanged={onSelectionChanged}
                // rowMultiSelectWithClick={true}
                >
                </AgGridReact>
            </div>
        </>
    )
}
