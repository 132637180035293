import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var employeDesignationRes;
export const employeDesignationTodo = createAsyncThunk('employeDesignation', async (id) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'employe_designation',
           data :{
            "id": id
        },
            headers: { "Content-Type": "application/json" }
        })
        return employeDesignationRes = res.data

    } catch (error) {
        return employeDesignationRes = error.response.data
    }
});

const employeDesignationSlice = createSlice({
    name: 'employeDesignation',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(employeDesignationTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('employeDesignation', state.data)
        });
        builder.addCase(employeDesignationTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default employeDesignationSlice.reducer; 