import * as Yup from "yup";
export const addCommissionSchema = Yup.object().shape({
    commission: Yup.array().of(
        Yup.object().shape({
            optionA: Yup.number().required("Enter Commission"),
            optionB: Yup.number().required("Enter Commission"),
            optionC: Yup.number().required("Enter Commission"),
            optionD: Yup.number().required("Enter Commission"),
            optionE: Yup.number().required("Enter Commission"),
            optionF: Yup.number().required("Enter Commission")
        })
    )
})