import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var teamListRes;
export const teamListTodo = createAsyncThunk('teamList', async (id) => {

    // console.log("teamList", id)

    const res = await axios({
        method: "post",
        // mode: 'cors',
        url: process.env.REACT_APP_API_URL + 'team_list',
        data: {
            "userid": id
        },
        crossDomain: true,
        headers: { "Content-Type": "application/json" }
    })
    return teamListRes = res.data
})


const teamListSlice = createSlice({
    name: 'teamList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(teamListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('teamListTodo', state.data.response)
        });
        builder.addCase(teamListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default teamListSlice.reducer