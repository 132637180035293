import '../gaganHoms_css/navigation.css'
import React from 'react';
import { FaRegBell, FaUserCircle } from "react-icons/fa";
import { RiLockPasswordLine } from "react-icons/ri";
import { IoReorderThree } from "react-icons/io5";
import { MdOutlineEmail, MdLogout } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
// import PropertySideBar from './PropertySideBar';
// import SideBar from './sideBar';
import GaganHouseSideBar from './sideBar';
import AdminPasswordChange from './adminPasswordChange';
import { ToastContainer } from 'react-toastify';
import { Dropdown } from 'react-bootstrap';

const Header = () => {

    const navigate = useNavigate();
    const [adminpsw, setAdminpsw] = useState(false)

    const handlePswUpdate = () => {
        setAdminpsw(true);

    }

    const logout = () => {
        localStorage.clear();
        navigate('/Login');
    }




    const [sidebar, setSidebar] = useState(true)
    useEffect(() => {
        if (sidebar) {
            var marginAll = document.getElementById("content");
            marginAll.classList.add('margin-all-page');

            var element = document.getElementById("property-header");
            element.classList.add('property-header-margin');

            document.getElementById('header-dekstop-logo').style.display = 'none'
        } else {
            var marginAll = document.getElementById("content");
            marginAll.classList.remove('margin-all-page');

            var element = document.getElementById("property-header");
            element.classList.remove('property-header-margin');
            document.getElementById('header-dekstop-logo').style.display = 'inline'
        }
    });

    return (
        <>
            <nav style={{ position: 'relative' }}>
                <div className="container-fluid " style={{ position: 'fixed', zIndex: "999", top: '0',padding:'0px' }}>
                    <header className='bg-light ' id='property-header'>
                        <div className="row  ">
                            <div className="col-md-12">
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className=" d-flex align-items-center">
                                        <div className='header-logo-section ' id='header-dekstop-logo'>
                                            <Link to='/'><img src="../gagan-logo.jpg" className='img-fluid header-logo  ' alt="property-logo" /></Link>
                                        </div>
                                        <div className='header-logo-section  d-block d-lg-none ' >
                                            <Link to='/'><img src="../gagan-logo.jpg" className='img-fluid  header-logo ' alt="property-logo" /></Link>
                                        </div>
                                        <div>
                                            <a type='button' className='sidebar-btn d-none d-lg-block' onClick={() => setSidebar(!sidebar)} > <IoReorderThree style={{ color: "#0f5684" }} size={35} /></a>
                                            <a type='button' className=' sidebar-btn d-block d-lg-none' data-bs-toggle="offcanvas" data-bs-target="#sideBar" aria-controls="offcanvasWithBothOptions" > <IoReorderThree style={{ color: "#0f5684" }} size={35} /></a>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        {/* <div className='me-3 d-none d-lg-block'>
                                            <p className='dashboard-heading'>Hello <b> {JSON.parse(localStorage.getItem('user_data')).name} ! </b></p>
                                        </div> */}
                                        {/* <div className='me-3'>
                                            <img src='https://cdn-icons-png.flaticon.com/512/149/149071.png' className='img-fluid user-img ' width='35px' alt="user-img" />
                                        </div> */}
                                        {/* <div className="  dropdown-start">
                                            <span className=' profile_drop_toggal dropdown-toggle' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src='https://cdn-icons-png.flaticon.com/512/149/149071.png' alt="img" className='img-fluid user-img ' />
                                            </span>
                                            <ul className="dropdown-menu profile_drop ">
                                                <p>Welcome Admin !</p>
                                                <li className=''><span style={{ color: '#105684' }} onClick={handlePswUpdate} className="dropdown-item" ><RiLockPasswordLine className=' me-2 ' size={25} style={{ color: '#105684' }} /> Change Password</span></li>
                                                <li className=''><span style={{ color: '#EF3D50' }} onClick={logout} className="dropdown-item" ><MdLogout className=' me-2 ' size={25} style={{ color: '#EF3D50' }} /> Logout</span></li>
                                            </ul>
                                        </div> */}
                                        <Dropdown>
                                            <Dropdown.Toggle className='drop_btn' id="dropdown-basic">
                                            <span style={{ fontSize: "14px", fontWeight: '500' }}>Hello {JSON.parse(localStorage.getItem('user_data')).name} ! <FaUserCircle size={22} className='mb-1 ms-1' style={{ color: "white" }} /> </span>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className=" profile_drop">
                                                <Dropdown.Item ><button className="dropdown-item" onClick={handlePswUpdate}  type='button' ><RiLockPasswordLine className=' me-1 ' size={25} style={{ color: '#e62b40' }} /> Change Password</button></Dropdown.Item>
                                                <Dropdown.Item ><button className="dropdown-item" type='button' onClick={logout}  ><MdLogout className=' me-1 ' size={25} style={{ color: '#e62b40' }} /> Logout</button></Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>
            </nav>

            {sidebar && (<GaganHouseSideBar />)}
            <AdminPasswordChange adminpsw={adminpsw} setAdminpsw={setAdminpsw} />
            
            <ToastContainer />
        </>
    );
}

export default Header;
