import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";



export var exceluploadRes;
export const exceluploadTodo = createAsyncThunk('excelupload', async (data) => {

    // console.log("UpdateProject--", data)

    const res = await axios({
        method: "post",
        // mode: 'cors',
        url: process.env.REACT_APP_API_URL + 'add_excel',
        data: data,
        crossDomain: true,
        headers: {  "Content-Type": "multipart/form-data"}
    })
    return exceluploadRes = res.data
})
