import { useState } from 'react'
import DataTbl from '../../table/table'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react';
import { projectListRes, projectListTodo } from '../../redux/slices/projectList';
import { MdDelete, MdDeleteOutline, MdOutlineAddCircleOutline } from "react-icons/md";
import Swal from 'sweetalert2'
import { FiMonitor } from "react-icons/fi";
import { BiSolidEdit } from "react-icons/bi";
import { Link, useNavigate } from 'react-router-dom';
import { BallTriangle } from 'react-loader-spinner'
import { deleteProjectRes, deleteProjectTodo } from '../../redux/slices/project/DeleteProject';
import { toast } from 'react-toastify';

export default function ProjectList() {

    const [loading, setLoading] = useState(true)
    const [bool, setBool] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const data = useSelector(state => state && state.projectList && state.projectList.data && state.projectList.data.data && state.projectList.data.data.rows);

    const userDetailsLink = (value) => {
        navigate('../projectDetails', { state: value })
    }

    const commissionAdd = (value) => {
        navigate('../addProjectCommssion', { state: value })
    }

    const projectDelete = (value) => {
        Swal.fire({
            title: "Project Delete",
            text: "Are You Sure Want To Delete This Project ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteProjectTodo(value.id)).then(() => delete_res())
            }
        })
    }

    const delete_res = () => {
        if (deleteProjectRes && deleteProjectRes.success) {
            Swal.fire(
                "Project",
                'Project Deleted Successfully',
                'success'
            )
            setBool(!bool)
        } else {
            toast.error(deleteProjectRes && deleteProjectRes.message, { position: "bottom-right" })
        }
    }

    const upDateList = (value) => {
        navigate("../UpdateProject", { state: value })
    }
    const exportcolumn = ['project_name', 'project_size', "project_status", "company_name"]

    const [columnDefs1, setColumnDefs1] = useState([
        {
            headerName: 'S.No.',
            valueGetter: 'node.rowIndex + 1',
            wrapText: true,
            maxWidth: 100,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '200px'
            // resizable: true,
        },
        {
            headerName: "Project Name",
            field: "project_name",
            wrapText: true,
            autoHeight: true,
            // resizable: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '200px'
        },
        {
            headerName: "Project Size",
            field: "project_size",
            wrapText: true,
            // resizable: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '150px'
        },
        {
            headerName: "Image",
            field: "mobile",
            cellRenderer: params => <div className='table_img_sec'>

                {/* {console.log("params--", params.data)} */}
                <img className=" img-fluid table_data_img" src={(params && params.data && params.data.project_image_url) + (params && params.data && params.data.project_image)} alt="image" />


            </div>,
            wrapText: true,
            autoHeight: true,
            // resizable: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },
        {
            headerName: "Project Status",
            field: "project_status",
            wrapText: true,
            cellRenderer: params => <span style={{ textTransform: "capitalize" }} className={params.data.project_status === 'available' ? 'status_availabel' : params.data.project_status === 'hold' ? 'status_hold' : params.data.project_status === 'booked' ? 'status_booked' : ''} >
                {params && params.data && params.data.project_status}</span>,
            autoHeight: true,
            // resizable: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },
        {
            headerName: "Company Name",
            field: "company_name",
            wrapText: true,
            autoHeight: true,
            // resizable: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },
        {
            headerName: "Action",
            wrapText: true,
            cellRenderer: params => <div className='viewEdit_btn'>

                <span onClick={() => { upDateList(params && params.data) }}><BiSolidEdit /></span>
                <span type='button' onClick={() => commissionAdd(params && params.data)}><MdOutlineAddCircleOutline size={22} className='' /></span>
                <span type='button' onClick={() => userDetailsLink(params && params.data)}><FiMonitor /></span>
                <span type='button' onClick={() => projectDelete(params && params.data)}><MdDeleteOutline size={23} style={{ color: 'red' }} /></span>
            </div>,
            autoHeight: true,
            // resizable: true,
            width: '130px',
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },
    ])

    useEffect(() => {
        dispatch(projectListTodo()).then(() => projectList_fun())
    }, [bool])

    const projectList_fun = () => {
        if (projectListRes && projectListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }



    return (
        <div className='container-fluid'>
            <div className='scnd_header'>
                <span className='icon1'>Project / </span>
                <span className='icon2'>Project List</span>
            </div>

            {loading ?

                <div className='d-flex justify-content-center mt-5'>
                    <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#105684"
                        ariaLabel="ball-triangle-loading"
                        wrapperclassName="justify-content-center"
                        wrapperStyle={{}}
                        visible={true}
                    />
                </div>
                :
                data && data.length ?
                    <div className='wrapper'>
                        <DataTbl
                            data={data ? data : ''}
                            columnDefs={columnDefs1}
                            exportcolumn={exportcolumn}
                        />
                    </div> :

                    <div className='empty_data_msg text-center mt-5'>
                        <h4>Project List Not Found</h4>
                    </div>
            }

        </div>
    )
}
