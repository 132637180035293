import React, { useEffect, useState } from 'react'
import PropertyHeader from './header'
import GaganHouseSideBar from './sideBar'
import { BsFileEarmarkTextFill } from "react-icons/bs"
import { RiHealthBookFill, RiFileList2Fill } from "react-icons/ri"
import { useDispatch, useSelector } from 'react-redux'
import { ImUser } from "react-icons/im";
import { dashboardRes, dashboardTodo } from '../redux/slices/dashboard'
import { BallTriangle } from 'react-loader-spinner'
// import '../gaganHoms_css/navigation.css'

export default function Dashboard() {

    const [loading, setLoading] = useState(true)

    const dispatch = useDispatch()
    const dashboard = useSelector(state => state.dashboard && state.dashboard.data && state.dashboard.data.data)
    // console.log("dashboard", dashboard)

    useEffect(() => {
        dispatch(dashboardTodo()).then(() => { dashboard_fun() })
    }, [])

    
    const dashboard_fun = () => {
        if (dashboardRes && dashboardRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    return (
        <>
            {
                loading ?
                    <div className='d-flex justify-content-center mt-5'>
                        <BallTriangle
                            height={100}
                            width={100}
                            radius={5}
                            color="#105684"
                            ariaLabel="ball-triangle-loading"
                            wrapperclassName="justify-content-center"
                            wrapperStyle={{}}
                            visible={true}
                        />
                    </div>
                    :
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-lg-3 col-md-3  mt-3">
                                <div className="card project-img-section text-center  h-100" >

                                    <div className='d-flex justify-content-around align-items-center '>
                                        <div className=''>
                                            <span className='dashboard_icon'>
                                                < BsFileEarmarkTextFill size={25} style={{ color: "#105684" }} />
                                            </span>
                                        </div>
                                        <div className='dashboard_details'>
                                            <h4>Project</h4>
                                            <h3 className='text-center'>{dashboard && dashboard[0] && dashboard[0].Total_project}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3  mt-3">
                                <div className="card project-img-section text-center  h-100" >

                                    <div className='d-flex justify-content-around align-items-center '>
                                        <div className=''>
                                            <span className='dashboard_icon'>
                                                < RiHealthBookFill size={25} style={{ color: "#105684" }} />
                                            </span>
                                        </div>
                                        <div className='dashboard_details'>
                                            <h4>Property</h4>
                                            <h3 className='text-center'>{dashboard && dashboard[0].Total_property}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3  mt-3">
                                <div className="card project-img-section text-center  h-100" >

                                    <div className='d-flex justify-content-around align-items-center '>
                                        <div className=''>
                                            <span className='dashboard_icon'>
                                                < RiFileList2Fill size={25} style={{ color: "#105684" }} />
                                            </span>
                                        </div>
                                        <div className='dashboard_details'>
                                            <h4>Booking</h4>
                                            <h3 className='text-center'>{dashboard && dashboard[0].Total_booking}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3  mt-3">
                                <div className="card project-img-section text-center  h-100" >

                                    <div className='d-flex justify-content-around align-items-center '>
                                        <div className=''>
                                            <span className='dashboard_icon'>
                                                < ImUser size={25} style={{ color: "#105684" }} />
                                            </span>
                                        </div>
                                        <div className='dashboard_details'>
                                            <h4>User</h4>
                                            <h3 className='text-center'>{dashboard && dashboard[0].Total_users}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}
