import React from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { commissionSetRes, commissionSetTodo } from '../../redux/slices/booking/commissionSet';
import { toast } from 'react-toastify';
import { bookingInstallmentRes, bookingInstallmentTodo } from '../../redux/slices/booking/bookingInstallment';
import { bookingInstallmentSchema } from '../../schemas';
import BookingInstallmentList from './bookingInstallmentList';


const BookingInstallment = (props) => {
    const { aprovePopup, setaprovePopup } = props;
    const { bstatus, setbstatus } = props;
    const { bool, setBool } = props;
    var bookingData = props.bookingData;
    var projectId = bookingData && bookingData.fk_project_id
    var propertyId = bookingData && bookingData.fk_property_id
    var bookingId = bookingData && bookingData.id
    var bookingStatus = bookingData && bookingData.booking_status

    // console.log("bookingStatus",bstatus)

    const dispatch = useDispatch()

    const initialValues = {
        recipet_amt: '',
        transacion_id: '',
        date: '',
        payment_typ: '',
        payment_status: 'Paid',

    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: bookingInstallmentSchema,
        onSubmit: (values, action) => {
            // console.log(values)
            {
                bstatus === 'hold' ? dispatch(commissionSetTodo({ values, projectId, propertyId, bookingId })).then(() => BookingApprovefun()) :
                bstatus === 'booked' ? dispatch(bookingInstallmentTodo({ values, projectId, propertyId, bookingId })).then(() => bookingInstallment()) : <></>
            }
            action.resetForm();
        },
    })
    const BookingApprovefun = () => {
        if (commissionSetRes && commissionSetRes.success) {
            toast.success(commissionSetRes && commissionSetRes.message, { position: "bottom-right" })
            setBool(!bool)
            setaprovePopup(false);
        } else {

            toast.error(commissionSetRes && commissionSetRes.message, { position: "bottom-right" })

        }
    }

    const bookingInstallment = () => {
        if (bookingInstallmentRes && bookingInstallmentRes.success) {
            toast.success(bookingInstallmentRes && bookingInstallmentRes.message, { position: "bottom-right" })
            setBool(!bool)
            setaprovePopup(false);
        } else {
            toast.error(bookingInstallmentRes && bookingInstallmentRes.message, { position: "bottom-right" })

        }
    }

    return (
        <>
            <div className="container-fuild">
                <div className={aprovePopup ? 'modal fade show d-block' : 'modal fade show d-none'} style={{ background: 'rgba(0,0,0,0.8)' }}>
                    <div class="modal-dialog  modal-dialog-centered modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel"></h5>
                                <h5 style={{ color: "#105684" }} className='text-center'>{bstatus === 'hold' ? "Booking Approve " : bstatus === 'booked' ? "Booking Installment " : ''}</h5>
                                <button type="button" class="btn-close" onClick={() => { setaprovePopup(false); }}></button>
                            </div>
                            <div class="modal-body p-4">
                                <form onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <div className="col-md-4 gh_formWrapper">
                                            <label htmlFor="recipet_amt" className="form-label gh_label">Receipt Amount </label>
                                            <input type="text"
                                                className="form-control gh_form"
                                                name='recipet_amt'
                                                id="recipet_amt"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.recipet_amt}
                                            />
                                            {errors.recipet_amt && touched.recipet_amt ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.recipet_amt}</span>) : null}
                                        </div>
                                        <div className="col-md-4 gh_formWrapper">
                                            <label htmlFor="transacion_id" className="form-label gh_label">Transaction Id</label>
                                            <input type="text"
                                                className="form-control gh_form"
                                                name='transacion_id'
                                                id="transacion_id"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.transacion_id}
                                            />
                                            {/* {errors.transacion_id && touched.transacion_id ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.transacion_id}</span>) : null} */}
                                        </div>
                                        <div className="col-md-4 gh_formWrapper">
                                            <label htmlFor="date" className="form-label gh_label"> Receipt Date</label>
                                            <input type="date"
                                                className="form-control gh_form"
                                                name='date'
                                                id="date"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.date}
                                            />
                                            {errors.date && touched.date ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.date}</span>) : null}
                                        </div>
                                        <div className="col-md-4 gh_formWrapper">
                                            <label htmlFor="payment_typ" className="form-label gh_label">Payment Type</label>
                                            <select className="form-select gh_form" name='payment_typ' id='payment_typ'
                                                onChange={handleChange}
                                                value={values.payment_typ}
                                                onBlur={handleBlur}
                                            >
                                                <option value=''>Select</option>
                                                <option value="cash">Cash</option>
                                                <option value="cheque">Cheque</option>
                                                <option value="online">Online</option>
                                                <option value="banktransfer">Bank Transfer</option>

                                            </select>

                                            {errors.payment_typ && touched.payment_typ ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.payment_typ}</span>) : null}
                                        </div>
                                        {/* <div className="col-md-4 gh_formWrapper">
                                            <label htmlFor="payment_status" className="form-label gh_label">Status</label>
                                            <select className="form-select gh_form" name='payment_status' id='payment_status'
                                                onChange={handleChange}
                                                value={values.payment_status}
                                                onBlur={handleBlur}
                                            >
                                                <option value=''>Select</option>
                                                <option value="paid">Paid</option>
                                                <option value="rejected">Rejected</option>
                                                <option value="pending">Pending</option>

                                            </select>

                                            {errors.payment_status && touched.payment_status ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.payment_status}</span>) : null}
                                        </div> */}

                                    </div>

                                    <div className='mt-3 d-flex justify-content-start'>
                                        <button type='submit' className='btn btn-success gh_btn'>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: "none" }}>
                <BookingInstallmentList bool={bool} setBool={setBool}/>
            </div>
        </>
    );
}

export default BookingInstallment;
