import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var updateProjectRes;
export const updateProjectTodo = createAsyncThunk('updateProject', async (data) => {

    console.log("UpdateProject--", data)

    const res = await axios({
        method: "post",
        // mode: 'cors',
        url: process.env.REACT_APP_API_URL + 'update_project',
        data: data,
        crossDomain: true,
        headers: {  "Content-Type": "multipart/form-data"}
    })
    return updateProjectRes = res.data
})


