import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var commissionListRes;
export const commissionListTodo = createAsyncThunk('commissionList', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'commission',
            data:{
                "project_id":""
            },
            headers: { "Content-Type": "application/json" }
        })
        return commissionListRes = res.data

    } catch (error) {
        return commissionListRes = error.response.data
    }
});

const commissionListSlice = createSlice({
    name: 'commissionList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(commissionListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(commissionListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default commissionListSlice.reducer; 