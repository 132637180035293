import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addPropertyListRes;
export const addPropertyListTodo = createAsyncThunk('addPropertyList', async (id) => {
    console.log("data==", id)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'property',
            data: {
                "project_id": id
            },
            headers: { "Content-Type": "application/json" }
        })
        return addPropertyListRes = res.data

    } catch (error) {
        return addPropertyListRes = error.response.data
    }
});

const addPropertyListSlice = createSlice({
    name: 'addPropertyList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(addPropertyListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(addPropertyListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default addPropertyListSlice.reducer; 
