import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var userPswUpdateRes;
export const userPswUpdateTodo = createAsyncThunk('userPswUpdate', async (data) => {

    console.log("userPswUpdate", data)

    const res = await axios({
        method: "post",
        // mode: 'cors',
        url: process.env.REACT_APP_API_URL + 'password_updated',
        data: {
            "id":data.userID,
            "Password": data.values.new_psw,
            "confirmPassword": data.values.confirm_psw
    },
        crossDomain: true,
        headers: { "Content-Type": "application/json" }
    })
return userPswUpdateRes = res.data
})