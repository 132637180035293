import React from 'react';
import { userPasswordSchema } from '../../schemas';
import { useState } from 'react'
import { useFormik } from 'formik';
import { RiLockPasswordLine } from "react-icons/ri";
import { BiHide, BiShow } from "react-icons/bi";
import { useDispatch } from 'react-redux';
import { userPswUpdateRes, userPswUpdateTodo } from '../../redux/slices/user/userPswUpdate';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-bootstrap';


const UpdateUserPsw = (props) => {

    const { pswPopup, setPswPopup } = props;
    const [newPswShow, setNewPswShow] = useState(false);
    const [confirmPswShow, setconfirmPswShow] = useState(false);
    const dispatch = useDispatch()

    var userID = props.id;
    console.log("userID", userID)

    const togglePassword = () => {
        setNewPswShow(!newPswShow);

    }
    const togglePassword1 = () => {
        setconfirmPswShow(!confirmPswShow);

    }

    const initialValues = {
        userId : userID,
        new_psw: "",
        confirm_psw: ""
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: userPasswordSchema,
        onSubmit: (values, action) => {
            console.log(values)
            dispatch(userPswUpdateTodo({values,userID})).then(() => userPswChange())
            action.resetForm()
        },
    })

    const userPswChange = () => {
        if (userPswUpdateRes && userPswUpdateRes.success) {
            toast.success(userPswUpdateRes && userPswUpdateRes.message, { position: 'bottom-right' })
            setPswPopup(false);
        } else {
            toast.error(userPswUpdateRes && userPswUpdateRes.message, { position: 'bottom-right' })

        }
    }

    return (
        <>
            <div className={pswPopup ? 'modal fade show d-block' : 'modal fade show d-none'} style={{ background: 'rgba(0,0,0,0.8)' }}>
                <div class="modal-dialog  modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel"></h5>
                            <h5 style={{ color: "#105684" }} className='text-center'>Update Password <RiLockPasswordLine size={25} className='mb-1' /></h5>
                            <button type="button" class="btn-close" onClick={() => { setPswPopup(false); }}></button>
                        </div>
                        <div class="modal-body">
                            <form onSubmit={handleSubmit}>

                                <div className='mt-2' style={{ position: 'relative' }}>
                                    <label htmlFor="user_name" className="form-label gh_label">New Password</label>
                                    {newPswShow ? <span class="psw_icon" onClick={togglePassword} ><BiShow size={20} /></span> : <span class="psw_icon" onClick={togglePassword} ><BiHide size={20} /></span>}
                                    <input type={newPswShow ? "text" : "password"} name='new_psw' onChange={handleChange} onBlur={handleBlur} value={values.new_psw} className='form-control gh_form' />
                                    {errors.new_psw && touched.new_psw ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.new_psw}</span>) : null}
                                </div>

                                <div className='mt-2 mb-4' style={{ position: 'relative' }}>
                                    <label htmlFor="user_name" className="form-label gh_label">Conform Password</label>
                                    {confirmPswShow ? <span class="psw_icon" onClick={togglePassword1} ><BiShow size={20} /></span> : <span class="psw_icon" onClick={togglePassword1} ><BiHide size={20} /></span>}
                                    <input type={confirmPswShow ? "text" : "password"} onChange={handleChange} onBlur={handleBlur}
                                        value={values.confirm_psw} name='confirm_psw' className='form-control gh_form' />

                                    {errors.confirm_psw && touched.confirm_psw ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.confirm_psw}</span>) : null}
                                </div>

                                <div className='mt-3 d-flex justify-content-center'>
                                    <button type='submit' className='btn btn-success gh_btn'>Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default UpdateUserPsw;
