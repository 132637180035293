import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addUserRes;
export const addUserTodo = createAsyncThunk('addUser', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'user_create',
            data: {
                "username": data.user_name,
                "mobile": data.mobile_num,
                "gender": data.gender,
                "datebirth": data.dob,
                "email": data.email,
                "address": data.address,
                "state": data.state,
                "city": data.city,
                "password": data.password,
                "designation_id": data.designation_id,
                "employer_id": data.employer_id,
                'added_by': JSON.parse(localStorage.getItem('user_data')).userId

            },
            headers: { "Content-Type": "application/json" }
        })
        return addUserRes = res.data

    } catch (error) {
        return addUserRes = error.response.data
    }
})