import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var updateReceiptDateRes;
export const updateReceiptDateTodo = createAsyncThunk('updateReceiptDate', async (data) => {
    console.log("data--", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'update_installment',
            data: {
                "receipt_id": data.id,
                "receipt_date": data.recpt_date,
                "type": data?.payment_typ,
                "transaction_id": data?.transacion_id,
                // "amount": data?.recipet_amt
            },
            headers: { "Content-Type": "application/json" }
        })
        return updateReceiptDateRes = res.data

    } catch (error) {
        return updateReceiptDateRes = error.response.data
    }
})