import React, { useState } from 'react'
import { useLocation } from 'react-router'
import '../../gaganHoms_css/viewDetails.css'
import ApproveBooking from '../../redux/slices/booking/approveBooking';
import BookingApproveList from './bookingApproveList';
import BookingInstallmentList from './bookingInstallmentList';

export default function BookingDetails() {

    const state = useLocation();
    const BookingData = state && state.state;

    const [bstatus, setbstatus] = useState( BookingData?.booking_status)
    const [pstatus, setpstatus] = useState( BookingData?.property_detail?.property_status)
    // console.log("BookingData", BookingData)

    const [bAmt, setBAmt] = useState(BookingData?.booking_amount)

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <div className='userDtl_box scroller'>
                            <div className='view_dtails'>
                                <div className='text-center'>{BookingData?.company_name}</div>
                                {/* <img src={BookingData ? (BookingData?.project_image_url + BookingData?.project_image) : "./DP_img.jpg"} alt="" /> */}

                                <h4>{BookingData?.project_master?.project_name}</h4>
                                <h6>Customer : <span>{ BookingData?.customer?.name}</span></h6>
                                <div className='date'>{ BookingData?.customer?.email}</div>
                                <div className='date'>Added date: {new Date(BookingData?.added_date).toLocaleDateString('en-GB')}</div>

                                <div className='gaganHomes_dataList'>

                                </div>
                            </div>

                            <hr />

                            <div className='gaganHomes_dataList'>
                                {/* <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Customer Name</p>
                                    <span className='body'></span>
                                </div> */}
                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Mob. Number</p>
                                    <span className='body'>{BookingData?.customer?.mobile}</span>
                                </div>
                                {/* <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Designation</p>
                                    <span className='body'>{BookingData?.booking_amount}</span>
                                </div> */}

                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Booking Status</p>
                                    {/* <span className={BookingData?.booking_status === 'available' ? 'text-primary' : BookingData?.booking_status === 'hold' ? 'text-warning' : (BookingData?.booking_status) === 'booked' ? 'text-success' : ''}>{BookingData?.booking_status}</span> */}
                                    <span className={bstatus === 'available' ? 'badge bg-primary' : bstatus === 'hold' ? 'badge bg-warning' : bstatus === 'booked' ? 'badge bg-success' : bstatus === 'rejected' ? 'badge bg-danger' : ''} >
                                        {bstatus}</span>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Deal Amount</p>
                                    <span className='body'>{bAmt}</span>
                                </div>

                                <hr />
                                <div className="mt-1">
                                    <p className='head text-center'>Plot Detail</p>
                                </div>



                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Cost</p>
                                    <span className='body'>{BookingData?.property_detail?.cost}</span>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Plot Length</p>
                                    <span className='body'>{BookingData?.property_detail?.property_length}</span>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Plot Number</p>
                                    <span className='body'>{BookingData?.property_detail?.property_number}</span>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Plot Size</p>
                                    <span className='body'>{BookingData?.property_detail?.property_size}</span>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Plot Status</p>
                                    <span className={pstatus === 'available' ? 'badge bg-primary' : pstatus === 'hold' ? 'badge bg-warning' : pstatus === 'booked' ? 'badge bg-success' : ''}>{pstatus}</span>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>PLot Width</p>
                                    <span className='body'>{BookingData?.property_detail?.property_width}</span>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="col-md-9 mt-3 mt-md-0">
                        <div>
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Commission</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Installment</button>
                                </li>

                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                                    <div className=''>
                                        <BookingApproveList bAmt={bAmt} setBAmt={setBAmt} bstatus={bstatus} setbstatus={setbstatus} pstatus={pstatus} setpstatus={setpstatus} />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
                                <div className=''>
                                    <BookingInstallmentList />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}