import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var customerBookingRes;
export const customerBookingTodo = createAsyncThunk('customerBooking', async (id) => {
    // console.log("data//", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'booking_list',
            data: {
                "userid": "",
                "projectid": "",
                "propertyid": "",
                "customerid": "",
                "startDate": '',
                "endDate": "",
                "bstatus": "",
                "cid": id,
                "rejectednot": ""
            },

            headers: { "Content-Type": "application/json" }
        })
        return customerBookingRes = res.data

    } catch (error) {
        return customerBookingRes = error.response.data
    }
});

const customerBookingSlice = createSlice({
    name: 'customerBooking',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(customerBookingTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(customerBookingTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default customerBookingSlice.reducer; 
