import React, { useEffect, useRef, useState } from 'react'
import { Formik, useFormik } from 'formik'
import { BallTriangle } from 'react-loader-spinner'
import { reportSection } from '../../schemas'
import DataTbl from '../../table/table'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { projectListTodo, projectListRes } from '../../redux/slices/projectList'
import Select from 'react-select'
import { userListTodo } from '../../redux/slices/userListSlice'
import { customerListTodo } from '../../redux/slices/customerList'
import { bookingLIstTodo } from '../../redux/slices/bookingListSlice'
import { FiMonitor } from "react-icons/fi";
import { addPropertyListRes, addPropertyListTodo } from '../../redux/slices/AddForm/addPropertyList'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { bookingFilterRes, bookingFilterTodo } from '../../redux/slices/booking/bookingFilter'
import { bookingEarningRes } from '../../redux/slices/booking/bookingEarningList'


export default function ReportSection() {

    const [loading, setLoading] = useState(false)
    const [bool, setBool] = useState(false)
    // const [project , setProject] = useState('')
    const [dateRange, setDateRange] = useState(['', '']);
    const [startDate, endDate] = dateRange;
    const dispatch = useDispatch();
    const projectList = useSelector(state => state && state.projectList && state.projectList.data && state.projectList.data.data && state.projectList.data.data.rows);
    const userList = useSelector(state => state && state.userList && state.userList.data && state.userList.data.data)
    const customerList = useSelector(state => state && state.customerList && state.customerList.data && state.customerList.data.data && state.customerList.data.data.rows)
    const data = useSelector(state => state && state.bookingFilter && state.bookingFilter.data && state.bookingFilter.data.data && state.bookingFilter.data.data[1])
    const bookingtotlCount = useSelector(state => state && state.bookingFilter && state.bookingFilter.data && state.bookingFilter.data.data && state.bookingFilter.data.data[2])
    const propertyProject = useSelector(state => state && state.addPropertyList && state.addPropertyList.data && state.addPropertyList.data.data && state.addPropertyList.data.data.rows[0] && state.addPropertyList.data.data.rows[0].property_details)
    const totalPropertyCount = useSelector(state => state && state.bookingFilter && state.bookingFilter.data && state.bookingFilter.data.data && state.bookingFilter.data.data[0])
    var fromdate = startDate;
    var enddate = endDate;

    // const initialProject = localStorage.getItem('project')
    // const initialStatus = localStorage.getItem('status')


    


    // console.log('userList', userList)

    const navigate = useNavigate();

    const userDetailsLink = (value) => {
        navigate('../BookingDetails', { state: value })
    }

    const initialValues = {
        projectName:  '',
        propertyNumber: "",
        customer: "",
        user: "",
        status:  '',
    }


    const { values, action, touched, errors, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        // validationSchema: reportSection,

        onSubmit: (values, action) => {
            dispatch(bookingFilterTodo({ values, fromdate, enddate })).then(() => bookingFilterfun())
            setLoading(true)
            // localStorage.setItem('project', values.projectName)
            // localStorage.setItem('status', values.status)


        }
    })

   

    // console.log("reportdata", data)

    const options = []
    const propertyNumber = []
    const customer = []
    const userName = []



    const cityAllData = projectList && projectList.map((value) => options.push(
        { value: value.id, label: value.project_name },
    )
    )

    customerList && customerList.map((value) => customer.push(
        { value: value.id, label: value.name },
        // console.log("dev[[", value.project_name)
    )
    )

    userList && userList.map((value) => userName.push(
        { value: value.id, label: value.username },
    )
    )

    propertyProject && propertyProject.map((value) => propertyNumber.push(
        { value: value.id, label: value.property_number },
        // console.log("dev[[", value.project_name)
    )
    )

    const project_fun = (id) => {
        dispatch(addPropertyListTodo(id))
    }

    useEffect(() => {
        setLoading(true)
        dispatch(projectListTodo())
        dispatch(userListTodo())
        dispatch(customerListTodo())
        dispatch(bookingFilterTodo()).then(() => bookingFilterfun())
        handleSubmit()
    }, [bool])

    const bookingFilterfun = () => {
        if (bookingEarningRes && bookingFilterRes.success) {
            // setBool(!bool)
            setLoading(false)
        } else {
            setLoading(false)

        }
    }

    const selectRef = useRef();
    const selectRef1 = useRef();
    const selectRef2 = useRef();
    const selectRef3 = useRef();


    const handleReset = () => {
        selectRef && selectRef.current && selectRef.current.clearValue();
        selectRef1 && selectRef1.current && selectRef1.current.clearValue();
        selectRef2 && selectRef2.current && selectRef2.current.clearValue();
        selectRef3 && selectRef3.current && selectRef3.current.clearValue();

        document.getElementById('status').value = ""
        values.status = ""
        setDateRange(['', ''])
        setBool(!bool)
        // localStorage.removeItem("project","status")
        // localStorage.removeItem()
    };

    const exportcolumn = ['project_master.project_name', 'property_detail.property_number', "customer.name", "booking_amount", "user.username", "added_date", "booking_status"]
    const [columnDefs1, setColumnDefs1] = useState([
        {
            headerName: 'S.No.',
            valueGetter: 'node.rowIndex + 1',
            wrapText: true,
            maxWidth: 90,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },
        {
            headerName: "Project Name",
            field: "project_master.project_name",
            wrapText: true,
            cellRenderer: params => <span style={{ textTransform: 'capitalize' }}>{params && params.data && params.data.project_master && params.data.project_master.project_name}</span>,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '200px'
        },
        {
            headerName: "Plot No.",
            field: "property_detail.property_number",
            wrapText: true,
            cellRenderer: params => <span style={{ textTransform: 'capitalize' }}>{params && params.data && params.data.property_detail && params.data.property_detail.property_number}</span>,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '120px'
        },

        {
            headerName: "Customer",
            field: "customer.name",
            cellRenderer: params => <span style={{ textTransform: 'capitalize' }}>{params && params.data && params.data.customer && params.data.customer.name}</span>,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '130px'
        },
        {
            headerName: "Booking Amount",
            field: "booking_amount",

            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '130px'
        },
        {
            headerName: "Booked By",
            field: "user.username",
            cellRenderer: params => <span style={{ textTransform: 'capitalize' }}>{params && params.data && params.data.user && params.data.user.username}</span>,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '220px'
        },
      
        {
            headerName: "Booking Date",
            field: "booking_date",
            cellRenderer: params =>params?.data?.booking_date === null ? '-': <span style={{ textTransform: 'capitalize' }}>{new Date(params?.data?.booking_date).toLocaleDateString('en-GB')}</span>,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '130px'
        },
        {
            headerName: "Added Date",
            field: "added_date",
            cellRenderer: params => params?.data?.added_date === null ? '-':<span style={{ textTransform: 'capitalize' }}>{new Date(params && params.data && params.data.added_date).toLocaleDateString('en-GB')}</span>,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '130px'
        },
        {
            headerName: "Booking Status",
            field: "booking_status",
            cellRenderer: params => <span style={{ textTransform: "capitalize" }} className={params && params.data && params.data.booking_status === 'available' ? 'status_availabel' : (params && params.data && params.data.booking_status) === 'hold' ? 'status_hold' : (params && params.data && params.data.booking_status) === 'booked' ? 'status_booked' : (params && params.data && params.data.booking_status) === 'rejected' ? 'status_rejected' : ''} >
                {params.data.booking_status}</span>,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '130px'
        },

        {
            headerName: "Action",
            wrapText: true,
            cellRenderer: params => <div className='viewEdit_btn'>

                {/* <span><BiSolidEdit /></span> */}
                {/* {params && params.data && params.data.booking_status  === 'hold' ?  <span> <BsCheckCircle onClick={() => approved_fun(params && params.data)} size={19} style={{ color: '#47BB4C', cursor: "pointer" }} /></span> :''}
              {params && params.data && params.data.booking_status  === 'hold' ?  <span> <RxCrossCircled onClick={() => { booking_reject(params && params.data && params.data.id) }} size={20} className='ms-1' style={{ color: '#EF3D50', cursor: "pointer" }} /></span>:''} */}
                <span onClick={() => userDetailsLink(params && params.data)}><FiMonitor style={{ color: '#105684', cursor: "pointer" }} /></span>
                {/* <button style={{fontSize: '12px', padding: '5px', margin: '0px'}} className='btn gh_btn' onClick={() => approved_fun(params && params.data)}>Approve</button> */}

            </div>,
            autoHeight: true,
            width: '100px',
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },

    ])

    return (
        <div className="container-flued">

            <div className='scnd_header'>
                <span className='icon1'>Report Section</span>
                {/* <span className='icon1'>Project / </span>
                <span className='icon2'>Add Project</span> */}
            </div>

            <div className='wrapper'>
                {/* <h4 className='text-center mb-4'>Add Project</h4> */}

                <form onSubmit={handleSubmit} >
                    <div className='row'>

                        <div className="col-md-4 gh_formWrapper">
                            <label htmlFor="projectName" className="form-label gh_label">Project Name</label>
                            <Select
                                ref={selectRef}
                                name='projectName'
                                onChange={(option) => {
                                    setFieldValue('projectName', option && option.value)
                                    project_fun(option && option.value)
                                    handleSubmit()
                                    // setProject(option && option.value)
                                }
                                }
                                // defaultValue={options.find((option) => option.value === initialProject)}
                                onBlur={handleBlur}
                                options={options}
                                className="gh_form "
                            />

                         
                        </div>

                        <div className="col-md-4 gh_formWrapper">
                            <label htmlFor="propertyNumber" className="form-label gh_label">Plot Number</label>

                            <Select
                                ref={selectRef1}
                                name='propertyNumber'
                                onChange={(option) => {
                                    setFieldValue('propertyNumber', option && option.value)
                                    handleSubmit()
                                }
                                }
                                onBlur={handleBlur}
                                options={propertyNumber}
                                className="gh_form"
                            />

                          
                        </div>

                        <div className="col-md-4 gh_formWrapper">
                            <label htmlFor="customer" className="form-label gh_label">Customer</label>
                            <Select
                                ref={selectRef2}
                                name='customer'
                                onChange={(option) => {
                                    setFieldValue('customer', option && option.value)
                                    handleSubmit()
                                }
                                }
                                onBlur={handleBlur}
                                options={customer}
                                className="gh_form"
                            />

                        
                        </div>

                        <div className="col-md-4 gh_formWrapper">
                            <label htmlFor="user" className="form-label gh_label">User</label>
                            <Select
                                ref={selectRef3}
                                name='user'
                                onChange={(option) => {
                                    setFieldValue('user', option && option.value)
                                    handleSubmit()
                                }
                                }
                                onBlur={handleBlur}
                                options={userName}
                                className="gh_form"
                            />

                         
                        </div>

                        <div className="col-md-4 gh_formWrapper">
                            <label htmlFor="project_size" className="form-label gh_label">Status</label>

                            <select className="form-select gh_form" name='status' id="status" onChange={
                                (e) => {
                                    handleChange(e)
                                    handleSubmit(e)
                                }
                            } onBlur={handleBlur}>
                                <option value=''>Select</option>
                                <option value="available">Available</option>
                                <option value="booked">Booked</option>
                                <option value="rejected">Rejected</option>
                                <option value="hold">Hold</option>
                            </select>

                          
                        </div>

                        <div className='col-md-4 gh_formWrapper'>
                            <label htmlFor="user" className="form-label gh_label">Booking Date</label>
                            <div className='w-100'>
                                <DatePicker
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    className="form-control gh_form"
                                    placeholderText='Select Date'
                                    onChange={(update) => {
                                        setDateRange(update);
                                        handleSubmit()
                                    }}
                                    isClearable={false}
                                />
                            </div>
                        </div>

                        <div className='text-end mt-2'>
                            {/* <span type= onClick={handleReset}>dev</span> */}
                            <button type='submit' className='btn btn-success gh_btn me-1'>Submit</button>
                            <button onClick={handleReset} className='btn btn-success gh_btn'>Reset</button>
                        </div>
                    </div>
                </form>
            </div>
            <div className='wrapper mt-2'>
                <div className="row">
                    <div className="col-md-4">
                        <div className="card totl_review_section text-center " >
                            <div className='d-flex justify-content-between align-items-center '>
                                <p>Total Plot</p>
                                <span>{totalPropertyCount && totalPropertyCount.Totalproperty ? totalPropertyCount && totalPropertyCount.Totalproperty : '0'}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card totl_review_section text-center " >
                            <div className='d-flex justify-content-between align-items-center '>
                                <p>Total Amount</p>
                                <span>{bookingtotlCount && bookingtotlCount.total_Amount ? bookingtotlCount && bookingtotlCount.total_Amount : '0'}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card totl_review_section text-center " >
                            <div className='d-flex justify-content-between align-items-center '>
                                <p>Total Cost</p>
                                <span>{bookingtotlCount && bookingtotlCount.total_cost ? bookingtotlCount && bookingtotlCount.total_cost : '0'}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mt-2">
                        <div className="card status_review_section text-center " >
                            <h5 className="text-success">Property Booked</h5>
                            <div className='d-flex justify-content-between align-items-center '>
                                <p>Total Plot Booked</p>
                                <span>{bookingtotlCount && bookingtotlCount.total_Booked_Property ? bookingtotlCount && bookingtotlCount.total_Booked_Property : '0'}</span>
                            </div>
                            <div className='d-flex justify-content-between align-items-center '>
                                <p>Total Booked Amount</p>
                                <span>{bookingtotlCount && bookingtotlCount.total_Booked_Amount ? bookingtotlCount && bookingtotlCount.total_Booked_Amount : '0'}</span>
                            </div>
                            <div className='d-flex justify-content-between align-items-center '>
                                <p>Total Booked Cost</p>
                                <span>{bookingtotlCount && bookingtotlCount.total_Booked_Cost ? bookingtotlCount && bookingtotlCount.total_Booked_Cost : '0'}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mt-2">
                        <div className="card status_review_section text-center " >
                            <h5 className='text-warning'>Plot Hold</h5>
                            <div className='d-flex justify-content-between align-items-center '>
                                <p>Total Plot Hold</p>
                                <span>{bookingtotlCount && bookingtotlCount.total_hold_Property ? bookingtotlCount && bookingtotlCount.total_hold_Property : '0'}</span>
                            </div>
                            <div className='d-flex justify-content-between align-items-center '>
                                <p>Total Hold Amount</p>
                                <span>{bookingtotlCount && bookingtotlCount.total_hold_Amount ? bookingtotlCount && bookingtotlCount.total_hold_Amount : '0'}</span>
                            </div>
                            <div className='d-flex justify-content-between align-items-center '>
                                <p>Total Hold Cost</p>
                                <span>{bookingtotlCount && bookingtotlCount.total_hold_Cost ? bookingtotlCount && bookingtotlCount.total_hold_Cost : '0'}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mt-2">
                        <div className="card status_review_section text-center " >
                            <h5 className='text-danger'>Plot Rejected</h5>
                            <div className='d-flex justify-content-between align-items-center '>
                                <p>Total  Property Rejected</p>
                                {/* {bookingtotlCount && bookingtotlCount.total_reject_Property && bookingtotlCount.total_reject_Property.length ? 'count' : '0'}      */}
                                <span>{bookingtotlCount && bookingtotlCount.total_reject_Property ? bookingtotlCount && bookingtotlCount.total_reject_Property : '0'}</span>
                            </div>
                            <div className='d-flex justify-content-between align-items-center '>
                                <p>Total Rejected Amount</p>
                                <span>{bookingtotlCount && bookingtotlCount.total_reject_Amount ? bookingtotlCount && bookingtotlCount.total_reject_Amount : '0'}</span>
                            </div>
                            <div className='d-flex justify-content-between align-items-center '>
                                <p>Total Rejected Cost</p>
                                <span>{bookingtotlCount && bookingtotlCount.total_reject_Cost ? bookingtotlCount && bookingtotlCount.total_reject_Cost : '0'}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading ?
                <div>
                    <div className='d-flex justify-content-center mt-5'>
                        <BallTriangle
                            height={100}
                            width={100}
                            radius={5}
                            color="#105684"
                            ariaLabel="ball-triangle-loading"
                            wrapperclassName="justify-content-center"
                            wrapperStyle={{}}
                            visible={true}
                        />
                    </div>
                </div>
                :
                data && data.length ?
                    <div className='wrapper mt-2'>
                        <DataTbl
                            columnDefs={columnDefs1}
                            data={data ? data : ""}
                            exportcolumn={exportcolumn}
                        />
                    </div>
                    :
                    <div className='empty_data_msg text-center mt-5'>
                        <h4>Booking Not Available</h4>
                    </div>

            }

        </div>
    )
}