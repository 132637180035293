import React, { useEffect, useState } from 'react'
import { useFormik, FieldArray, FormikProvider } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { addPropertyListRes, addPropertyListTodo } from '../../redux/slices/AddForm/addPropertyList';
import { updatePropertyRes, updatePropertyTodo } from '../../redux/slices/project/update_property';
import { ToastContainer, toast } from 'react-toastify';
import { MdDeleteOutline } from "react-icons/md";
import { useLocation } from 'react-router';
import { deletePropertyRes, deletePropertyTodo } from '../../redux/slices/propertyDelete';
import { BallTriangle } from 'react-loader-spinner'
import Swal from 'sweetalert2'
import { propertyBookRes, propertyBookTodo } from '../../redux/slices/project/PropertyBooked';



export default function AddForm(props) {
    const { bool, setBool } = props;
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const state = useLocation();
    var projectId = state && state.state.id
    const addPropertyList = useSelector(state => state && state.addPropertyList && state.addPropertyList.data && state.addPropertyList.data && state.addPropertyList.data.data && state.addPropertyList.data.data.rows && state.addPropertyList.data.data.rows[0]);

    var content_data = []
    const cont_data = addPropertyList && addPropertyList.property_details && addPropertyList.property_details.map((value) => content_data.push({

        property_id: value.id,
        property_number: value.property_number,
        property_length: value.property_length,
        property_width: value.property_width,
        cost: value.cost,
        property_status: value.property_status
    }))
    const initialValues = {
        data: addPropertyList && content_data,
    };

    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(updatePropertyTodo(values)).then(() => add_update_res())
            // action.resetForm();

        },
    })

    const add_update_res = () => {
        if (updatePropertyRes && updatePropertyRes.success) {
            setBool(!bool)
            // setLoading(false)
            toast.success('Data Submitted Successfully!!.', { position: "bottom-right" })
        } else {
            setLoading(false)
            toast.error('Something Went Wrong!!..', { position: "bottom-right" })
        }
    }

    const deletePropertyfun = (id) => {
        Swal.fire({
            title: "Plot Delete",
            text: "Are You Sure Want To Delete This Plot ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deletePropertyTodo(id)).then(() => propertyDelete())
            }
        })
    }

    const propertyDelete = () => {
        if (deletePropertyRes && deletePropertyRes.success) {
            Swal.fire(
                "Property",
                'Property Deleted Successfully',
                'success'
            )
            setBool(!bool)
        } else {
            toast.error('Something Went Wrong!!..', { position: "bottom-right" })

        }
    }

    // const deletePropertyfun = (id) => {
    //     dispatch(deletePropertyTodo(id)).then(() => propertyDelete())
    // }

    useEffect(() => {
        setLoading(true)
        dispatch(addPropertyListTodo(projectId)).then(() => propertyListFun())
    }, [bool])

    const propertyListFun = () => {
        if (addPropertyListRes && addPropertyListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)

        }
    }
    const plotBook = (value) => {
        // dispatch(propertyBookTodo({ 'plot': value.property_number, 'project': projectId })).then(() => book_res())
        Swal.fire({
            title: "Plot Book",
            text: "Are You Sure Want To Book This Plot ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: 'green',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Book'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(propertyBookTodo({ 'plot': value.property_number, 'project': projectId })).then(() => book_res())
            }
        })
    }

    const book_res = () => {
        if (propertyBookRes && propertyBookRes.success) {
            Swal.fire(
                "Plot",
                'Plot Booked Successfully',
                'success'
            )
            setBool(!bool)
        } else {
            toast.error(propertyBookRes && propertyBookRes.message, { position: "bottom-right" });
        }
    }

    return (
        <>
            <div className='container'>

                <div className='mt-2'>
                    {loading ?
                        <div className='d-flex justify-content-center mt-5'>
                            <BallTriangle
                                height={100}
                                width={100}
                                radius={5}
                                color="#105684"
                                ariaLabel="ball-triangle-loading"
                                wrapperclassName="justify-content-center"
                                wrapperStyle={{}}
                                visible={true}
                            />
                        </div>
                        :
                        <FormikProvider value={formik}>
                            <div className=''>
                                <form onSubmit={(e) => { e.preventDefault(); formik.handleSubmit(); }}>
                                    <FieldArray
                                        name="data"
                                        render={(arrayHelpers) => (
                                            <>
                                                {formik && formik.values && formik.values.data && formik.values.data.map((field, index) => (
                                                    <div className="row" key={index}>
                                                        <input type="text"
                                                            className="form-control gh_form"
                                                            name={`data[${index}].property_id`}
                                                            value={formik.values.data[index].property_id}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            hidden
                                                        />

                                                        <div className="col-md-2 mb-3" key={index}>
                                                            {index == 0 ?
                                                                <label htmlFor="property_number" style={{ color: "#105684", fontSize: '16px' }} className="form-label gh_label">Plot No.</label>
                                                                : ''}
                                                            <input type="text"
                                                                className="form-control gh_form"
                                                                name={`data[${index}].property_number`}
                                                                value={formik.values.data[index].property_number}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                readOnly={field.property_status === 'hold' ? true : field.property_status === 'booked' ? true : false}
                                                            />
                                                        </div>

                                                        <div className="col-md-2 mb-3" key={index}>
                                                            {index == 0 ?
                                                                <label htmlFor="property_length" style={{ color: "#105684", fontSize: '16px' }} className="form-label gh_label">Plot Length</label>
                                                                : ''}
                                                            <input type="text"
                                                                className="form-control gh_form"
                                                                name={`data[${index}].property_length`}
                                                                value={formik.values.data[index].property_length}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                readOnly={field.property_status === 'hold' ? true : field.property_status === 'booked' ? true : false}

                                                            />
                                                        </div>

                                                        <div className="col-md-2 mb-3" key={index}>
                                                            {index == 0 ?
                                                                <label htmlFor="property_width" style={{ color: "#105684", fontSize: '16px' }} className="form-label gh_label">Plot Width</label>
                                                                : ''}

                                                            <input type="text"
                                                                className="form-control gh_form"
                                                                name={`data[${index}].property_width`}
                                                                value={formik.values.data[index].property_width}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                readOnly={field.property_status === 'hold' ? true : field.property_status === 'booked' ? true : false}

                                                            />
                                                        </div>

                                                        <div className="col-md-2 mb-3" key={index}>
                                                            {index == 0 ?
                                                                <label htmlFor="cost" style={{ color: "#105684", fontSize: '16px' }} className="form-label gh_label">Plot Cost</label>
                                                                : ''}
                                                            <input type="text"
                                                                className="form-control gh_form"
                                                                name={`data[${index}].cost`}
                                                                value={formik.values.data[index].cost}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                readOnly={field.property_status === 'hold' ? true : field.property_status === 'booked' ? true : false}

                                                            />
                                                        </div>
                                                        <div className="col-md-2 mb-3">
                                                            {index == 0 ?
                                                                <label htmlFor="property_cost" style={{ color: "#105684", fontSize: '16px' }} className="form-label gh_label">Plot Status</label>
                                                                : ''}
                                                            <div className='d-flex justify-content-start'>
                                                                <span style={{ textTransform: "capitalize" }} className={field.property_status === 'available' ? 'status_availabel ' : field.property_status === 'hold' ? 'status_hold text-center' : field.property_status === 'booked' ? 'status_booked ' : ''}> {field.property_status}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 mb-3">
                                                            {index == 0 ?
                                                                <label htmlFor="property_cost" style={{ color: "#105684", fontSize: '16px' }} className="form-label gh_label">Action</label>
                                                                : ''}
                                                            <div className='mt-1'>
                                                                {field.property_status === 'hold' ? <span>-</span> :
                                                                    field.property_status === 'booked' ? <span>-</span> : <span style={{ cursor: 'pointer' }}><MdDeleteOutline onClick={() => { deletePropertyfun(field.property_id) }} size={20} style={{ color: '#EF3D50' }} /></span>}
                                                                {field.property_status === 'available' ? <button className='btn ms-2 book_prty' onClick={() => { plotBook(field) }} type='button'>  Book</button> : ''}
                                                            </div>

                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    />

                                    <div className='position-fixed' style={{ top: "87%", right: '8%' }}>
                                        <div className='text-end mt-3 position-absolute'>
                                            <button className='btn gh_btn' type='submit'>Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </FormikProvider>
                    }


                </div>
            </div >
            {/* <ToastContainer /> */}
        </>
    )
}
