import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var userActiveRes;
export const userActiveTodo = createAsyncThunk('userActive', async (data) => {

    // console.log("userActive", data)

    const res = await axios({
        method: "post",
        // mode: 'cors',
        url: process.env.REACT_APP_API_URL + 'user_status_active',
        data: {
            "userid":data.userId.toString(),
            "employer_id": data.employId,
            "status":"active"
    },
        crossDomain: true,
        headers: { "Content-Type": "application/json" }
    })
return userActiveRes = res.data
})