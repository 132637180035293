import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var  targetListRes;
export const targetListTodo = createAsyncThunk('targetList', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'target_list',
           
            headers: { "Content-Type": "application/json" }
        })
        return targetListRes = res.data

    } catch (error) {
        return targetListRes = error.response.data
    }
});

const targetListSlice = createSlice({
    name: 'targetList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(targetListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(targetListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default targetListSlice.reducer; 