import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var adminCommissionRes;
export const adminCommissionTodo = createAsyncThunk('adminCommission', async (data) => {
    console.log("dataId", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'admin_commission',
            data: {
                "user_id": JSON.parse(localStorage.getItem('user_data')).userId
            },
            headers: { "Content-Type": "application/json" }
        })
        return adminCommissionRes = res.data

    } catch (error) {
        return adminCommissionRes = error.response.data
    }
});

const adminCommissionSlice = createSlice({
    name: 'adminCommission',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(adminCommissionTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(adminCommissionTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default adminCommissionSlice.reducer; 
