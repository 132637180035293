import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { employeDesignationTodo } from '../../redux/slices/employeDesignation';
import { useFormik } from 'formik';
import { userActiveRes, userActiveTodo } from '../../redux/slices/user/UserActive';
import { toast } from 'react-toastify';
import { userActiveSchema } from '../../schemas';

export default function UserActivePopup(props) {
    const { userActive, setUserActive, userData, bool, setBool } = props;
    const dispatch = useDispatch()
    const designation = userData.designation_id
    const employDesignationList = useSelector(state => state && state.employDesignationList && state.employDesignationList.data && state.employDesignationList.data.data && state.employDesignationList.data.data.filter(status => status.status !== "inactive"))
    const userId = userData && userData.id

    const initialValues = {
        employe_id: ""
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: userActiveSchema,
        onSubmit: (values, action) => {
            console.log(values)
            dispatch(userActiveTodo({ employId: values.employe_id, userId:userId })).then(() => userActive_res())
            action.resetForm()
        },
    })

    const userActive_res = () => {
        if (userActiveRes && userActiveRes.success) {
            toast.success(userActiveRes && userActiveRes.message, { position: 'bottom-right' })
                setUserActive(false);
            setBool(!bool)
        } else {
            toast.error(userActiveRes && userActiveRes.message, { position: 'bottom-right' })
        }
    }

    useEffect(() => {
        dispatch(employeDesignationTodo(designation))
    }, [designation])

    return (
        <>
            <div className={userActive ? 'modal fade show d-block' : 'modal fade show d-none'} style={{ background: 'rgba(0,0,0,0.8)' }}>
                <div class="modal-dialog  modal-dialog-center modal-md">
                    <div class="modal-content pt-3 pb-5 ps-3 pe-3">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel"></h5>
                            <h5 style={{ color: "#105684" }} className='text-center'>User Active </h5>
                            <button type="button" class="btn-close" onClick={() => { setUserActive(false); }}></button>
                        </div>
                        <div class="modal-body ">
                            <form onSubmit={handleSubmit}>
                                <div className='text-center' >
                                    <p style={{ color:"grey" }}>Please Provide Employer To Activate User</p>
                                    <h5 style={{ textTransform: 'capitalize' }} className='text-start'>{userData.username} ({userData && userData.master_designation && userData.master_designation.designation})</h5>
                                    {/* <div className="col-md-8  gh_formWrapper"> */}
                                    {/* <label htmlFor="employer_id" className="form-label gh_label" id="employer_id_label">Employer </label> */}
                                    <select className="form-select gh_form"
                                        name='employe_id'
                                        id="employe_id"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.employe_id}
                                    >
                                        <option value=''>Select</option>
                                        {employDesignationList && employDesignationList.map((uservalue, index4) =>
                                            <option key={index4} value={uservalue.id}>{uservalue.username}</option>
                                        )}
                                    </select>
                                    {errors.employe_id && touched.employe_id ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.employe_id}</span>) : null}
                                    {/* </div> */}
                                </div>

                                <div className='mt-3 d-flex justify-content-center'>
                                    <button type='submit' className='btn btn-success gh_btn'>Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
