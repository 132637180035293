import React from 'react';
import { useLocation } from 'react-router';
import CustomerBookingList from './customerBookingList';

const CustomerDetails = () => {

    const state = useLocation();
    const customerData = state && state.state
    console.log("state", customerData)

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3">
                        <div className='userDtl_box scroller'>
                            <div className='view_dtails'>
                                <h5 className='text-center mb-2'>Customer Details</h5>
                                <img className='userImg' src="./profile.png" alt="" />
                                <h4 style={{ textTransform: "capitalize" }}>{customerData.name}</h4>


                                {/* <div className='date'>DOB: {new Date(userData && userData.datebirth).toLocaleDateString('en-GB')}</div> */}

                                <div className='date'>Added date: {new Date(customerData && customerData.added_date).toLocaleDateString('en-GB')}</div>

                                <hr />

                                <div className='gaganHomes_dataList'>
                                    <div className="d-flex justify-content-between align-items-center mt-1">
                                        <p className='head'>Email</p>
                                        <span className='body'>{customerData.email}</span>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mt-1">
                                        <p className='head'>Contact No.</p>
                                        <span className='body'>{customerData.mobile}</span>
                                    </div>

                                    <div className="d-flex justify-content-between align-items-center mt-1">
                                        <p className='head'>City</p>
                                        <span className='body' style={{ textTransform: "capitalize" }}>{customerData && customerData.city_master && customerData.city_master.city}</span>
                                    </div>

                                    <div className="d-flex justify-content-between align-items-center mt-1">
                                        <p className='head'>State</p>
                                        <span className='body' style={{ textTransform: "capitalize" }}>{customerData && customerData.state_master && customerData.state_master.state}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-9">
                        <CustomerBookingList />

                    </div>
                </div>
            </div>
        </>
    );
}

export default CustomerDetails;
