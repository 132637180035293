import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var adminTeamListRes;
export const adminTeamListTodo = createAsyncThunk('adminTeamList', async (id) => {

    // console.log("adminTeamList", id)

    const res = await axios({
        method: "post",
        // mode: 'cors',
        url: process.env.REACT_APP_API_URL + 'team_list',
        data: {
            "userid": JSON.parse(localStorage.getItem('user_data')).userId
        },
        crossDomain: true,
        headers: { "Content-Type": "application/json" }
    })
    return adminTeamListRes = res.data
})


const adminTeamListSlice = createSlice({
    name: 'adminTeamList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(adminTeamListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('adminTeamListTodo', state.data.response)
        });
        builder.addCase(adminTeamListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default adminTeamListSlice.reducer