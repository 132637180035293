import React, { useEffect, useState } from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { BallTriangle } from 'react-loader-spinner'
import { adminTeamListRes, adminTeamListTodo } from '../redux/slices/user/adminTeamList';

export default function TeamTree() {
    const { state } = useLocation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const userTeam = useSelector(state => state?.adminTeamList?.data?.data);
    var userId = JSON.parse(localStorage.getItem('user_data')).userId
    console.log('userTeam', userTeam)
    let userdatateam = userTeam && userTeam.team;

    let userTeamByOrdered = userdatateam && [...userdatateam].sort((a, b) => {
        console.log("d", a)
        const orderedA = parseInt(a && a.master_designation && a.master_designation.ordered);
        const orderedB = parseInt(b && b.master_designation && b.master_designation.ordered);
        return orderedA - orderedB;
    });

    console.log("userTeamByOrdered", userTeamByOrdered);



    useEffect(() => {
        setLoading(true)
        dispatch(adminTeamListTodo()).then(() => teamListFun())
    }, [])

    const teamListFun = () => {
        if (adminTeamListRes && adminTeamListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)

        }
    }
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='scnd_header'>
                            <span className='icon1'>Team / </span>
                            <span className='icon2'> Team Tree</span>
                        </div>
                        <div>
                            {loading ?
                                <div className='d-flex justify-content-center mt-5'>
                                    <BallTriangle
                                        height={100}
                                        width={100}
                                        radius={5}
                                        color="#105684"
                                        ariaLabel="ball-triangle-loading"
                                        wrapperclassName="justify-content-center"
                                        wrapperStyle={{}}
                                        visible={true}
                                    />
                                </div>
                                :
                                // userTeamByOrdered && userTeamByOrdered.length ?
                                <div className='mt-4 mb-4'>
                                    <div className='tree_child '>
                                        <Tree label={<span className="userLoginTree" style={{ textTransform: "capitalize" }} >{userTeam?.team?.[0]?.username} <br /> ({userTeam?.team?.[0]?.master_designation?.designation})</span>}>
                                            {userTeamByOrdered?.[0]?.emp?.map((value, index) => {
                                                return (
                                                    <>
                                                        {
                                                            index >= 0 ?

                                                                <TreeNode label={<span className={value.currentuser ? "userLoginTree" : ''}>{value.username} <br /> ({value && value.master_designation && value.master_designation.designation})</span>}>

                                                                    {value?.emp && value.emp.map((value2, index2) => {
                                                                        return (
                                                                            <>
                                                                                <TreeNode label={<span className={value2?.currentuser ? "userLoginTree" : ''}>{value2?.username} <br /> ({value2 && value2.master_designation && value2.master_designation.designation})</span>} >
                                                                                    {value2?.emp && value2.emp.map((value3, index2) => {
                                                                                        return (
                                                                                            <>
                                                                                                <TreeNode label={<span className={value3.currentuser ? "userLoginTree" : ''}>{value3?.username} <br /> ({value3 && value3.master_designation && value3.master_designation.designation})</span>} >
                                                                                                    {value3?.emp && value3.emp.map((value4, index4) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <TreeNode label={<span className={value4?.currentuser ? "userLoginTree" : ''}>{value4?.username} <br /> ({value4 && value4.master_designation && value4.master_designation.designation})</span>} >
                                                                                                                    {value4?.emp && value4.emp.map((value5, index5) => {

                                                                                                                        return (
                                                                                                                            <>
                                                                                                                                <TreeNode label={<span className={value5?.currentuser ? "userLoginTree" : ''}>{value5?.username} <br /> ({value5 && value5.master_designation && value5.master_designation.designation})</span>} >
                                                                                                                                    {value5?.emp && value5.emp.map((value6, index6) => {
                                                                                                                                        return (
                                                                                                                                            <>
                                                                                                                                                <TreeNode label={<span className={value6?.currentuser ? "userLoginTree" : ''}>{value6?.username} <br /> ({value6 && value6.master_designation && value6.master_designation.designation})</span>} >
                                                                                                                                                    {value6?.emp && value6.emp.map((value7, index7) => {
                                                                                                                                                        return (
                                                                                                                                                            <>
                                                                                                                                                                <TreeNode label={<span className={value7?.currentuser ? "userLoginTree" : ''}>{value7?.username} <br /> ({value7 && value7.master_designation && value7.master_designation.designation})</span>} >
                                                                                                                                                                    {value7?.emp && value7.emp.map((value8, index8) => {
                                                                                                                                                                        return (
                                                                                                                                                                            <>
                                                                                                                                                                                <TreeNode label={<span className={value8?.currentuser ? "userLoginTree" : ''}>{value8?.username} <br /> ({value8 && value8.master_designation && value8.master_designation.designation})</span>} >

                                                                                                                                                                                    {value8?.emp && value8.emp.map((value9, index6) => {
                                                                                                                                                                                        return (
                                                                                                                                                                                            <>
                                                                                                                                                                                                <TreeNode label={<span className={value9?.currentuser ? "userLoginTree" : ''}>{value9?.username} <br /> ({value9 && value9.master_designation && value9.master_designation.designation})</span>} >
                                                                                                                                                                                                    {value9?.emp && value9.emp.map((value10, index6) => {
                                                                                                                                                                                                        return (
                                                                                                                                                                                                            <>
                                                                                                                                                                                                                <TreeNode label={<span className={value10?.currentuser ? "userLoginTree" : ''}>{value10?.username} <br /> ({value10?.master_designation?.designation})</span>} />
                                                                                                                                                                                                            </>
                                                                                                                                                                                                        )
                                                                                                                                                                                                    })}
                                                                                                                                                                                                </TreeNode>
                                                                                                                                                                                            </>
                                                                                                                                                                                        )
                                                                                                                                                                                    })}
                                                                                                                                                                                </TreeNode>
                                                                                                                                                                            </>
                                                                                                                                                                        )
                                                                                                                                                                    })}
                                                                                                                                                                </TreeNode>
                                                                                                                                                            </>
                                                                                                                                                        )
                                                                                                                                                    })}
                                                                                                                                                </TreeNode>
                                                                                                                                            </>
                                                                                                                                        )
                                                                                                                                    })}
                                                                                                                                </TreeNode>

                                                                                                                            </>
                                                                                                                        )
                                                                                                                    })}
                                                                                                                </TreeNode>
                                                                                                            </>
                                                                                                        )
                                                                                                    })}
                                                                                                </TreeNode>
                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </TreeNode>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </TreeNode>

                                                                : ''}

                                                    </>
                                                )

                                            })}
                                        </Tree>

                                    </div >
                                </div>
                                // :
                                // <div className='empty_data_msg text-center mt-5'>
                                //     <h4>Team Not Found</h4>
                                // </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
