import React, { useEffect , useState} from 'react';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { commissionListTodo } from '../../redux/slices/commisionListSlice';
import { targetListTodo } from '../../redux/slices/targetListSlice';
import { updateCommissionTargetRes, updateCommissionTargetTodo } from '../../redux/slices/updateCommissiontarget';
import { ToastContainer, toast } from 'react-toastify';
import { updateCommissionTargetSchema } from '../../schemas';
import { BallTriangle } from 'react-loader-spinner'

const CommissionListUpdate = () => {

    const dispatch = useDispatch()
    const commissionListData = useSelector(state => state && state.targetList && state.targetList.data && state.targetList.data.data && state.targetList.data.data.rows)
    const [loading, setLoading] = useState(false)

    var commission_data = []

    const commission_date2 = commissionListData && commissionListData.map((value) => commission_data.push({
        designation_id: value && value.designation && value.designation.id,
        designation: value && value.designation && value.designation.designation,
        from_target: value.from_target,
        to_target: value.to_target,
    }))

    const initialValues = {
        data: commissionListData && commission_data,
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: updateCommissionTargetSchema,
        enableReinitialize: true,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(updateCommissionTargetTodo(values)).then(() => updateCommissionTarget())

        },
    })

    const updateCommissionTarget = () => {
        if (updateCommissionTargetRes && updateCommissionTargetRes.success) {
            setLoading(false)
            toast.success('Commission Update Successfully!!.', { position: "bottom-right" })

        } else {
            setLoading(false)
            toast.error(updateCommissionTargetRes && updateCommissionTargetRes.message, { position: "bottom-right" })

        }
    }


    useEffect(() => {
        // dispatch(commissionListTodo())
        dispatch(targetListTodo())
    }, [])


    return (
        <>
            <div className='container-fluid'>
                <div className='wrapper'>
                    <div>
                        <h5 style={{ color: "#105684" }}>Target Update</h5>
                    </div>
                    {loading ?
                        <div>
                            <div className='d-flex justify-content-center mt-5'>
                                <BallTriangle
                                    height={100}
                                    width={100}
                                    radius={5}
                                    color="#105684"
                                    ariaLabel="ball-triangle-loading"
                                    wrapperclassName="justify-content-center"
                                    wrapperStyle={{}}
                                    visible={true}
                                />
                            </div>
                        </div>
                        :
                    <FormikProvider value={formik}>
                        <div className='mt-2'>
                            <form onSubmit={(e) => { e.preventDefault(); formik.handleSubmit(); }}>


                                <FieldArray
                                    name="data"
                                    render={(arrayHelpers) => (
                                        <>
                                            {formik && formik.values && formik.values.data && formik.values.data.map((field, index) => (
                                                <div className="row" key={index}>
                                                    <input type="text"
                                                        className="form-control gh_form"
                                                        name={`data[${index}].designation_id`}
                                                        value={formik.values.data[index].designation_id}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}

                                                        hidden
                                                    />

                                                    <div className="col-md-3 mb-3" key={index}>
                                                        {index == 0 ?
                                                            <label htmlFor="property_number" style={{ color: "#105684", fontSize: '18px' }} className="form-label gh_label">S.No.</label>
                                                            : ''}

                                                        <span className="form-control gh_form"> {index + 1}</span>
                                                    </div>

                                                    <div className="col-md-3 mb-3" key={index}>
                                                        {index == 0 ?
                                                            <label htmlFor="designation" style={{ color: "#105684", fontSize: '18px' }} className="form-label gh_label">Designation</label>
                                                            : ''}
                                                        <input type="text"
                                                            className="form-control gh_form"
                                                            name={`data[${index}].designation`}
                                                            value={formik.values.data[index].designation}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            readOnly

                                                        />
                                                    </div>

                                                    <div className="col-md-3 mb-3" key={index}>
                                                        {index == 0 ?
                                                            <label htmlFor="from_target" style={{ color: "#105684", fontSize: '18px' }} className="form-label gh_label">From Target</label>
                                                            : ''}

                                                        <input type="text"
                                                            className="form-control gh_form"
                                                            name={`data[${index}].from_target`}
                                                            value={formik.values.data[index].from_target}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}

                                                        />
                                                        {formik.touched.data && formik.touched.data[index] && formik.touched.data[index].from_target && formik.errors.data && formik.errors.data[index] && formik.errors.data[index].from_target ?
                                                            (<span className='text-danger form_label'> {formik.errors.data[index].from_target}</span>) : null
                                                        }

                                                    </div>

                                                    <div className="col-md-3 mb-3" key={index}>
                                                        {index == 0 ?
                                                            <label htmlFor="to_target" style={{ color: "#105684", fontSize: '18px' }} className="form-label gh_label">To Target</label>
                                                            : ''}
                                                        <input type="text"
                                                            className="form-control gh_form"
                                                            name={`data[${index}].to_target`}
                                                            value={formik.values.data[index].to_target}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}

                                                        />
                                                          {formik.touched.data && formik.touched.data[index] && formik.touched.data[index].to_target && formik.errors.data && formik.errors.data[index] && formik.errors.data[index].to_target ?
                                                            (<span className='text-danger form_label'> {formik.errors.data[index].to_target}</span>) : null
                                                        }
                                                    </div>

                                                </div>
                                            ))}
                                        </>
                                    )}
                                />

                                <div className='text-end mt-3'>
                                    <button className='btn gh_btn' type='submit'>Submit</button>
                                </div>
                            </form>
                        </div>
                    </FormikProvider>}
                </div>
            </div >
            <ToastContainer />
        </>
    );
}

export default CommissionListUpdate;
