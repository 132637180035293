import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var approveBookingRes;
export const approveBookingTodo = createAsyncThunk('approveBooking', async (id) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'approve_booking_list',
            data: {
                "booking_id": id
            },
            headers: { "Content-Type": "application/json" }
        })
        return approveBookingRes = res.data

    } catch (error) {
        return approveBookingRes = error.response.data
    }
});

const approveBookingSlice = createSlice({
    name: 'approveBooking',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(approveBookingTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(approveBookingTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default approveBookingSlice.reducer; 
