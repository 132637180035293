import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var designationListRes;
export const designationListTodo = createAsyncThunk('designationList', async (data) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'designation_list',
           
            headers: { "Content-Type": "application/json" }
        })
        return designationListRes = res.data

    } catch (error) {
        return designationListRes = error.response.data
    }
});

const designationListSlice = createSlice({
    name: 'designationList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(designationListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(designationListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default designationListSlice.reducer; 