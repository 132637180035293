import React, { useState, useEffect } from 'react';
import DataTbl from '../../table/table';
import { useNavigate } from 'react-router';
import { FiMonitor } from "react-icons/fi";
import { BallTriangle } from 'react-loader-spinner'
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { customerListRes, customerListTodo } from '../../redux/slices/customerList';
import { customerBookingRes, customerBookingTodo } from '../../redux/slices/customerBookingList';

const CustomerBookingList = () => {

    const state = useLocation()
    var customerID = state && state.state && state.state.id
    // console.log("customerID",customerID)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const data = useSelector(state => state && state.customerBookingList && state.customerBookingList.data && state.customerBookingList.data.data && state.customerBookingList.data.data[1])
    console.log("customerBooking", data)

    const navigate = useNavigate();
    const userDetailsLink = (value) => {
        navigate('../BookingDetails', { state: value })
    }

    const exportcolumn = ['project_master.project_name', 'property_detail.property_number', "booking_amount", "booking_status"]

    const [columnDefs1, setColumnDefs1] = useState([
        {
            headerName: 'S.No.',
            valueGetter: 'node.rowIndex + 1',
            wrapText: true,
            maxWidth: 100,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '200px'
        },
        {
            headerName: "Project Name",
            field: "project_master.project_name",
            wrapText: true,
            cellRenderer: params => <span style={{ textTransform: 'capitalize' }}>{params && params.data && params.data.project_master && params.data.project_master.project_name}</span>,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
            //  width: '200px'
        },


        {
            headerName: "Plot No.",
            field: "property_detail.property_number",
            cellRenderer: params => <span >{params && params.data && params.data.property_detail && params.data.property_detail.property_number}</span>,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '150px'
        },
        {
            headerName: "Booking Amount",
            field: "booking_amount",
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
            // width: '150px'
        },
        {
            headerName: "Booking Status",
            field: "booking_status",
            wrapText: true,
            cellRenderer: params => <span style={{ textTransform: "capitalize" }} className={params && params.data && params.data.booking_status === 'available' ? 'status_availabel' : (params && params.data && params.data.booking_status) === 'hold' ? 'status_hold' : (params && params.data && params.data.booking_status) === 'booked' ? 'status_booked' :(params && params.data && params.data.booking_status) === 'rejected' ? 'status_rejected' : ''} >
                {params.data.booking_status}</span>,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
             width: '150px'
        },

        {
            headerName: "Action",
            wrapText: true,
            cellRenderer: params => <div className='viewEdit_btn'>

                {/* <span><BiSolidEdit /></span> */}
                <span onClick={() => userDetailsLink(params.data)}><FiMonitor /></span>

            </div>,
            autoHeight: true,
            width: '130px',
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },

    ])
    const customerListFun = () => {
        if (customerBookingRes && customerBookingRes.success) {
            setLoading(false)
        } else {
            setLoading(false)

        }
    }

    useEffect(() => {

        setLoading(true)
        dispatch(customerBookingTodo(customerID)).then(() => customerListFun())

    }, [])


    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='userDtl_box p-3'>
                            <h5>Customer Booking</h5>
                            {loading ?

                                <div className='d-flex justify-content-center mt-5'>
                                    <BallTriangle
                                        height={100}
                                        width={100}
                                        radius={5}
                                        color="#105684"
                                        ariaLabel="ball-triangle-loading"
                                        wrapperclassName="justify-content-center"
                                        wrapperStyle={{}}
                                        visible={true}
                                    />
                                </div>
                                :
                                data && data.length
                                    ?
                                    <DataTbl
                                        data={data ? data : ''}

                                        columnDefs={columnDefs1}
                                        exportcolumn={exportcolumn}
                                    /> 
                                    :
                                    <div className='empty_data_msg text-center mt-5'>
                                        <h4>Customer Booking List Not Found</h4>
                                    </div>
                            }
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default CustomerBookingList;
