import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var dashboardRes;
export const dashboardTodo = createAsyncThunk('dashboard', async (data) => {

    // console.log("+id+", data)

    const res = await axios({
        method: "post",
        // mode: 'cors',
        url: process.env.REACT_APP_API_URL + 'admin_dashboard',
        crossDomain: true,
        headers: { "Content-Type": "application/json" }
    })
    return dashboardRes = res.data
})


const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(dashboardTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('dashboardTodo', state.data.response)
        });
        builder.addCase(dashboardTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default dashboardSlice.reducer