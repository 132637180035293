import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTbl from '../../table/table';
import { useNavigate } from 'react-router-dom';
import { FiMonitor } from "react-icons/fi";
import { projectListRes, projectListTodo } from '../../redux/slices/projectList';
import { BallTriangle } from 'react-loader-spinner'

const PropertyList = () => {

    const [loading, setLoading] = useState(true)

    const dispatch = useDispatch()
    const data = useSelector(state => state && state.projectList && state.projectList.data && state.projectList.data.data && state.projectList.data.data.rows);
    // console.log("propertyList", data)


    const navigate = useNavigate();
    const userDetailsLink = (value) => {
        navigate('../propertyDetails', { state: value })
    }

    const exportcolumn = ['project_name', 'project_size', "project_status", "company_name"]

    const [columnDefs1, setColumnDefs1] = useState([
        {
            headerName: 'S.No.',
            valueGetter: 'node.rowIndex + 1',
            wrapText: true,
            maxWidth: 100,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '200px'
        },
        {
            headerName: "Project Name",
            field: "project_name",
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '200px'
        },
        {
            headerName: "Size",
            field: "project_size",
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
            width: '120px'
        },
        {
            headerName: "Image",
            field: "mobile",
            cellRenderer: params => <div className='table_img_sec'>

                <img className=" img-fluid table_data_img" src={params && params.data && params.data.project_image_url + (params &&params.data && params.data.project_image)} alt="image" />

                {/* <img className=" img-fluid table_data_img" src={params.data.project_image_url + (params &&params.data && params.data.project_image)} alt="image" /> */}

            </div>,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },
        {
            headerName: "Project Status",
            field: "project_status",
            wrapText: true,
            autoHeight: true,
            cellRenderer: params => <span style={{ textTransform: "capitalize" }} className={params.data.project_status === 'available' ? 'status_availabel' : params.data.project_status === 'hold' ? 'status_hold' : (params && params.data && params.data.project_status) === 'booked' ? 'status_booked' : ''} >
                {params && params.data && params.data.project_status}</span>,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },
        {
            headerName: "Company Name",
            field: "company_name",
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },
        {
            headerName: "Action",
            wrapText: true,
            cellRenderer: params => <div className='viewEdit_btn'>
                <span type='button' onClick={() => userDetailsLink(params && params.data)}><FiMonitor /></span>
            </div>,
            autoHeight: true,
            width: '130px',
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },

    ])

    useEffect(() => {
        dispatch(projectListTodo()).then(() => propertyList_fun())
    }, []);

    const propertyList_fun = () => {
        if (projectListRes && projectListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }


    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='scnd_header'>
                            <span className='icon1'>Plot / </span>
                            <span className='icon2'> Plot List</span>
                        </div>
                        {loading ?
                            <div className='d-flex justify-content-center mt-5'>
                                <BallTriangle
                                    height={100}
                                    width={100}
                                    radius={5}
                                    color="#105684"
                                    ariaLabel="ball-triangle-loading"
                                    wrapperclassName="justify-content-center"
                                    wrapperStyle={{}}
                                    visible={true}
                                />
                            </div>
                            :
                            data && data.length
                                ?
                                <div className='wrapper'>
                                    <DataTbl
                                        data={data ? data : ''}
                                        columnDefs={columnDefs1}
                                        exportcolumn={exportcolumn}
                                    />
                                </div>
                                :
                                <div className='empty_data_msg text-center mt-5'>
                                    <h4>Property List Not Found</h4>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default PropertyList;
