import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var userBookingListRes;
export const userBookingListTodo = createAsyncThunk('userBookingList', async (id) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'booking_list',
           data:{
            "userid":id,
            "projectid":"",
            "propertyid":"",
            "customerid":"",
            "startDate":"",
            "endDate":"",
            "bstatus":"",
            "cid":'',
            "rejectednot": ""
        },
            headers: { "Content-Type": "application/json" }
        })
        return userBookingListRes = res.data

    } catch (error) {
        return userBookingListRes = error.response.data
    }
});

const userBookingListSlice = createSlice({
    name: 'userBookingList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(userBookingListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(userBookingListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default userBookingListSlice.reducer; 
