import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import DataTbl from '../../table/table'
import { BallTriangle } from 'react-loader-spinner'
import { useDispatch, useSelector } from 'react-redux'
import { addPropertyListRes, addPropertyListTodo } from '../../redux/slices/AddForm/addPropertyList'

export default function ProjectPropertyList() {

    const [loading, setLoading] = useState(true)

    const state = useLocation()
    const projectPropertyId = state.state.id
    // console.log("projectPropertyId", projectPropertyId)

    const dispatch = useDispatch()

    const propertyProject = useSelector(state => state && state.addPropertyList && state.addPropertyList.data && state.addPropertyList.data.data && state.addPropertyList.data.data.rows[0] && state.addPropertyList.data.data.rows[0].property_details)

    // console.log("addPropertyListId--", propertyProject)

    const exportcolumn = ['property_number', 'property_length', "property_width", "property_size", "cost", "property_status"]

    const [columnDefs1, setColumnDefs1] = useState([
        {
            headerName: 'S.No.',
            valueGetter: 'node.rowIndex + 1',
            wrapText: true,
            maxWidth: 100,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '200px'
        },
        {
            headerName: "Plot No.",
            field: "property_number",
            // cellRenderer: params => <div className='table_img_sec'>
            //     <span>{params && params.data && params.data.property_number}</span>
            // </div>,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '130px'
        },
        {
            headerName: " length",
            field: "property_length",
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '110px'
        },
        {
            headerName: " Width",
            field: "property_width",
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '100px'
        },
        {
            headerName: " Size",
            field: "property_size",
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '120px'
        },
        {
            headerName: " cost",
            field: "cost",
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '150px'
        },
        {
            headerName: "Plot Status",
            field: "property_status",
            // cellRenderer: params => <div className='table_img_sec'>
            //     <span style={{textTransform:"capitalize"}}>{params.data.property_status}</span>
            // </div>,
            cellRenderer: params => <span style={{ textTransform: "capitalize" }} className={params && params.data && params.data.property_status === 'available' ? 'status_availabel' : params && params.data &&params.data.property_status === 'hold' ? 'status_hold' : params && params.data && params.data.property_status === 'booked' ? 'status_booked' : ''} >
                {params && params.data && params.data.property_status}</span>,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, width: '150px'
        },
        // {
        //     headerName: "Status",
        //     field: "status",
        //     wrapText: true,
        //     autoHeight: true,
        //     filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '100px'
        // },

    ])

    useEffect(() => {
        dispatch(addPropertyListTodo(projectPropertyId)).then(() => { addProject_fun() })
    }, [])

    const addProject_fun = () => {
        if (addPropertyListRes && addPropertyListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    return (
        <>
            <div className='container'>
                {loading ?

                    <div className='d-flex justify-content-center mt-5'>
                        <BallTriangle
                            height={100}
                            width={100}
                            radius={5}
                            color="#105684"
                            ariaLabel="ball-triangle-loading"
                            wrapperclassName="justify-content-center"
                            wrapperStyle={{}}
                            visible={true}
                        />
                    </div>
                    :
                    propertyProject && propertyProject.length
                        ?
                        <div className='wrapper'>
                            <h4 className='mb-4'>Plot List</h4>
                            <DataTbl
                                data={propertyProject ? propertyProject : ''}
                                columnDefs={columnDefs1}
                            />
                        </div>
                        :
                        <div className='empty_data_msg text-center mt-5'>
                            <h4>Property Project List Not Found</h4>
                        </div>
                }

            </div>
        </>
    )
}
