import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import '../../gaganHoms_css/viewDetails.css'
import AddForm from './addForm';
import AddProperty from './AddProperty';
import { AiFillFileExcel } from 'react-icons/ai';
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { addPropertyListTodo } from '../../redux/slices/AddForm/addPropertyList';
import { exceluploadRes, exceluploadTodo } from '../../redux/slices/excelFileUpload';
import xlsx from "json-as-xlsx"


export default function PropertyDetails() {

    const state = useLocation();
    const projectData = state.state
    const projectId = state && state.state && state.state.id
    // console.log("projectData",projectId)
    const [addProperty, setAddProperty] = useState(false)
    const [proId, setProId] = useState('')
    const [bool, setBool] = useState(false)
    const dispatch = useDispatch()
    const propertyList = useSelector(state => state && state.addPropertyList && state.addPropertyList.data && state.addPropertyList.data.data && state.addPropertyList.data.data.rows[0] && state.addPropertyList.data.data.rows[0].property_details)
    console.log("propertyList", propertyList)

    const handleAddProperty = (id) => {
        setAddProperty(true);
        setProId(id)
    }

    const initialValues = {
        excelfile: '',
    };




    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        onSubmit: (values, action) => {
            console.log("excel", values)
            dispatch(exceluploadTodo(bodyFormData)).then(() => excelupload())
            document.getElementById('excelfile').value = '';

        },
    })

    let bodyFormData = new FormData();
    bodyFormData.append("project_id", projectId)
    bodyFormData.append("path", "category")
    bodyFormData.append("xfile", values.excelfile)

    const excelupload = () => {
        if (exceluploadRes && exceluploadRes.success) {
            toast.success(exceluploadRes && exceluploadRes.message, { position: "bottom-right" })
            setBool(!bool)

        } else {
            toast.error(exceluploadRes && exceluploadRes.message, { position: "bottom-right" })

        }
    }

    const propertyData = []
    console.log("propertyData", propertyData)

    const propertyDataRender = propertyList && propertyList.map((value) => propertyData.push({
        "id": value.id,
        "property_number": value.property_number,
        "property_width": value.property_width,
        "property_length": value.property_length,
        "cost": value.cost,
    }))

    const downloadFile = () => {
        let exceldata = [
            {
                columns: [
                    { label: "id", value: "id" },
                    { label: "property_number", value: "property_number" },
                    { label: "property_width", value: "property_width" },
                    { label: "property_length ", value: "property_length" },
                    { label: "cost", value: "cost" },
                ],
                content: propertyData
            },

        ]
        let settings = {
            fileName: "ExcelSheet1",
        }
        xlsx(exceldata, settings)

    }

    useEffect(() => {
   
        dispatch(addPropertyListTodo(projectId))
    }, [bool])


    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-3">

                        <div className='userDtl_box scroller'>
                            <div className='view_dtails'>

                                <img src={projectData ? ((projectData && projectData.project_image_url) + (projectData && projectData.project_image)) : "./DP_img.jpg"} alt="" />

                                <h4>{projectData && projectData.project_name}</h4>
                                {/* <h6>Company Name : <span>{projectData && projectData.company_name}</span></h6> */}
                                <div className='date'>Added date: {new Date(projectData.added_date).toLocaleDateString('en-GB')}</div>
                            </div>

                            <hr />

                            <div className='gaganHomes_dataList'>
                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Total plots</p>
                                    <span className='body'>{projectData && projectData.total_plots}</span>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Plot Status</p>
                                    <span style={{ textTransform: "capitalize" }} className={projectData.project_status === 'available' ? 'badge bg-primary' : projectData.project_status === 'hold' ? 'badge bg-warning' : (projectData && projectData.project_status) === 'booked' ? 'badge bg-success' : ''}>{projectData && projectData.project_status}</span>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Company Name</p>
                                    <span className='body'>{projectData && projectData.company_name}</span>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Size</p>
                                    <span className='body'>{projectData && projectData.project_size}</span>
                                </div>


                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Rer number</p>
                                    <span className='body'>{projectData && projectData.rera_number}</span>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Status</p>
                                    <span className='body'>{projectData && projectData.status}</span>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Street</p>
                                    <span className='body'>{projectData && projectData.street}</span>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Latitude</p>
                                    <span className='body'>{projectData && projectData.latitude}</span>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Longitude</p>
                                    <span className='body'>{projectData && projectData.longitude}</span>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Locality</p>
                                    <span className='body'>{projectData && projectData.locality}</span>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Sub locality</p>
                                    <span className='body'>{projectData && projectData.sub_locality}</span>
                                </div>


                                <div className="mt-2">
                                    <div className='head'>Plot Description</div>
                                    <div className='body'>{projectData && projectData.project_description}</div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-9 mt-3 mt-md-0">
                        <div className='d-flex justify-content-between align-items-center'>
                            <h5 style={{ color: "#105684" }}>Plot Update</h5>
                            <div className='d-flex'>
                                {/* <span className='me-2 exelSheet_icon'><AiFillFileExcel size={25} style={{color:"white"}} /></span> */}
                                <form onSubmit={handleSubmit}>
                                    <input type='file'
                                        id="excelfile"
                                        name='excelfile'
                                        onChange={(e) => { setFieldValue("excelfile", e.currentTarget.files[0]); handleSubmit() }}
                                        onBlur={handleBlur}
                                        className='file'
                                        style={{ display: 'none' }}
                                    />
                                    {/* <button className='btn gh_btn me-2'   type='button' htmlFor="excelfile">Export Excel <AiFillFileExcel  style={{ color: "white" }} /></button> */}

                                    <label className=' me-2 btn gh_btn' type='button' htmlFor="excelfile"> Import Excel <AiFillFileExcel className='mb-1' style={{ color: "white" }} /></label>
                                </form>
                                <button className='btn gh_btn me-2' onClick={downloadFile} type='submit'>Download Template</button>

                                <button className='btn gh_btn' onClick={() => handleAddProperty(projectId)} type='submit'>Add Plot</button>
                            </div>
                        </div>
                        <div className='userDtl_box scroller_scnd mt-2'>

                            <AddForm
                                projectId={projectData.id}
                                bool={bool} setBool={setBool}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <AddProperty addProperty={addProperty} bool={bool} setBool={setBool} setAddProperty={setAddProperty} proId={proId} setProId={setProId} />
            {/* <ToastContainer/> */}
      
        </>
    )
}