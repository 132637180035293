import '../gaganHoms_css/navigation.css'
import React, { useState } from 'react'
import { FaHandHoldingDollar } from "react-icons/fa6";
import { IoReorderThree, IoAnalyticsSharp } from "react-icons/io5";
import { AiFillHome, AiFillProject } from "react-icons/ai";
import { ImUser } from "react-icons/im";
import { RiHealthBookFill, RiFileList2Fill, RiContactsBook2Fill, RiFileCopy2Fill, RiUserShared2Fill, RiHome2Fill } from "react-icons/ri";
import { TiGroup } from "react-icons/ti";
import { MdGroup, MdModeEdit, MdOutlineAccountTree } from "react-icons/md";
import { IoLogOutSharp } from "react-icons/io5";
import { BsFileEarmarkTextFill } from "react-icons/bs";
import { Link, useNavigate } from 'react-router-dom';
import { BiChevronRight } from "react-icons/bi";
// import PropertyAttendance from '../PROPERTY-ATTENDANCE/PropertyAttendance';
// import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

export default function GaganHouseSideBar() {

    const navigate = useNavigate();
    const logout = () => {
        localStorage.clear();
        navigate('/Login');
    }

    // const [attendance, setAttendance] = useState(false)

    // const handleAttendance = () => {
    //     setAttendance(true)
    // }
    const msidebar = () => {
        return (
            <>
                <div className=" profile-header ">
                    <div className='text-center header-logo-section '>
                        <img src="../gagan-logo.jpg" className='img-fluid header-logo' alt="property-logo" />
                    </div>
                </div>
                <div className="offcanvas-body profile-sidebar-body">
                    <PerfectScrollbar>
                        <div className="accordion accordion-flush" id="accordionFlushExample">
                            <div className="accordion-item new-accordion">
                                <h2 className="accordion-header" id="flush-headingOne">
                                    <Link to='/Dashboard'>
                                        <button className="collapsed acc-btn" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseHome" aria-expanded="false" aria-controls="flush-collapseHome" data-bs-dismiss={mobileScreen ? "offcanvas" : ''}>
                                            <AiFillHome className='mb-1 me-2' size={18} />
                                            Home
                                        </button>
                                    </Link>
                                </h2>
                            </div>
                            <div className="accordion-item new-accordion">
                                <h2 className="accordion-header" style={{ paddingTop: "15px" }} id="flush-headingTwo">
                                    {/* <Link to='/property-project'> */}
                                    {/* <button className="collapsed acc-btn" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo" data-bs-dismiss={mobileScreen ? "offcanvas" : ''}>
                                        <BsFileEarmarkTextFill className='mb-1 me-2' size={18} /> Project
                                    </button> */}

                                    <button className="accordion-button acc-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                        <BsFileEarmarkTextFill className='mb-1 me-2' size={18} /> Project
                                    </button>
                                    {/* </Link> */}

                                    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <div>
                                                <Link to="/AddProject">
                                                    <span className='accordion_subMenu'>Add Project</span>
                                                </Link>
                                            </div>

                                            <div>
                                                <Link to="/ProjectList">
                                                    <span className='accordion_subMenu'>Project List</span>
                                                </Link>
                                            </div>

                                            {/* <div>
                                                <Link to="/AddForm">
                                                    <span className='accordion_subMenu'>Add form</span>
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </h2>
                            </div>


                            <div className="accordion-item new-accordion">
                                <h2 className="accordion-header" style={{ paddingTop: "15px" }} id="flush-headingThree">
                                    {/* <Link to='/Property' >  */}
                                    <button className="accordion-button collapsed acc-btn" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree" data-bs-dismiss={mobileScreen ? "offcanvas" : ''}>
                                        <RiHealthBookFill className='mb-1 me-2' size={18} /> Plot
                                    </button>
                                    {/* </Link> */}
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            {/* <div>
                                                <Link to="/addproperty">
                                                    <span className='accordion_subMenu'>Add Property</span>
                                                </Link>
                                            </div> */}
                                            <div>
                                                <Link to="/propertylist">
                                                    <span className='accordion_subMenu'>Plot List</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </h2>
                            </div>

                            <div className="accordion-item new-accordion">
                                <h2 className="accordion-header" style={{ paddingTop: "15px" }} id="flush-headingFour">

                                    <button className="accordion-button collapsed acc-btn" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour" data-bs-dismiss={mobileScreen ? "offcanvas" : ''} >
                                        <RiFileList2Fill className='mb-1 me-2' size={18} /> Booking
                                    </button>
                                    <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            {/* <div>
                                                <Link to="/AddUser">
                                                    <span className='accordion_subMenu'>Add User</span>
                                                </Link>
                                            </div> */}

                                            <div>
                                                <Link to="/bookinglist">
                                                    <span className='accordion_subMenu'>Booking List</span>
                                                </Link>
                                            </div>

                                            <div>
                                                <Link to="/reportSection">
                                                    <span className='accordion_subMenu'>Report Section</span>
                                                </Link>
                                            </div>

                                            <div>
                                                <Link to="/myCommission">
                                                    <span className='accordion_subMenu'>My Commission</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </h2>
                            </div>


                            <div className="accordion-item new-accordion">
                                <h2 className="accordion-header" style={{ paddingTop: "15px" }} id="flush-headingFive">
                                    <button className=" accordion-button acc-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive" data-bs-dismiss={mobileScreen ? "offcanvas" : ''}>
                                        <ImUser className='mb-1 me-2' size={18} /> User
                                    </button>
                                    <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <div>
                                                <Link to="/AddUser">
                                                    <span className='accordion_subMenu'>Add User</span>
                                                </Link>
                                            </div>

                                            <div>
                                                <Link to="/userlist">
                                                    <span className='accordion_subMenu'>User List</span>
                                                </Link>
                                            </div>
                                            <div>
                                                <Link to="/customerList">
                                                    <span className='accordion_subMenu'>Customer List</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </h2>
                            </div>
                            <div className="accordion-item new-accordion">
                                <h2 className="accordion-header" style={{ paddingTop: "15px" }} id="flush-headingSix">
                                    <button className=" accordion-button acc-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix" data-bs-dismiss={mobileScreen ? "offcanvas" : ''}>
                                        <FaHandHoldingDollar className='mb-1 me-2' size={18} /> Target
                                    </button>
                                    <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <div>
                                                {/* <Link to="/addcommission">
                                                    <span className='accordion_subMenu'>Add Commission </span>
                                                </Link> */}
                                            </div>
                                            <div>
                                                <Link to="/commissionlist">
                                                    <span className='accordion_subMenu'>Target List</span>
                                                </Link>
                                            </div>

                                        </div>
                                    </div>
                                </h2>
                            </div>
                            <div className="accordion-item new-accordion">
                                <h2 className="accordion-header" id="flush-headingTwelve">
                                    <Link to='/team-tree'>
                                    <button className=" collapsed acc-btn" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve" data-bs-dismiss={mobileScreen ? "offcanvas" : ''}>
                                        <MdOutlineAccountTree  className='mb-1 me-2' size={18} /> Team Tree
                                    </button>
                                    </Link>
                                </h2>

                            </div>

                            <div className="accordion-item new-accordion">
                                <h2 className="accordion-header" id="flush-headingTwelve">
                                    {/* <Link to='/login'> */}
                                    <button className=" collapsed acc-btn" type="button" onClick={logout} data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve" data-bs-dismiss={mobileScreen ? "offcanvas" : ''}>
                                        <IoLogOutSharp className='mb-1 me-2' size={18} /> Logout
                                    </button>
                                    {/* </Link> */}
                                </h2>

                            </div>
                        </div>
                    </PerfectScrollbar>
                </div>
                {/* <PropertyAttendance attendance={attendance} setAttendance={(bool) => setAttendance(bool)} /> */}
            </>
        )
    }

    let mobileScreen = window.screen.width <= 992;

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    {mobileScreen ? (<div className="offcanvas offcanvas-start profile-sidebar " data-bs-scroll="true" tabindex="-1" id="sideBar" aria-labelledby="offcanvasWithBothOptionsLabel" >{msidebar()}</div>)
                        :
                        (<div id="dashboard-sidebar">{msidebar()}</div>)}
                </div>
            </div>
        </>
    )
}
