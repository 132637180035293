import React, { useEffect, useState } from 'react'
import DataTbl from '../../table/table'
import { useDispatch, useSelector } from 'react-redux'
import { BallTriangle } from 'react-loader-spinner'
import { FiMonitor } from "react-icons/fi";
import { customerListRes, customerListTodo } from '../../redux/slices/customerList'
import { useNavigate } from 'react-router';
import { BiSolidEdit } from 'react-icons/bi';

export default function CustomerList() {

    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const data = useSelector(state => state && state.customerList && state.customerList.data && state.customerList.data.data && state.customerList.data.data.rows)

    console.log("customerListData", data)

    const upDateList = (value) => {
        navigate("../update-customer", { state: value })
    }

    const constumerDetails = (value) => {
        navigate('../customerDetails', { state: value })
    }
    const exportcolumn = ['name', 'email', "mobile", "state_master.state", "city_master.city"]
    const [columnDefs1, setColumnDefs1] = useState([
        {
            headerName: 'S.No.',
            valueGetter: 'node.rowIndex + 1',
            wrapText: true,
            maxWidth: 90,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },
        {
            headerName: "Name",
            field: "name",
            wrapText: true,
            autoHeight: true, width: '200px',
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },
        {
            headerName: "Email",
            field: "email",
            wrapText: true,
            width: '250px',
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },
        {
            headerName: "Mobile",
            field: "mobile",
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '150px',
        },
        {
            headerName: "State",
            field: "state_master.state",
            cellRenderer: params => params && params.data && params.data.city_master && params.data.state_master.state,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },
        {
            headerName: "City",
            field: "city_master.city",
            cellRenderer: params => params && params.data && params.data.city_master && params.data.city_master.city,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },

        {
            headerName: "Action",
            wrapText: true,
            cellRenderer: params =>

                <div className='viewEdit_btn'>
                    <span  onClick={() => { upDateList(params && params.data) }}><BiSolidEdit style={{ color: 'green' }} size={22} /></span>
                    <span className='ms-2' onClick={() => constumerDetails(params.data)}><FiMonitor /></span>
                </div>,
            autoHeight: true, width: "100px",
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },

    ])

    useEffect(() => {
        setLoading(true)
        dispatch(customerListTodo()).then(() => customerListFun())
    }, [])

    const customerListFun = () => {
        if (customerListRes && customerListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)

        }
    }


    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='scnd_header'>
                            <span className='icon1'>User / </span>
                            <span className='icon2'> Customer List</span>
                        </div>
                        {loading ?
                            <div className='d-flex justify-content-center mt-5'>
                                <BallTriangle
                                    height={100}
                                    width={100}
                                    radius={5}
                                    color="#105684"
                                    ariaLabel="ball-triangle-loading"
                                    wrapperclassName="justify-content-center"
                                    wrapperStyle={{}}
                                    visible={true}
                                />
                            </div>
                            :

                            data ?

                                <div className='wrapper'>
                                    <DataTbl
                                        data={data ? data : ''}
                                        columnDefs={columnDefs1}
                                        exportcolumn={exportcolumn}
                                    />
                                </div> :
                                <div className='empty_data_msg text-center mt-5'>
                                    <h4>Customer List Not Found</h4>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
