import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var projectListRes;
export const projectListTodo = createAsyncThunk('projectList', async () => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'project_list',
        //    data:{
        //         "userid":""
        //    },
            headers: { "Content-Type": "application/json" }
        })
        return projectListRes = res.data

    } catch (error) {
        return projectListRes = error.response.data
    }
});

const projectListSlice = createSlice({
    name: 'projectList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(projectListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(projectListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default projectListSlice.reducer; 