import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var bookingLIstRes;
export const bookingLIstTodo = createAsyncThunk('bookingLIst', async (data) => {
    console.log("data//", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'booking_list',
            data: {
                "userid": "",
                "projectid":"",
                "propertyid":"",
                "customerid":"",
                "startDate":'',
                "endDate":"",
                "bstatus":"",
                "cid":'',
                "rejectednot": "rejected"
            },
            
            headers: { "Content-Type": "application/json" }
        })
        return bookingLIstRes = res.data

    } catch (error) {
        return bookingLIstRes = error.response.data
    }
});

const bookingLIstSlice = createSlice({
    name: 'bookingLIst',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(bookingLIstTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(bookingLIstTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default bookingLIstSlice.reducer; 
