import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { stateListRes, stateListTodo } from '../../redux/slices/StateListSlice';
import { cityListTodo } from '../../redux/slices/cityListSlice';
import { updateCustomerValidation } from '../../schemas';
import { CustomerUpdateRes, CustomerUpdateTodo } from '../../redux/slices/user/UpdateCustomer';
import { ToastContainer, toast } from 'react-toastify';

export default function UpdateCustomer() {
    const dispatch = useDispatch()
    const { state } = useLocation()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const stateList = useSelector(state => state?.stateList?.data?.data)
    const cityList = useSelector(state => state?.cityList?.data?.data)
    console.log(state)

    const initialValues = {
        customer_id:state?.id,
        customer_name: state?.name,
        mobile_num: state?.mobile,
        email: state?.email,
        state: state?.state,
        city: state?.city,

    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: updateCustomerValidation,
        enableReinitialize: 'true',
        onSubmit: (values, action) => {
            // console.log(values)
            dispatch(CustomerUpdateTodo(values)).then(() => userupdatefnc(action))

        },
    })
    const userupdatefnc = () => {
        if (CustomerUpdateRes?.success) {
            toast.success(CustomerUpdateRes && CustomerUpdateRes.message, { position: "bottom-right" });
            setTimeout(() => {
                navigate('/customerList')
            }, 1000);
        } else {
            toast.error(CustomerUpdateRes && CustomerUpdateRes.message, { position: "bottom-right" });
           
        }
    }

    const selectStateID = (id) => {
        dispatch(cityListTodo(id)).then()
    }

    useEffect(() => {
        setLoading(true)
        dispatch(stateListTodo()).then(() => list_res())
    }, [])

    const list_res = () => {
        if (stateListRes?.success) {
            dispatch(cityListTodo(state?.state)).then((city_res) => {
                if (city_res?.payload?.success) {
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            })
        } else {
            setLoading(false)
        }
    }


    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className='scnd_header'>
                        <span className='icon1'>Customer / </span>
                        <span className='icon2'>Update User</span>
                    </div>
                    <div className='wrapper'>
                        {/* <h4 className='text-center mb-4'>Add Project</h4> */}
                        <form onSubmit={handleSubmit}>
                            <div className='row'>
                                <div className="col-md-4 gh_formWrapper">
                                    <label htmlFor="customer_name" className="form-label gh_label">Customer Name</label>
                                    <input type="text"
                                        className="form-control gh_form"
                                        name='customer_name'
                                        id="customer_name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.customer_name}
                                    />
                                    {errors.customer_name && touched.customer_name ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.customer_name}</span>) : null}
                                </div>
                                <div className="col-md-4 gh_formWrapper">
                                    <label htmlFor="mobile_num" className="form-label gh_label">Mobile Number</label>
                                    <input type="number"
                                        className="form-control gh_form"
                                        name='mobile_num'
                                        id="mobile_num"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.mobile_num}
                                    />
                                    {errors.mobile_num && touched.mobile_num ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.mobile_num}</span>) : null}
                                </div>
                                <div className="col-md-4 gh_formWrapper">
                                    <label htmlFor="email" className="form-label gh_label">Email</label>
                                    <input type="email"
                                        className="form-control gh_form"
                                        name='email'
                                        id="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                    />
                                    {/* {errors.email && touched.email ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.email}</span>) : null} */}
                                </div>
                                <div className="col-md-4 gh_formWrapper">
                                    <label htmlFor="state" className="form-label gh_label">State</label>
                                    <select className="form-select gh_form"
                                        name='state'
                                        id="state"
                                        onChange={(e) => { handleChange(e); selectStateID(e.target.value) }}
                                        onBlur={handleBlur}
                                        value={values.state}
                                    >
                                        <option value=''>Select</option>
                                        {stateList && stateList.map((statevalue, index2) =>
                                            <option key={index2} value={statevalue.id}>{statevalue.state}</option>
                                        )}
                                    </select>
                                    {errors.state && touched.state ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.state}</span>) : null}
                                </div>
                                <div className="col-md-4 gh_formWrapper">
                                    <label htmlFor="city" className="form-label gh_label">City</label>
                                    <select className="form-select gh_form"
                                        name='city'
                                        id="city"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.city}
                                    >
                                        <option value=''>Select</option>
                                        {cityList && cityList.map((cityvalue, index3) =>
                                            <option key={index3} value={cityvalue.id}>{cityvalue.city}</option>
                                        )}
                                    </select>
                                    {errors.city && touched.city ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.city}</span>) : null}
                                </div>
                                <div className='text-end mt-2'>
                                        <button type='submit' className='btn btn-success gh_btn'>Update</button>
                                    </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}
