import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var userUpdateRes;
export const userUpdateTodo = createAsyncThunk('userUpdate', async (data) => {

    // console.log("userUpdate--", JSON.parse(localStorage.getItem))
console.log(data)
    const res = await axios({
        method: "post",
        // mode: 'cors',
        url: process.env.REACT_APP_API_URL + 'user_updated',
        data: {
            "address": data && data.values && data.values.address,
            "city" : data && data.values && data.values.city,
            "designation_id" : data && data.values && data.values.designation_id,
            "datebirth": data && data.values && data.values.dob,
            "email": data && data.values && data.values.email,
            "employer_id": data && data.values && data.values.employer_id,
            "gender" : data && data.values && data.values.gender,
            "mobile": data && data.values && data.values.mobile_num,
            "state": data && data.values && data.values.state,
            "username": data && data.values && data.values.user_name,
            "id": data.userId.toString(),
            "userid": JSON.parse(localStorage.getItem('user_data')).userId,
            // "status": data && data.values && data.values.status
        },
        crossDomain: true,
        headers: { "Content-Type": "application/json" }
    })
    return userUpdateRes = res.data
})
