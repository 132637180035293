import React, { useEffect, useState } from 'react'
import { approveBookingRes, approveBookingTodo } from '../../redux/slices/booking/approveBooking'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import DataTbl from '../../table/table'
import { BallTriangle } from 'react-loader-spinner'
import { commissionListTodo } from '../../redux/slices/commisionListSlice'
import { commissionSetRes, commissionSetTodo } from '../../redux/slices/booking/commissionSet'
import { ToastContainer, toast } from 'react-toastify';
import { bookingRejectRes, bookingRejectTodo } from '../../redux/slices/booking/bookingReject';
import BookingInstallment from './bookingInstallmentform'
import Swal from 'sweetalert2'
import { FiMonitor } from "react-icons/fi";
import BookingEarning from './bookingEarning'
import { bookingInstallmentListTodo } from '../../redux/slices/booking/bookingInstallmentList'
import UpdateDealAmount from './UpdateDealAmount'




export default function BookingApproveList(props) {
    const { bstatus, setbstatus } = props;
    const { bAmt, setBAmt } = props;
    const { pstatus, setpstatus } = props;
    // console.log("props", props)
    const [loading, setLoading] = useState(true)
    const [bookingData, setbookingData] = useState('')
    const [bookingEarningData, setbookingEarningData] = useState('')
    const [aprovePopup, setaprovePopup] = useState(false)
    const [dealAmt, setDealAmt] = useState(false)
    const [earning, setEarning] = useState(false)
    const [bool, setBool] = useState(false)
    const state = useLocation()
    // console.log(bAmt)
    const fk_project_id = state && state.state && state.state.fk_project_id
    const property_id = state && state.state && state.state.fk_property_id
    var bookingId = state && state.state && state.state.id
    const bookingAmnt = state && state.state && state.state.booking_amount
    // console.log("state", bookingAmnt)
    const dispatch = useDispatch()
    const approveBooking = useSelector(state => state && state.approveBooking && state.approveBooking.data && state.approveBooking.data.data && state.approveBooking.data.data.rows)
    const totalInstallAmt = useSelector(state => state && state.bookingInstallmentList && state.bookingInstallmentList.data && state.bookingInstallmentList.data.data && state.bookingInstallmentList.data.data.total)
    const bookingStatus = approveBooking && approveBooking[0] && approveBooking[0].booking_master && approveBooking[0].booking_master.booking_status
    const propertyStatus = approveBooking && approveBooking[0] && approveBooking[0].property_detail && approveBooking[0].property_detail.property_status
    const dealAmount = approveBooking && approveBooking[0] && approveBooking[0].booking_master && approveBooking[0].booking_master.booking_amount

    bookingStatus && props && props.setbstatus(bookingStatus);
    propertyStatus && props && props.setpstatus(propertyStatus)
    dealAmount && props && props.setBAmt(dealAmount)
    // bookingStatus && props && props.setbstatus(bookingStatus);
    console.log("totalInstallAmt", totalInstallAmt)


    const handlebookingApprove = (data) => {
        setaprovePopup(true);
        setbookingData(data)

    }

    const handleUpdateDealAmt = (data) => {
        setDealAmt(true);
        setbookingData(data)
    }

    const handleBookingEarning = (data) => {
        setEarning(true);
        // setbookingData(data)
        setbookingEarningData(data)
        // console.log("earning", data)

    }


    const booking_reject = (data) => {
        // dispatch(bookingRejectTodo(id)).then(() => booking_reject_msg())
        // console.log("BookingId", data)
        Swal.fire({
            title: 'Booking Reject',
            text: "Are you sure want to Reject Booking ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#105684',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'CANCEL',
            confirmButtonText: 'REJECT !'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(bookingRejectTodo(data)).then(() => booking_reject_msg())
                Swal.fire(
                    'Booking',
                    'Booking Reject Successfull.',
                    'success'
                )
            }

        })
    }


    const booking_reject_msg = () => {
        if (bookingRejectRes && bookingRejectRes.success) {
            // toast.success(bookingRejectRes && bookingRejectRes.message, { position: "bottom-right" })
            setBool(!bool)
        }
        else {
            toast.error(bookingRejectRes && bookingRejectRes.message, { position: "bottom-right" })
        }
    }

    const exportcolumn = ['User.username', 'commission_percent', "commission_amt", "added_date", "status"]

    const [columnDefs1, setColumnDefs1] = useState([
        {
            headerName: 'S.No.',
            valueGetter: 'node.rowIndex + 1',
            wrapText: true,
            maxWidth: 80,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true
        },
        {
            headerName: "User",
            field: "User.username",
            cellRenderer: params => params && params.data && params.data.User && params.data.User.username,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: "230px"
        },
        // {
        //     headerName: "Property Number",
        //     field: "property_detail.property_number",
        //     cellRenderer: params => params && params.data && params.data.property_detail && params.data.property_detail.property_number,
        //     wrapText: true,
        //     autoHeight: true,
        //     filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '140px'
        // },


        {
            headerName: "Commission",
            cellRenderer: params => <span>
                {params && params.data && params.data.commission_percent}%
            </span>,
            field: "commission_percent",
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '130px'
        },
        {
            headerName: "Commission Amount",
            cellRenderer: params => params && params.data && params.data.commission_amt,
            field: "commission_amt",
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '130px'
        },

        {
            headerName: "Added Date",
            field: "added_date",
            cellRenderer: params => new Date(params && params.data && params.data.added_date).toLocaleDateString('en-GB'),
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '130px'
        },

        {
            headerName: "Status",
            field: "status",
            cellRenderer: params => <span style={{ textTransform: "capitalize" }} className={params.data.status === 'pending' ? 'status_hold' : params.data.status === 'rejected' ? 'status_rejected' : params.data.status === 'approved' ? 'status_booked' : ''} >
                {params.data.status}</span>,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '120px'
        },
        {
            headerName: "Action",
            wrapText: true,
            cellRenderer: params => <div className='viewEdit_btn'>
                <span ><FiMonitor onClick={() => handleBookingEarning(params.data)} style={{ color: '#105684', cursor: "pointer" }} /></span>

            </div>,
            autoHeight: true,
            width: '100px',
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },

    ])

    useEffect(() => {
        dispatch(approveBookingTodo(bookingId)).then(() => { approveBooking_fun() })
        dispatch(bookingInstallmentListTodo(bookingId))
    }, [bool])


    const approveBooking_fun = () => {
        if (approveBookingRes && approveBookingRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    return (
        <>
                {loading ?

                    <div className='d-flex justify-content-center mt-5'>
                        <BallTriangle
                            height={100}
                            width={100}
                            radius={5}
                            color="#105684"
                            ariaLabel="ball-triangle-loading"
                            wrapperclassName="justify-content-center"
                            wrapperStyle={{}}
                            visible={true}
                        />
                    </div>
                    :
                    approveBooking && approveBooking.length ?
                        <div className='wrapper p-3'>

                            <div className='row'>
                                <div className="col-md-3">
                                    {/* {bookingStatus === 'hold' ?  */}
                                    <button onClick={() => handleUpdateDealAmt(state.state)} style={{color:"white",fontSize:'14px'}} className='btn bg-warning'  > Update deal amount</button> 
                                    {/* : ""} */}

                                </div>
                                <div className="col-md-9">
                                    <div className="d-flex justify-content-end align-items-center">
                                        {/* <h4 className='mb-4'>Commission</h4> */}

                                        <div className='text-end'>
                                            {bookingStatus === 'hold' ? <button onClick={() => handlebookingApprove(state.state)} className='btn gh_btn ms-2' > Approve</button> :
                                                bAmt <=  totalInstallAmt ? "":   bookingStatus === 'booked' ? <button onClick={() => handlebookingApprove(state.state)} className='btn gh_btn ms-2' > Booking Installment</button> : ''}
                                            {bookingStatus === 'hold' ? <button onClick={() => booking_reject({ bookingId, property_id })} className='btn gh_btn reject_btn ms-2' >Reject</button> : ''}

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='mt-2'>
                                <DataTbl
                                    data={approveBooking ? approveBooking : ''}
                                    columnDefs={columnDefs1}
                                    exportcolumn={exportcolumn}
                                />
                            </div>
                        </div>
                        :
                        <div className='empty_data_msg text-center mt-5'>
                            <h4>Approve List Not Found</h4>
                        </div>
                }

            <BookingInstallment bstatus={bstatus} setbstatus={setbstatus} aprovePopup={aprovePopup} bool={bool} setBool={setBool} bookingData={bookingData} setaprovePopup={setaprovePopup} />
            <BookingEarning earning={earning} setEarning={setEarning} bookingEarningData={bookingEarningData} setbookingEarningData={setbookingEarningData} />
            <UpdateDealAmount bool={bool} setBool={setBool} bookingData={bookingData} bAmt={bAmt} setBAmt={setBAmt} dealAmt={dealAmt} setDealAmt={setDealAmt} />

            {/* <ToastContainer /> */}
        </>
    )
}
