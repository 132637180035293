import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var deleteProjectRes;
export const deleteProjectTodo = createAsyncThunk('deleteProject', async (id) => {
    console.log("data", id)
    try {
        const res = await axios({
            method: "Delete",
            url: process.env.REACT_APP_API_URL + 'project_delete',
            data: { 
                "projectid":id
            },
            headers: { "Content-Type": "application/json" }
        })
        return deleteProjectRes = res.data

    } catch (error) {
        return deleteProjectRes = error.response.data
    }
})