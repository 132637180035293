import { useState } from 'react'
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";

export default function Property() {

    return (
        <>
            <div className="container-flued">

                <div className='scnd_header'>
                    <span className='icon1'>Property / </span>
                    <span className='icon2'>Property</span>
                </div>

                <div className='container mt-3 mb-3'>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="project-img-section ">
                                <h6>Project Name</h6>
                                <div className="mt-2">
                                    <div className="d-flex justify-content-between">
                                        <p>Total</p>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="project-img-section ">
                                <h6>Project Status</h6>
                                <div className="mt-2">
                                    <div className="d-flex justify-content-between">
                                        <p>Total</p>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="project-img-section ">
                                <h6>Locality</h6>
                                <div className="mt-2">
                                    <div className="d-flex justify-content-between">
                                        <p>Total</p>
                                        <span>8</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='wrapper me-2'>
                    <form action="">
                        <table style={{ width: "100%" }}>
                            <tr>
                                <th>Plot No.</th>
                                <th>Size</th>
                                <th>Price</th>
                            </tr>

                            <tr>
                                <td>
                                    <input type="text" className='form-control gh_form' placeholder='Plot No.' />
                                </td>
                                <td>
                                    <input type="text" className='form-control gh_form' placeholder='Size' />
                                </td>
                                <td>
                                    <input type="text" className='form-control gh_form' placeholder='Price' />
                                </td>
                            </tr>
                        </table>
                        <div className='text-end mt-4'>
                            <button type='submit' className='btn gh_btn'>Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </>

        // <div>
        //     <hr />
        //     <Formik
        //         initialValues={{
        //             users: [
        //                 {
        //                     PlotNo: "",
        //                     Size: "",
        //                     Price: ""
        //                 }
        //             ],
        //         }}
        //         validationSchema={Yup.object({
        //             PlotNo: Yup.array().of(
        //                 Yup.object().shape({
        //                     name: Yup.string().required("Name required"),
        //                     email: Yup.string()
        //                         .required("email required")
        //                         .email("Enter valid email")
        //                 })
        //             ),
        //             Size: Yup.array().of(
        //                 Yup.object().shape({
        //                     name: Yup.string().required("Name required"),
        //                     email: Yup.string()
        //                         .required("email required")
        //                         .email("Enter valid email")
        //                 })
        //             ),
        //             Price: Yup.array().of(
        //                 Yup.object().shape({
        //                     name: Yup.string().required("Name required"),
        //                     email: Yup.string()
        //                         .required("email required")
        //                         .email("Enter valid email")
        //                 })
        //             )
        //         })}
        //         onSubmit={(values) => alert(JSON.stringify(values, null, 2))}
        //         render={({ values, touched }) => (
        //             <Form>
        //                 <h5>Form </h5>
        //                 <FieldArray
        //                     name="users"
        //                     render={(arrayHelpers) => {
        //                         const users = values.users;
        //                         return (
        //                             <div>
        //                                 {users && users.length > 0
        //                                     ? users.map((user, index) => (
        //                                         <div key={index}>
        //                                             <Field
        //                                                 placeholder="Plot No."
        //                                                 name={`PlotNo.${index}`}
        //                                             />
        //                                             <ErrorMessage name={`PlotNo.${index}`} />
        //                                             <br />

        //                                             <Field
        //                                                 placeholder="Size"
        //                                                 name={`Size.${index}`}
        //                                             />
        //                                             <ErrorMessage name={`users.${index}`} />

        //                                             <Field
        //                                                 placeholder="Price"
        //                                                 name={`Price.${index}`}
        //                                             />
        //                                             <ErrorMessage name={`Price.${index}`} />

        //                                             <br />
        //                                         </div>
        //                                     ))
        //                                     : null}
        //                                 <br />
        //                                 <br />
        //                                 <br />
        //                                 <div>
        //                                     <button type="submit">Form Submit</button>
        //                                 </div>
        //                             </div>
        //                         );
        //                     }}
        //                 />
        //                 <hr />
        //             </Form>
        //         )}
        //     />
        // </div>
    )
}


