import { useFormik } from 'formik';
import React from 'react'
import { updateReceiptDateRes, updateReceiptDateTodo } from '../../redux/slices/project/UpdateReceiptDate';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { updateReciptDateSchema } from '../../schemas';

export default function ReceiptDateUpdate(props) {
    const { recieptDate, setRecieptDate, installmentData } = props;
    const { bool, setBool } = props;
    const dispatch = useDispatch()
    // console.log(installmentData)

    const initialValues = {
        id: installmentData && installmentData.id,
        // recipet_amt:installmentData?.receipt_amt,
        transacion_id:installmentData?.transaction_id,
        payment_typ:installmentData?.type,
        recpt_date: installmentData && installmentData.receipt_date,
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: updateReciptDateSchema,
        enableReinitialize: true,
        onSubmit: (values, action) => {
            dispatch(updateReceiptDateTodo( values )).then(() => update_res())
        },
    })

    const update_res = () => {
        if (updateReceiptDateRes && updateReceiptDateRes.success) {
            toast.success(updateReceiptDateRes && updateReceiptDateRes.message, { position: "bottom-right" })
            setBool(!bool)
            setTimeout(() => {
                setRecieptDate(false)
            }, 1000);
        } else {
            toast.error(updateReceiptDateRes && updateReceiptDateRes.message, { position: "bottom-right" })
        }
    }
    return (
        <>
            <div className="container-fuild">
                <div className={recieptDate ? 'modal fade show d-block' : 'modal fade show d-none'} style={{ background: 'rgba(0,0,0,0.8)' }}>
                    <div class="modal-dialog  modal-dialog-centered modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel"></h5>
                                <h5 style={{ color: "#105684" }} className='text-center'>Update Installment</h5>
                                <button type="button" class="btn-close" onClick={() => { setRecieptDate(false); }}></button>
                            </div>
                            <div class="modal-body p-4">
                                <form onSubmit={handleSubmit}>
                                    <div className='row'>
                                    {/* <div className="col-md-6 gh_formWrapper">
                                            <label htmlFor="recipet_amt" className="form-label gh_label">Receipt Amount </label>
                                            <input type="text"
                                                className="form-control gh_form"
                                                name='recipet_amt'
                                                id="recipet_amt"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.recipet_amt}
                                            />
                                            {errors.recipet_amt && touched.recipet_amt ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.recipet_amt}</span>) : null}
                                        </div> */}
                                        <div className="col-md-6 gh_formWrapper">
                                            <label htmlFor="transacion_id" className="form-label gh_label">Transaction Id</label>
                                            <input type="text"
                                                className="form-control gh_form"
                                                name='transacion_id'
                                                id="transacion_id"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.transacion_id}
                                            />
                                            {/* {errors.transacion_id && touched.transacion_id ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.transacion_id}</span>) : null} */}
                                        </div>
                                        <div className="col-md-6 gh_formWrapper">
                                            <label htmlFor="recpt_date" className="form-label gh_label">Receipt Date </label>
                                            <input type="date"
                                                className="form-control gh_form"
                                                name='recpt_date'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.recpt_date}
                                            />
                                            {errors.recpt_date && touched.recpt_date ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.recpt_date}</span>) : null}
                                        </div>
                                        <div className="col-md-6 gh_formWrapper">
                                            <label htmlFor="payment_typ" className="form-label gh_label">Payment Type</label>
                                            <select className="form-select gh_form" name='payment_typ' id='payment_typ'
                                                onChange={handleChange}
                                                value={values.payment_typ}
                                                onBlur={handleBlur}
                                            >
                                                <option value=''>Select</option>
                                                <option value="cash">Cash</option>
                                                <option value="cheque">Cheque</option>
                                                <option value="online">Online</option>
                                                <option value="banktransfer">Bank Transfer</option>

                                            </select>

                                            {errors.payment_typ && touched.payment_typ ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.payment_typ}</span>) : null}
                                        </div>
                                    </div>
                                    <div className='mt-3 d-flex justify-content-end'>
                                        <button type='submit' className='btn btn-success gh_btn'>Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
