import React from 'react';
import { useState } from 'react'
import DataTbl from '../../table/table'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { userListRes, userListTodo } from '../../redux/slices/userListSlice';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import { BiSolidEdit } from "react-icons/bi";
import { RiLockPasswordLine } from "react-icons/ri";
import { FiMonitor, FiTrash2 } from "react-icons/fi";
import { BallTriangle } from 'react-loader-spinner'
import UpdateUserPsw from './updateUserPsw';
import { ToastContainer, toast } from 'react-toastify';
import { FaRegEye, FaRegEyeSlash, FaTrashAlt } from 'react-icons/fa';
import UserActivePopup from './UserActivePopup';
import { userActiveRes, userActiveTodo } from '../../redux/slices/user/UserActive';
import UserInactivePopup from './UserInactivePopup';
import UserDeletePopup from './UserDeletePopup';

const UserList = () => {

    // const initialValues = {
    //     new_psw: "",
    //     confirm_psw: ""
    // };
    const [bool, setBool] = useState(false)
    const [loading, setLoading] = useState(true)
    const [userId, setUserId] = useState('')
    const [pswPopup, setPswPopup] = useState(false)
    const [userActive, setUserActive] = useState(false)
    const [userInactive, setUserInactive] = useState(false)
    const [userDelete, setUserDelete] = useState(false)
    const [userData, setUserData] = useState('')

    const handlePswUpdate = (id) => {
        setPswPopup(true);
        setUserId(id)
    }

    const handleUserActive = (data) => {
        setUserActive(true)
        setUserData(data)
    }
    const handleUserInactive = (data) => {
        setUserInactive(true)
        setUserData(data)
    }
    const handleUserDelete = (data) => {
        setUserDelete(true)
        setUserData(data)
    }

    const userActiveFun = (userId) => {
        console.log(userId)
        Swal.fire({
            title: "User Active",
            text: "Are You Sure Want To Active This User",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Active'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(userActiveTodo({ employId: '7', userId: userId })).then(() => userActive_res())
            }
        })
    }

    const userActive_res = () => {
        if (userActiveRes && userActiveRes.success) {
            toast.success(userActiveRes && userActiveRes.message, { position: 'bottom-right' })
            setBool(!bool)
        } else {
            toast.error(userActiveRes && userActiveRes.message, { position: 'bottom-right' })
        }
    }

    const dispatch = useDispatch();
    const data = useSelector(state => state && state.userList && state.userList.data && state.userList.data.data)
    // console.log(data)

    const navigate = useNavigate();

    const upDateList = (value) => {
        navigate("../UpdateUser", { state: value })
    }

    const userDetailsLink = (value) => {
        navigate('../userDetails', { state: value })
    }

    // const [id, setId] = useState();

    const exportcolumn = ['username', 'email', "mobile", "master_designation.designation", "address"]

    const [columnDefs1, setColumnDefs1] = useState([
        {
            headerName: 'S.No.',
            valueGetter: 'node.rowIndex + 1',
            wrapText: true,
            maxWidth: 90,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '200px'
        },
        {
            headerName: "Name",
            field: "username",
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '300px'
        },
        // {
        //     headerName: "Email",
        //     field: "email",
        //     wrapText: true,
        //     width: '250px',
        //     autoHeight: true,
        //     filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        // },
        {
            headerName: "Mobile",
            field: "mobile",
            wrapText: true,
            width: '130px',
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },
        {
            headerName: "Designation",
            field: "master_designation.designation",
            width: '130px',
            wrapText: true,
            cellRenderer: params => <span style={{ textTransform: 'capitalize' }}>{params && params.data && params.data.master_designation && params.data.master_designation.designation}</span>,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },
        {
            headerName: "Address",
            field: "address",
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },
        {
            headerName: "Status",
            field: "status",
            cellRenderer: params => <span style={{ textTransform: "capitalize" }} className={(params && params.data && params.data.status) === 'active' ? 'status_booked' : 'status_rejected'} >
                {params && params.data && params.data.status}</span>,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '130px'
        },
        {
            headerName: "Action",
            wrapText: true,
            cellRenderer: params => <div className=''>
                {params.data.status === 'active' ? <span onClick={()=>{handleUserInactive(params.data)}}><FaRegEye size={20} style={{ color: "grey" }} /></span> : <span onClick={() => { params.data.designation_id === 1 ? userActiveFun(params.data.id) : handleUserActive(params.data) }}><FaRegEyeSlash size={20} style={{ color: "grey" }} /></span>}
                <span className='ms-2' onClick={() => { handlePswUpdate(params && params.data.id) }}>  <RiLockPasswordLine size={20} /> </span>
                <span className='ms-2' onClick={() => { upDateList(params && params.data) }}><BiSolidEdit style={{ color: 'green' }} size={20} /></span>
                <span className='ms-2' onClick={() => userDetailsLink(params.data)}><FiMonitor style={{ color: "#105684" }} size={20} /></span>
                <span className='ms-2' onClick={()=>{handleUserDelete(params.data)}} ><FiTrash2 style={{ color: "red" }} size={20} /></span>

            </div>,
            autoHeight: true,
            width: '200px',
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },

    ])

    useEffect(() => {
        dispatch(userListTodo()).then(() => userList_fun())
    }, [bool])

    const userList_fun = () => {
        if (userListRes && userListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    return (
        <>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='scnd_header'>
                            <span className='icon1'>User / </span>
                            <span className='icon2'> User List</span>
                        </div>
                        {
                            loading ?
                                <div className='d-flex justify-content-center mt-5'>
                                    <BallTriangle
                                        height={100}
                                        width={100}
                                        radius={5}
                                        color="#105684"
                                        ariaLabel="ball-triangle-loading"
                                        wrapperclassName="justify-content-center"
                                        wrapperStyle={{}}
                                        visible={true}
                                    />
                                </div>
                                :
                                data && data.length
                                    ?
                                    <div className='wrapper'>
                                        <DataTbl
                                            data={data ? data : ''}
                                            columnDefs={columnDefs1}
                                            exportcolumn={exportcolumn}
                                        />
                                    </div>
                                    :
                                    <div className='empty_data_msg text-center mt-5'>
                                        <h4>User List Not Found</h4>
                                    </div>
                        }
                    </div>
                </div>
            </div>
            <UpdateUserPsw pswPopup={pswPopup} id={userId} setPswPopup={setPswPopup} />
            <UserActivePopup userActive={userActive} setUserActive={setUserActive} userData={userData} bool={bool} setBool={setBool} />
            <UserInactivePopup userInactive={userInactive} setUserInactive={setUserInactive} userData={userData} bool={bool} setBool={setBool}/>
            <UserDeletePopup userDelete={userDelete} setUserDelete={setUserDelete} userData={userData} bool={bool} setBool={setBool}/>
           
            {/* <ToastContainer /> */}
        </>
    );
}

export default UserList;
