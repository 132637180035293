import React from 'react'
import { useLocation } from 'react-router'
import '../../gaganHoms_css/viewDetails.css'
import ProjectPropertyList from './projectPropertyList';
// import { BallTriangle } from 'react-loader-spinner'

export default function ProjectDetails() {

    // const [loading, setLoading] = useState(true)

    const state = useLocation();
    const projectData = state && state.state
    // console.log("projectData", projectData)
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <div className='userDtl_box scroller'>
                            <div className='view_dtails'>
                                <div className='project_img_section'>
                                    <img src={projectData ? ((projectData && projectData.project_image_url) + (projectData && projectData.project_image)) : "./DP_img.jpg"} className='project_img' alt="" />
                                </div>

                                <h4>{projectData.project_name}</h4>
                                <div className='date mt-1'>Added date: {new Date(projectData.added_date).toLocaleDateString('en-GB')}</div>
                            </div>

                            <hr />

                            <div className='gaganHomes_dataList'>
                            <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Total plots</p>
                                    <span className='body'>{projectData && projectData.total_plots}</span>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Project Status</p>
                                    <span style={{ textTransform: "capitalize" }} className={(projectData && projectData.project_status) === 'available' ? 'badge bg-primary' : (projectData && projectData.project_status) === 'hold' ? 'badge bg-warning' :(projectData && projectData.project_status) === 'booked' ? 'badge bg-success ' :''}>{projectData && projectData.project_status}</span>

                                </div>
                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Project Size</p>
                                    <span className='body'>{projectData && projectData.project_size}</span>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Company Name</p>
                                    <span className='body'>{projectData && projectData.company_name}</span>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Rera number</p>
                                    <span className='body'>{projectData && projectData.rera_number}</span>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Latitude</p>
                                    <span className='body'>{projectData && projectData.latitude}</span>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Longitude</p>
                                    <span className='body'>{projectData && projectData.longitude}</span>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Street</p>
                                    <span className='body'>{projectData && projectData.street}</span>
                                </div>


                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Locality</p>
                                    <span className='body'>{projectData && projectData.locality}</span>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Sub locality</p>
                                    <span className='body'>{projectData && projectData.sub_locality}</span>
                                </div>

                               
                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Status</p>
                                    <span className='body'>{projectData && projectData.status}</span>
                                </div>

                                <div className="mt-2">
                                    <div className='head'>Project Description</div>
                                    <div className='body'>{projectData && projectData.project_description}</div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-9 mt-3 mt-md-0">
                        <div>
                        
                            <ProjectPropertyList />
                         
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}