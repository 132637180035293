import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var deletePropertyRes;
export const deletePropertyTodo = createAsyncThunk('deleteProperty', async (id) => {
    // console.log("id", id)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'property_delete',
            data: {
                "property_id": id
            },
            headers: { "Content-Type": "application/json" }
        })
        return deletePropertyRes = res.data

    } catch (error) {
        return deletePropertyRes = error.response.data
    }
})