import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var userDesignationListRes;
export const userDesignationListTodo = createAsyncThunk('userDesignationList', async (id) => {
    // console.log("data", id)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'designationUID',
            data: {
                "ordered": id
            },
            headers: { "Content-Type": "application/json" }
        })
        return userDesignationListRes = res.data

    } catch (error) {
        return userDesignationListRes = error.response.data
    }
});

const userDesignationListSlice = createSlice({
    name: 'userDesignationList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(userDesignationListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(userDesignationListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default userDesignationListSlice.reducer; 