import React from 'react'
import { useLocation } from 'react-router'
import '../../gaganHoms_css/viewDetails.css'

export default function CommissionDetails() {

    const state = useLocation();
    const commissionData = state && state.state;
    // console.log("commissionData", commissionData)
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-3">
                        <div className='userDtl_box scroller'>
                            <div className='view_dtails'>
                                <div className='text-center'>{commissionData && commissionData.company_name}</div>
                                <img src={commissionData ? (commissionData.project_image_url + commissionData.project_image) : ("./DP_img.jpg")} alt="" />

                                <h4>{commissionData.project_name}</h4>

                                {/* <div>
                                    <a href="">xyz@gmail.com</a>
                                </div> */}

                                <div className='date'>Add date: {commissionData && commissionData.added_date}</div>
                                {/* <div className='date'>Date of birth: 09/04/2005</div> */}
                                {/* <div className='country'>Country: India</div> */}

                                {/* <div className='country'>Address : Vijay nagar</div> */}
                            </div>

                            <hr />

                            <div className='gaganHomes_dataList'>
                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Designation</p>
                                    <span className='body'>{commissionData && commissionData.designation_detail && commissionData.designation_detail.designation}</span>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Status</p>
                                    <span className='body'>{commissionData && commissionData.status}</span>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>From Target</p>
                                    <span className='body'>{commissionData && commissionData.target && commissionData.target.from_target}</span>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>To target</p>
                                    <span className='body'>{commissionData && commissionData.target && commissionData.target.to_target}</span>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-9">
                        <div>
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Booking</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Total Earning</button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                                    <div className='userDtl_box mt-3'></div>
                                </div>
                                <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
                                    <div className='userDtl_box mt-3'></div>
                                </div>
                                <div className="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex="0">
                                    <div className='userDtl_box mt-3'></div>
                                </div>
                                <div className="tab-pane fade" id="disabled-tab-pane" role="tabpanel" aria-labelledby="disabled-tab" tabIndex="0">
                                    <div className='userDtl_box mt-3'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}