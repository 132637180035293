import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var CustomerUpdateRes;
export const CustomerUpdateTodo = createAsyncThunk('CustomerUpdate', async (data) => {

    console.log("CustomerUpdate--", data)

    const res = await axios({
        method: "post",
        // mode: 'cors',
        url: process.env.REACT_APP_API_URL + 'updated_customer',
        data: {
            "city" : data && data.city,
            "email": data && data.email,
            "mobile": data && data.mobile_num,
            "state": data && data.state,
            "name": data && data.customer_name,
            "id": data?.customer_id,
            "modified_by": JSON.parse(localStorage.getItem('user_data')).userId,
            // "status": data && data.status
        },
        crossDomain: true,
        headers: { "Content-Type": "application/json" }
    })
    return CustomerUpdateRes = res.data
})
