import React from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { designationListTodo } from '../../redux/slices/designationListSlice';
import { stateListTodo } from '../../redux/slices/StateListSlice';
import { cityListTodo } from '../../redux/slices/cityListSlice';
import { userListTodo } from '../../redux/slices/userListSlice';
import { useState } from 'react';
import { addUserRes, addUserTodo } from '../../redux/slices/AddUserSlice';
import { ToastContainer, toast } from 'react-toastify';
import { addUserValidation } from '../../schemas';
import { employeDesignationTodo } from '../../redux/slices/employeDesignation';
import { useNavigate } from 'react-router';



const AddUser = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const designationList = useSelector(state => state && state.designationList && state.designationList.data && state.designationList.data.data && state.designationList.data.data.rows)
    const stateList = useSelector(state => state && state.stateList && state.stateList.data && state.stateList.data.data)
    const cityList = useSelector(state => state && state.cityList && state.cityList.data && state.cityList.data.data)
    const employDesignationList = useSelector(state => state && state.employDesignationList && state.employDesignationList.data && state.employDesignationList.data.data)
    // console.log(employDesignationList)

    const selectStateID = (id) => {
        dispatch(cityListTodo(id)).then()
    }
    // console.log("selectState", selectState)

    const initialValues = {
        user_name: '',
        mobile_num: '',
        email: '',
        password: '',
        designation_id: '',
        employer_id: '7',
        state: '',
        city: '',
        address: '',
        gender: '',
    };

    const userAddfnc = (action) => {
        if (addUserRes && addUserRes.success) {
            action.resetForm();
            toast.success(addUserRes && addUserRes.message, { position: "bottom-right" });
            setTimeout(() => {
                navigate('../userlist')
            }, 1500);
        } else {
            toast.error(addUserRes && addUserRes.message, { position: "bottom-right" });
        }
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: addUserValidation,
        onSubmit: (values, action) => {
            // console.log(values)
            dispatch(addUserTodo(values)).then(() => userAddfnc(action))
            
        },
    })
    const selectDesignationId = (id) => {
        dispatch(employeDesignationTodo(id)).then()
        
        if (id == "1") {
            document.getElementById("employer_id").setAttribute('disabled', 'disabled');
            document.getElementById("employer_id").style.display = 'none';
            document.getElementById("employer_id_label").style.display = 'none';
            values.employer_id = "1";
        }else{
            document.getElementById("employer_id").removeAttribute('disabled');
            document.getElementById("employer_id").style.display = 'block';
            document.getElementById("employer_id_label").style.display = 'block';
            values.employer_id = "";
        }

    }

    useEffect(() => {
        dispatch(designationListTodo()).then()
        dispatch(stateListTodo()).then()
        // dispatch(userListTodo()).then()
    }, [])


    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='scnd_header'>
                            <span className='icon1'>User / </span>
                            <span className='icon2'>Add User</span>
                        </div>
                        <div className='wrapper'>
                            {/* <h4 className='text-center mb-4'>Add Project</h4> */}
                            <form onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className="col-md-4 gh_formWrapper">
                                        <label htmlFor="user_name" className="form-label gh_label">User Name</label>
                                        <input type="text"
                                            className="form-control gh_form"
                                            name='user_name'
                                            id="user_name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.user_name}
                                        />
                                        {errors.user_name && touched.user_name ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.user_name}</span>) : null}
                                    </div>
                                    <div className="col-md-4 gh_formWrapper">
                                        <label htmlFor="mobile_num" className="form-label gh_label">Mobile Number</label>
                                        <input type="number"
                                            className="form-control gh_form"
                                            name='mobile_num'
                                            id="mobile_num"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.mobile_num}
                                        />
                                        {errors.mobile_num && touched.mobile_num ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.mobile_num}</span>) : null}
                                    </div>
                                    {/* <div className="col-md-4 gh_formWrapper">
                                        <label htmlFor="dob" className="form-label gh_label">Date of Birth</label>
                                        <input type="date"
                                            className="form-control gh_form"
                                            name='dob'
                                            id="dob"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.dob}
                                        />
                                        {errors.dob && touched.dob ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.dob}</span>) : null}
                                    </div> */}
                                    <div className="col-md-4 gh_formWrapper">
                                        <label htmlFor="email" className="form-label gh_label">Email</label>
                                        <input type="email"
                                            className="form-control gh_form"
                                            name='email'
                                            id="email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                        />
                                        {/* {errors.email && touched.email ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.email}</span>) : null} */}
                                    </div>
                                    <div className="col-md-4 gh_formWrapper">
                                        <label htmlFor="password" className="form-label gh_label">Password</label>
                                        <input type="password"
                                            className="form-control gh_form"
                                            name='password'
                                            id="password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password}
                                        />
                                        {/* {errors.password && touched.password ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.password}</span>) : null} */}
                                    </div>
                                    <div className="col-md-4 gh_formWrapper">
                                        <label htmlFor="designation_id" className="form-label gh_label">Designation </label>
                                        <select className="form-select gh_form"
                                            name='designation_id'
                                            id="designation_id"
                                            onChange={(e) => { handleChange(e); selectDesignationId(e.target.value) }}
                                            onBlur={handleBlur}
                                            value={values.designation_id}
                                        >
                                            <option value=''>Select</option>
                                            {designationList && designationList.map((value, index) =>
                                                <option key={index} value={value.id}>{value.designation}</option>
                                            )}
                                        </select>
                                        {errors.designation_id && touched.designation_id ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.designation_id}</span>) : null}
                                    </div>
                                    <div className="col-md-4 gh_formWrapper">
                                        <label htmlFor="employer_id" className="form-label gh_label" id="employer_id_label">Employer </label>
                                        <select className="form-select gh_form"
                                            name='employer_id'
                                            id="employer_id"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.employer_id}
                                        >
                                            <option value=''>Select</option>
                                            {employDesignationList && employDesignationList.map((uservalue, index4) =>
                                                <option key={index4} value={uservalue.id}>{uservalue.username} ( {uservalue && uservalue.master_designation && uservalue.master_designation.designation} )</option>
                                            )}
                                        </select>
                                        {errors.employer_id && touched.employer_id ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.employer_id}</span>) : null}
                                    </div>
                                    <div className="col-md-4 gh_formWrapper">
                                        <label htmlFor="state" className="form-label gh_label">State</label>
                                        <select className="form-select gh_form"
                                            name='state'
                                            id="state"
                                            onChange={(e) => { handleChange(e); selectStateID(e.target.value) }}
                                            onBlur={handleBlur}
                                            value={values.state}
                                        >
                                            <option value=''>Select</option>
                                            {stateList && stateList.map((statevalue, index2) =>
                                                <option key={index2} value={statevalue.id}>{statevalue.state}</option>
                                            )}
                                        </select>
                                        {/* {errors.state && touched.state ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.state}</span>) : null} */}
                                    </div>
                                    <div className="col-md-4 gh_formWrapper">
                                        <label htmlFor="city" className="form-label gh_label">City</label>
                                        <select className="form-select gh_form"
                                            name='city'
                                            id="city"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.city}
                                        >
                                            <option value=''>Select</option>
                                            {cityList && cityList.map((cityvalue, index3) =>
                                                <option key={index3} value={cityvalue.id}>{cityvalue.city}</option>
                                            )}
                                        </select>
                                        {/* {errors.city && touched.city ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.city}</span>) : null} */}
                                    </div>
                                    <div className="col-md-4 gh_formWrapper">
                                        <label htmlFor="address" className="form-label gh_label">Address</label>
                                        <textarea className="form-control gh_form"
                                            rows={1}
                                            name='address'
                                            id="address"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.address}
                                        >

                                        </textarea>
                                        {/* {errors.address && touched.address ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.address}</span>) : null} */}
                                    </div>
                                    <div className="col-md-4 gh_formWrapper">
                                        <label htmlFor="gender" className="form-label gh_label">Gender</label>
                                        <select className="form-select gh_form"
                                            name='gender'
                                            id="gender"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.gender}
                                        >
                                            <option value=''>Select</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </select>
                                        {errors.gender && touched.gender ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.gender}</span>) : null}
                                    </div>
                                    <div className='text-end mt-2'>
                                        <button type='submit' className='btn btn-success gh_btn'>Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default AddUser;
