import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import Header from './gaganHomes_view/header'

const useAuth = () => {
    var token = JSON.parse(localStorage.getItem('user_data'));
    let user = token;
    // console.log('>---->---->', user)
    if (user) {
        try {
            if (user) {
                return user;
            }
        } catch (error) {
            return false
        }
    } else {
        return false
    }
}


const ProtectedRoutes = () => {
    const auth = useAuth()
    if (!auth) {
        return <Navigate to="/Login" />
    } else {
        return (
            <div>
                <Header />
                <div id='content' className='margin-all-page' style={{ marginTop: '85px' }}>
                    <Outlet />
                </div>
            </div>
        )
    }
}


export default ProtectedRoutes;
