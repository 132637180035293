import { configureStore } from "@reduxjs/toolkit";
import dashboard from "./slices/dashboard";
import user_login from "./slices/user_login";
import projectList from "./slices/projectList";
import designationListSlice from "./slices/designationListSlice";
import StateListSlice, { stateListRes } from "./slices/StateListSlice";
import cityListSlice from "./slices/cityListSlice";
import userListSlice from "./slices/userListSlice";
import PropertyListSlice from "./slices/PropertyListSlice";
import commisionListSlice from "./slices/commisionListSlice";
import targetListSlice from "./slices/targetListSlice";
import bookingListSlice from "./slices/bookingListSlice";
import userDesignationList from "./slices/userDesignationList";
import employeDesignation from "./slices/employeDesignation";
import teamList from "./slices/user/teamList";
import addPropertyList from "./slices/AddForm/addPropertyList";
import userBookingList from "./slices/user/userBookingList";
import approveBooking from "./slices/booking/approveBooking";
import state_list from "./slices/AddForm/state_list";
import AddProjectCommission from "./slices/AddProjectCommission";
import commissionSet from "./slices/booking/commissionSet";
import bookingInstallmentList from "./slices/booking/bookingInstallmentList";
import bookingEarningList from "./slices/booking/bookingEarningList";
import userEarningList from "./slices/user/userEarningList";
import customerList from "./slices/customerList";
import bookingFilter from "./slices/booking/bookingFilter";
import customerBookingList from "./slices/customerBookingList";
import adminCommission from "./slices/booking/adminCommission";
import UserEmployeList from "./slices/user/UserEmployeList";
import adminTeamList from "./slices/user/adminTeamList";

const Store = configureStore({
    reducer: {
        dashboard: dashboard,
        userLogin: user_login,
        projectList : projectList,
        designationList: designationListSlice,
        stateList: StateListSlice,
        cityList: cityListSlice,
        userList: userListSlice,
        propertyList:  PropertyListSlice,
        commissionList  : commisionListSlice,
        targetList: targetListSlice,
        bookingList: bookingListSlice,
        userDesignationList: userDesignationList,
        employDesignationList : employeDesignation,
        teamList: teamList,
        addPropertyList: addPropertyList,
        userBookingList: userBookingList,
        approveBooking: approveBooking,
        stateList: state_list,
        projectCommission: AddProjectCommission,
        bookingInstallmentList : bookingInstallmentList,
        bookingEarning : bookingEarningList,
        userEarningList: userEarningList,
        customerList : customerList,
        customerBookingList : customerBookingList,
        bookingFilter: bookingFilter,
        adminCommission: adminCommission,
        userEmployeList:UserEmployeList,
        adminTeamList:adminTeamList
    }
})

export default Store;