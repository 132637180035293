import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var propertyBookRes;
export const propertyBookTodo = createAsyncThunk('propertyBook', async (data) => {
    console.log("data--", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'admin_property_booked',
            data: {
                "user_id":JSON.parse(localStorage.getItem('user_data')).userId,
                "project_id": data.project,
                "property_id": data.plot
            },
            headers: { "Content-Type": "application/json" }
        })
        return propertyBookRes = res.data

    } catch (error) {
        return propertyBookRes = error.response.data
    }
})