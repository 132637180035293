import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var bookingInstallmentRes;
export const bookingInstallmentTodo = createAsyncThunk('bookingInstallment', async (data) => {
    console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_instalment',
            data: {
                "project_id": data && data.projectId,
                "property_id":data && data.propertyId ,
                "booking_id": data && data.bookingId,
                "receipt_amt": data && data.values && data.values.recipet_amt,
                "receipt_date": data && data.values && data.values.date,
                "transaction_id": data && data.values && data.values.transacion_id,
                "type": data && data.values && data.values.payment_typ,
                "status": data && data.values && data.values.payment_status
            },
            headers: { "Content-Type": "application/json" }
        })
        return bookingInstallmentRes = res.data

    } catch (error) {
        return bookingInstallmentRes = error.response.data
    }
});

