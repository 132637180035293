import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addPropertyRes;
export const addPropertyTodo = createAsyncThunk('addProperty', async (data) => {
    console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_property',
            data: {

                "project_id":data.projectId,
                "property_number":data && data.values && data.values.property_num ,
                "property_width":data && data.values && data.values.property_width,
                "property_length":data && data.values && data.values.property_length,
                "cost":data && data.values && data.values.property_cost,
                "userid": data.added_by
                
                },
            headers: { "Content-Type": "application/json" }
        })
        return addPropertyRes = res.data

    } catch (error) {
        return addPropertyRes = error.response.data
    }
})