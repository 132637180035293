import React, { useState, useEffect } from 'react';
import DataTbl from '../../table/table';
import { useDispatch, useSelector } from 'react-redux';
import { bookingEarningRes, bookingEarningTodo } from '../../redux/slices/booking/bookingEarningList';
import { BallTriangle } from 'react-loader-spinner'

const BookingEarning = (props) => {

    const [loading, setLoading] = useState(false)
    const { earning, setEarning } = props;

    var userId = props && props.bookingEarningData && props.bookingEarningData.User && props.bookingEarningData.User.id;
    const userName = props && props.bookingEarningData && props.bookingEarningData.User && props.bookingEarningData.User.username;
    var bookingId = props && props.bookingEarningData && props.bookingEarningData.booking_master && props.bookingEarningData.booking_master.id;
    // console.log("test", props && props.bookingEarningData) 
    const dispatch = useDispatch()
    const data = useSelector(state => state && state.bookingEarning && state.bookingEarning.data && state.bookingEarning.data.data && state.bookingEarning.data.data.rows)
    // console.log("totalearning", data)

    const [columnDefs1, setColumnDefs1] = useState([
        {
            headerName: 'S.No.',
            valueGetter: 'node.rowIndex + 1',
            wrapText: true,
            maxWidth: 90,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },
        {
            headerName: "Commission Amount",
            field: "commission_amt",
            // cellRenderer: params => params && params.data && params.data.booking_receipt && params.data.booking_receipt.receipt_amt,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },
        {
            headerName: "Commission ",
            field: "commission_percent",
            // cellRenderer: params => params && params.data && params.data.booking_receipt && params.data.booking_receipt.transaction_id ,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },


        {
            headerName: "Reciept Date",
            cellRenderer: params => new Date(params && params.data && params.data.added_date).toLocaleDateString('en-GB'),
            field: "added_date",
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },


    ])

    useEffect(() => {
        setLoading(true)
        dispatch(bookingEarningTodo({userId,bookingId})).then(() => bookingEarning())
    }, [userId])

    const bookingEarning = () => {
        if (bookingEarningRes && bookingEarningRes.success) {
            setLoading(false)

        } else {
            setLoading(false)

        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className={earning ? 'modal fade show d-block' : 'modal fade show d-none'} style={{ background: 'rgba(0,0,0,0.8)' }}>
                            <div class="modal-dialog   modal-lg">
                                <div class="modal-content">
                                    <div class="ps-4 pt-4 pb-0 pe-4 d-flex justify-content-between">
                                        <h5 class="modal-title" id="exampleModalLabel">Booking Earning</h5>
                                        <h5 style={{ color: "#105684" }} className='text-center'>User Name : {userName}</h5>
                                        <button type="button" class="btn-close" onClick={() => { setEarning(false); }}></button>
                                    </div>
                                    <div class="modal-body p-4">
                                        {loading ?

                                            <div className='d-flex justify-content-center mt-5'>
                                                <BallTriangle
                                                    height={100}
                                                    width={100}
                                                    radius={5}
                                                    color="#105684"
                                                    ariaLabel="ball-triangle-loading"
                                                    wrapperclassName="justify-content-center"
                                                    wrapperStyle={{}}
                                                    visible={true}
                                                />
                                            </div>
                                            :
                                            data && data.length ?
                                                <DataTbl
                                                    data={data ? data : ''}
                                                    columnDefs={columnDefs1}

                                                />
                                                :
                                                <div className='empty_data_msg text-center '>
                                                    <h4>Booking Earning List Not Found</h4>
                                                </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BookingEarning;
