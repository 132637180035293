import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var UserEmployeListRes;
export const UserEmployeListTodo = createAsyncThunk('UserEmployeList', async (id) => {

    // console.log("UserEmployeList", id)

    const res = await axios({
        method: "post",
        url: process.env.REACT_APP_API_URL + 'employe_down_list',
        data: {
            "user_id": id
        },
        crossDomain: true,
        headers: { "Content-Type": "application/json" }
    })
    return UserEmployeListRes = res.data
})


const UserEmployeListSlice = createSlice({
    name: 'UserEmployeList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(UserEmployeListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('UserEmployeListTodo', state.data.response)
        });
        builder.addCase(UserEmployeListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default UserEmployeListSlice.reducer