import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var projectCommissionRes;
export const projectCommissionTodo = createAsyncThunk('projectCommission', async (id) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'commission_list',
            data:{
                "project_id":id
            },
            headers: { "Content-Type": "application/json" }
        })
        return projectCommissionRes = res.data

    } catch (error) {
        return projectCommissionRes = error.response.data
    }
});

const projectCommissionSlice = createSlice({
    name: 'projectCommission',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(projectCommissionTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(projectCommissionTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default projectCommissionSlice.reducer; 