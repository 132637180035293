import React, { useEffect,useState } from 'react'
import { useFormik } from 'formik';
import { addProjectValidation } from '../../schemas';
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router';
import { cityListTodo } from '../../redux/slices/cityListSlice';
import Select from 'react-select'
import { stateListTodo } from '../../redux/slices/StateListSlice';
import { updateProjectRes, updateProjectTodo } from '../../redux/slices/project/updateProject';
import { BallTriangle } from 'react-loader-spinner'

export default function UpdateProject() {
    const [loading, setLoading] = useState(false)
    const state = useLocation()
    var updateProject = state && state.state
    // console.log("updateProject--", updateProject)

    const initialValues = {
        project_name: updateProject && updateProject.project_name,
        project_description: updateProject && updateProject.project_description,
        project_size: updateProject && updateProject.project_size,
        company_name: updateProject && updateProject.company_name,
        number: updateProject && updateProject.rera_number,
        project_status: updateProject && updateProject.project_status,
        total_plots: updateProject && updateProject.total_plots,
        locality: updateProject && updateProject.locality,
        sub_locality: updateProject && updateProject.sub_locality,
        street: updateProject && updateProject.street,
        latitude: updateProject && updateProject.latitude,
        longitude: updateProject && updateProject.longitude,
        project_image: updateProject && updateProject.project_image,
        // property_image: '',
        state_list: updateProject && updateProject.state_master && updateProject.state_master.id,
        city_list: updateProject && updateProject.city_master.id,
    };

    const dispatch = useDispatch()
    const stateList = useSelector(state => state.stateList && state.stateList.data && state.stateList.data.data)
    const cityList = useSelector(state => state.cityList && state.cityList.data && state.cityList.data.data)
    const editProject = useSelector(state => state.updateProject && state.updateProject.data && state.updateProject.data.data)
    // console.log("cityList--", cityList)
    const navigate = useNavigate()

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: addProjectValidation,

        onSubmit: (values, action) => {
            // dispatch(addProjectTodo( values ).then(()=> addProject_fun() ))
            setLoading(true)
            dispatch(updateProjectTodo(bodyFormData)).then(() => updateProject_fun())

            // console.log("values--", values)

        },
    })

    var added_by = JSON.parse(localStorage.getItem('user_data')).userId

    let bodyFormData = new FormData();

    bodyFormData.append("id", updateProject && updateProject.id)
    bodyFormData.append("project_name", values.project_name)
    bodyFormData.append("project_description", values.project_description)
    bodyFormData.append("project_size", values.project_size)
    bodyFormData.append("company_name", values.company_name)
    bodyFormData.append("rera_number", values.number)
    bodyFormData.append("project_status", values.project_status)
    // bodyFormData.append("total_plots", values.total_plots)
    bodyFormData.append("locality", values.locality)
    bodyFormData.append("sub_locality", values.sub_locality)
    bodyFormData.append("street", values.street)
    bodyFormData.append("latitude", values.latitude)
    bodyFormData.append("longitude", values.longitude)
    bodyFormData.append("modified_by", added_by)
    bodyFormData.append("path", "category")
    bodyFormData.append("project_image", values.project_image)
    bodyFormData.append("state", values.state_list)
    bodyFormData.append("city", values.city_list)


    const updateProject_fun = () => {
        if (updateProjectRes && updateProjectRes.success) {
            setLoading(false)
            toast.success('Project Update Successfully!!.', { position: "bottom-right" })
            setTimeout(() => {
                navigate('../ProjectList')
            }, 1500);
        } else {
            setLoading(false)
            toast.error('Something Went Wrong!!..', { position: "bottom-right" })
        }
    }


    const cityListFun = (id) => {
        // var stateData = document.getElementById('state_list').value
        console.log("stateData", id)
        dispatch(cityListTodo(id))
    }

    useEffect(() => {
        dispatch(stateListTodo({ "cid": "101" }))
        var stateData = document.getElementById('state_list').value
        console.log("valuestate", stateData)
        dispatch(cityListTodo(stateData))
    }, [])

    const options = []

    const cityAllData = cityList && cityList.map((value) => options.push(
        { value: value && value.id, label: value && value.city },
        // console.log("cityAllData--", value.id)
    )
    )

    return (
        <>

            <div className="container-flued">

                <div className='scnd_header'>
                    <span className='icon1'>Project / </span>
                    <span className='icon2'>Edit Project</span>
                </div>

                <div className='wrapper'>
                    {/* <h4 className='text-center mb-4'>Add Project</h4> */}
                     {loading ?
                        <div>
                            <div className='d-flex justify-content-center mt-5'>
                                <BallTriangle
                                    height={100}
                                    width={100}
                                    radius={5}
                                    color="#105684"
                                    ariaLabel="ball-triangle-loading"
                                    wrapperclassName="justify-content-center"
                                    wrapperStyle={{}}
                                    visible={true}
                                />
                            </div>
                        </div>
                        :
                    <form onSubmit={handleSubmit}>
                        <div className='row'>

                            <div className="col-md-4 gh_formWrapper">
                                <label htmlFor="project_name" className="form-label gh_label">Project Name</label>
                                <input type="text" className="form-control gh_form" name='project_name' id="project_name" onChange={handleChange} onBlur={handleBlur} value={values.project_name} />

                                {errors.project_name && touched.project_name ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.project_name}</span>) : null}
                            </div>

                            <div className="col-md-4 gh_formWrapper">
                                <label htmlFor="company_name" className="form-label gh_label">Company Name</label>
                                <input type="text" className="form-control gh_form" name='company_name' id="company_name" onChange={handleChange} onBlur={handleBlur} value={values.company_name} />

                                {/* {errors.company_name && touched.company_name ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.company_name}</span>) : null} */}
                            </div>

                            <div className="col-md-4 gh_formWrapper">
                                <label htmlFor="number" className="form-label gh_label">Rera Number</label>
                                <input type="text" className="form-control gh_form" name='number' id="number" onChange={handleChange} onBlur={handleBlur} value={values.number} />

                                {/* {errors.number && touched.number ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.number}</span>) : null} */}
                            </div>

                            <div className="col-md-4 gh_formWrapper">
                                <label htmlFor="project_status" className="form-label gh_label">Project Status</label>
                                <select className="form-select gh_form" name='project_status' id='project_status' onChange={handleChange} onBlur={handleBlur}>
                                    <option value={updateProject && updateProject.project_status}>{updateProject && updateProject.project_status}</option>
                                    <option value="available">Available</option>
                                    <option value="booked">Booked</option>
                                    <option value="hold">Hold</option>
                                    <option value="registry">Registry</option>
                                    <option value="underconstruction">Underconstruction</option>
                                </select>

                                {errors.project_status && touched.project_status ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.project_status}</span>) : null}
                            </div>

                            <div className="col-md-4 gh_formWrapper">
                                <label htmlFor="total_plots" className="form-label gh_label">Total Plots</label>
                                <input type="number" className="form-control gh_form" name='total_plots' id="total_plots"
                                    onChange={handleChange} onBlur={handleBlur} value={values.total_plots} readOnly/>

                                {/* {errors.total_plots && touched.total_plots ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.total_plots}</span>) : null} */}
                            </div>

                            <div className="col-md-4 gh_formWrapper">
                                <label htmlFor="project_size" className="form-label gh_label">Project Size</label>
                                <input type="text" className="form-control gh_form" name='project_size' id="project_size"
                                    onChange={handleChange} onBlur={handleBlur} value={values.project_size} />

                                {/* {errors.project_size && touched.project_size ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.project_size}</span>) : null} */}
                            </div>

                            <div className="col-md-12 gh_formWrapper">
                                <label htmlFor="project_description" className="form-label gh_label">Project Description</label>
                                <textarea className="form-control gh_form" name='project_description' id="project_description" rows="3" onChange={handleChange} onBlur={handleBlur} value={values.project_description}></textarea>

                                {/* {errors.project_description && touched.project_description ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.project_description}</span>) : null} */}
                            </div>

                            <div className="col-md-4 gh_formWrapper">
                                <label htmlFor="state_list" className="form-label gh_label">State List</label>
                                <select className="form-select gh_form" name='state_list' id='state_list' onChange={(e) => {
                                    cityListFun(e.target.value)
                                    handleChange(e)
                                }
                                }
                                    onBlur={handleBlur}>
                                    <option value={updateProject && updateProject.state_master && updateProject.state_master.id}>{updateProject && updateProject.state_master && updateProject.state_master.state}</option>
                                    {stateList && stateList.map((value, index) =>
                                        <option key={index} value={value && value.id}>{value && value.state}</option>
                                    )}
                                </select>

                                {errors.state_list && touched.state_list ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.state_list}</span>) : null}
                            </div>

                            <div className="col-md-4 gh_formWrapper">
                                <label htmlFor="city_list" className="form-label gh_label">City List</label>

                                <Select
                                    name='city_list'
                                    options={options}
                                    onChange={(e) =>
                                        setFieldValue('city_list', e.value)
                                    }
                                    defaultValue={  options.find((option) => option.value === updateProject.city_master.id)}
                                    onBlur={handleBlur} className="gh_form"
                                />

                                {/* <select className="form-select gh_form" name='city_list' id='city_list' onChange={handleChange} onBlur={handleBlur}>
                                    <option value=''>Select</option>
                                    {cityList && cityList.map((value, index) =>
                                        <option key={index} value={value.city}>{value.city}</option>
                                    )}
                                </select> */}

                                {errors.city_list && touched.city_list ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.city_list}</span>) : null}
                            </div>

                            <div className="col-md-4 gh_formWrapper">
                                <label htmlFor="locality" className="form-label gh_label">Locality</label>
                                <input type="text" className="form-control gh_form" name='locality' id="locality"
                                    onChange={handleChange} onBlur={handleBlur} value={values.locality} />

                                {/* {errors.locality && touched.locality ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.locality}</span>) : null} */}
                            </div>

                            <div className="col-md-4 gh_formWrapper">
                                <label htmlFor="sub_locality" className="form-label gh_label">Sub Locality</label>
                                <input type="text" className="form-control gh_form" name='sub_locality' id="sub_locality"
                                    onChange={handleChange} onBlur={handleBlur} value={values.sub_locality} />

                                {/* {errors.sub_locality && touched.sub_locality ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.sub_locality}</span>) : null} */}
                            </div>

                            <div className="col-md-4 gh_formWrapper">
                                <label htmlFor="street" className="form-label gh_label">Street</label>
                                <input type="text" className="form-control gh_form" name='street' id="street" onChange={handleChange} onBlur={handleBlur} value={values.street} />

                                {/* {errors.street && touched.street ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.street}</span>) : null} */}
                            </div>

                            <div className="col-md-4 gh_formWrapper">
                                <label htmlFor="latitude" className="form-label gh_label">Latitude</label>
                                <input type="text" className="form-control gh_form" name='latitude' id="latitude" onChange={handleChange} onBlur={handleBlur} value={values.latitude} />

                                {/* {errors.latitude && touched.latitude ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.latitude}</span>) : null} */}
                            </div>

                            <div className="col-md-4 gh_formWrapper">
                                <label htmlFor="longitude" className="form-label gh_label">Longitude</label>
                                <input type="text" className="form-control gh_form" name='longitude' id="longitude" onChange={handleChange} onBlur={handleBlur} value={values.longitude} />

                                {/* {errors.longitude && touched.longitude ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.longitude}</span>) : null} */}
                            </div>

                            <div className="col-md-4 gh_formWrapper">
                                <label htmlFor="project_image" className="form-label gh_label">Project Image</label>
                                <input type="file" className="form-control gh_form" name='project_image' id="project_image" onChange={(e) => {setFieldValue("project_image", e.currentTarget.files[0]); console.log("image", e)}}  onBlur={handleBlur} />

                                {errors.project_image && touched.project_image ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.project_image}</span>) : null}
                            </div>

                            {/* <div className="col-md-4 gh_formWrapper">
                                <label htmlFor="property_image" className="form-label gh_label">Property Image</label>
                                <input type="file" className="form-control gh_form" name='property_image' id="property_image" onChange={(e) => setFieldValue("property_image", e.currentTarget.files[0])} onBlur={handleBlur} />

                                {errors.property_image && touched.property_image ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.property_image}</span>) : null}
                            </div> */}

                            <div className='text-end mt-2'>
                                <button type='submit' className='btn btn-success gh_btn'>Submit</button>
                            </div>
                        </div>
                    </form>}
                </div>
            </div>
            <ToastContainer />
        </>
    )
}
