import React from 'react'
import { useLocation } from 'react-router'
import '../../gaganHoms_css/viewDetails.css'
import UserTree from './UserTree';
import UserBookingList from './userBookingList';
import UserEarningList from './userEarningList';

export default function UserDetails() {

    const state = useLocation();
    var userData = state && state.state
    console.log("userData", state.state)
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3">
                        <div className='userDtl_box scroller'>
                            <div className='view_dtails'>
                                {/* <div className='text-center'>{userData && userData.company_name}</div> */}
                                <img className='userImg' src="./profile.png" alt="" />

                                {/* <img className='userImg' src={userData.profilePictureUrl} alt="img" /> */}

                                <h4>{userData && userData.username}</h4>
                                <p className='mb-0' style={{color:"grey",fontWeight:'600'}}>({userData?.master_designation?.designation})</p>

                                <div>{userData && userData.email}</div>

                                {/* <div className='date'>DOB: {new Date(userData && userData.datebirth).toLocaleDateString('en-GB')}</div> */}

                                <div className='date'>Added date: {new Date(userData && userData.added_date).toLocaleDateString('en-GB')}</div>

                                <hr />

                                <div className='gaganHomes_dataList'>
                                    <div className="d-flex justify-content-between align-items-center mt-1">
                                        <p className='head'>Gender</p>
                                        <span className='body'>{userData && userData.gender}</span>
                                    </div>

                                    <div className="text-start mt-1">
                                        <p className='head'>Address</p>
                                        <span className='body'>{userData && userData.address}</span>
                                    </div>

                                    <div className="d-flex justify-content-between align-items-center mt-1">
                                        <p className='head'>City</p>
                                        <span className='body'>{userData && userData.city_master && userData.city_master.city}</span>
                                    </div>

                                    <div className="d-flex justify-content-between align-items-center mt-1">
                                        <p className='head'>State</p>
                                        <span className='body'>{userData && userData.state_master && userData.state_master.state}</span>
                                    </div>
                                </div>
                            </div>

                            <div className='gaganHomes_dataList'>
                                {/* <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Size</p>
                                    <span className='body'>{userData && userData.project_size}</span>
                                </div> */}

                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Designation</p>
                                    <span className='body'>{userData && userData.master_designation && userData.master_designation.designation}</span>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Mobile No.</p>
                                    <span className='body'>{userData && userData.mobile}</span>
                                </div>

                                <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Status</p>
                                    <span className='body'>{userData && userData.status}</span>
                                </div>

                                {/* <div className="d-flex justify-content-between align-items-center mt-1">
                                    <p className='head'>Subscription Type</p>
                                    <span className='body'>{userData && userData && userData.subscription_type}</span>
                                </div> */}
                            </div>

                        </div>
                    </div>

                    <div className="col-md-9 mt-3 mt-md-0">
                        <div className='userDtl_box'>
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">User Booking</button>
                                </li>
                                {/* <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">User Earning</button>
                                </li> */}
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Team Tree</button>
                                </li>
                                {/* <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="disabled-tab" data-bs-toggle="tab" data-bs-target="#disabled-tab-pane" type="button" role="tab" aria-controls="disabled-tab-pane" aria-selected="false" disabled>Disabled</button>
                                </li> */}
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                                    <div className='mt-3'>
                                        {/* <TreeData/> */}
                                        <UserBookingList />
                                    </div>
                                </div>
                                {/* <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
                                    <UserEarningList />
                                </div> */}
                                <div className="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex="0">
                                    <UserTree />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}