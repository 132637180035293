import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var bookingRejectRes;
export const bookingRejectTodo = createAsyncThunk('bookingReject', async (id) => {
    console.log("Api_Data",id)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'booking_rejected',
            data: {
                "booked_id":id,

            },
            headers: { "Content-Type": "application/json" }
        })
        return bookingRejectRes = res.data

    } catch (error) {
        return bookingRejectRes = error.response.data
    }
});

