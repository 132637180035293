import React from 'react';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { targetListTodo } from '../../redux/slices/targetListSlice';
import { designationListTodo } from '../../redux/slices/designationListSlice';
import { userDesignationListTodo } from '../../redux/slices/userDesignationList';
import { addCommissionRes, addCommissionTodo } from '../../redux/slices/AddCommission';
import { ToastContainer, toast } from 'react-toastify';
import { addCommissionValidation } from '../../schemas';

const AddCommission = () => {

    const dispatch = useDispatch();
    const targetList = useSelector(state => state && state.targetList && state.targetList.data && state.targetList.data.data && state.targetList.data.data.rows)
    const designationList = useSelector(state => state && state.designationList && state.designationList.data && state.designationList.data.data && state.designationList.data.data.rows)
    const userDesignationList = useSelector(state => state && state.userDesignationList && state.userDesignationList.data && state.userDesignationList.data.data)
    // console.log("userDesignationList", userDesignationList)

    const selectdesignationID = (id) => {
        dispatch(userDesignationListTodo(id)).then()
    }

    const initialValues = {
        target: '',
        designation: '',
        user_designation: '',
        commission: ""

    };


    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: addCommissionValidation,
        onSubmit: (values, action) => {
            // console.log(values)
            dispatch(addCommissionTodo(values)).then(() => addCommission())
            action.resetForm();
        },
    })

    const addCommission = () => {
        if (addCommissionRes && addCommissionRes.success) {
            toast.success('Data Submitted Successfully!!.', { position: "bottom-right" });
        } else {
            toast.error('Something Went Wrong!!..', { position: "bottom-right" });

        }
    }

    useEffect(() => {
        dispatch(targetListTodo()).then()
        dispatch(designationListTodo()).then()
        // dispatch(userDesignationListTodo()).then()
    }, [])


    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='scnd_header'>
                            <span className='icon1'>Property / </span>
                            <span className='icon2'>Add Commission</span>
                        </div>
                        <div className='wrapper'>
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-3 gh_formWrapper">
                                        <label htmlFor="target" className="form-label gh_label">Target</label>
                                        <select className="form-select gh_form"
                                            name='target'
                                            id="target"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.target}
                                        >
                                            <option value=''>Select</option>
                                            {targetList && targetList.map((value, index) => 
                                                        <option key={index} value={value.id}>{value.from_target} To {value.to_target}</option>
                                                  )}
                                        </select>

                                        {errors.target && touched.target ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.target}</span>) : null}
                                    </div>
                                    <div className="col-md-3 gh_formWrapper">
                                        <label htmlFor="designation" className="form-label gh_label">Designation</label>
                                        <select className="form-select gh_form"
                                            name='designation'
                                            id="designation"
                                            // onChange={handleChange}
                                            onChange={(e) => { handleChange(e); selectdesignationID(e.target.value) }}
                                            onBlur={handleBlur}
                                            value={values.designation}
                                        >
                                            <option value=''>Select</option>
                                            {designationList && designationList.map((value, index) =>
                                                        <option key={index} value={value.id}>{value.designation}</option>
                                                 )}
                                        </select>

                                        {errors.designation && touched.designation ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.designation}</span>) : null}
                                    </div>
                                    <div className="col-md-3 gh_formWrapper">
                                        <label htmlFor="user_designation" className="form-label gh_label"> User Designation</label>
                                        <select className="form-select gh_form"
                                            name='user_designation'
                                            id="user_designation"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.user_designation}
                                        >
                                            <option value=''>Select</option>
                                            {userDesignationList && userDesignationList.map((value, index) => 
                                                        <option key={index} value={value.id}>{value.designation}</option>
                                                    )}
                                        </select>

                                        {errors.user_designation && touched.user_designation ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.user_designation}</span>) : null}
                                    </div>
                                    <div className="col-md-3 gh_formWrapper">
                                        <label htmlFor="commission" className="form-label gh_label">Commission</label>
                                        <input type="number"
                                            className="form-control gh_form"
                                            name='commission'
                                            id="commission"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.commission}
                                        />

                                        {errors.commission && touched.commission ? (<span className='error_msg' style={{ fontSize: "15px" }} >{errors.commission}</span>) : null}
                                    </div>
                                    <div className='text-end mt-2'>
                                        <button type='submit' className='btn btn-success gh_btn'>Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default AddCommission;
