import React, { useEffect, useState } from 'react'
import DataTbl from '../../table/table'
import { useDispatch, useSelector } from 'react-redux';
import { adminCommissionRes, adminCommissionTodo } from '../../redux/slices/booking/adminCommission';
import { Link, useNavigate } from 'react-router-dom';
import { FiMonitor } from "react-icons/fi";
import { BallTriangle } from 'react-loader-spinner'


export default function MyCommission() {

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false)
    const data = useSelector(state => state && state.adminCommission && state.adminCommission.data && state.adminCommission.data.data && state.adminCommission.data.data && state.adminCommission.data.data.commissionlist);

    const tototalCommission = useSelector(state => state && state.adminCommission && state.adminCommission.data && state.adminCommission.data.data && state.adminCommission.data.data.total && state.adminCommission.data.data.total)

    const navigate = useNavigate();

    const userDetailsLink = (value) => {
        navigate('../BookingDetails', { state: value })
    }

    const exportcolumn = ['project_master.project_name', 'property_detail.property_number', "customer.name", "booking_approvals[0].commission_amt",'booking_amount',  "user.username","property_detail.cost"]

    const [columnDefs1, setColumnDefs1] = useState([
        {
            headerName: 'S.No.',
            valueGetter: 'node.rowIndex + 1',
            wrapText: true,
            maxWidth: 90,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },
        {
            headerName: "Project Name",
            field: "project_master.project_name",
            wrapText: true,
            cellRenderer: params => <span style={{ textTransform: 'capitalize' }}>{params && params.data && params.data.project_master && params.data.project_master.project_name}</span>,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '200px'
        },
        {
            headerName: "Plot No.",
            field: "property_detail.property_number",
            wrapText: true,
            cellRenderer: params => <span style={{ textTransform: 'capitalize' }}>{params && params.data && params.data.property_detail && params.data.property_detail.property_number}</span>,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '150px'
        },

        {
            headerName: "Customer",
            field: "customer.name",
            cellRenderer: params => <span style={{ textTransform: 'capitalize' }}>{params && params.data && params.data.customer && params.data.customer.name}</span>,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '130px'
        },
        {
            headerName: "Commission Amt",
            field: "booking_approvals[0].commission_amt",
            cellRenderer: params => <span   >{params && params.data && params.data.booking_approvals && params.data.booking_approvals[0].commission_amt}</span>,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '130px'
        },
        {
            headerName: "Deal Amount",
            field: "booking_amount",

            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '130px'
        },
        {
            headerName: "Booked By",
            field: "user.username",
            cellRenderer: params => <span style={{ textTransform: 'capitalize' }}>{params && params.data && params.data.user && params.data.user.username}</span>,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '130px'
        },
        {
            headerName: "Plot Cost",
            field: "property_detail.cost",
            cellRenderer: params => <span style={{ textTransform: 'capitalize' }}>{params && params.data && params.data.property_detail && params.data.property_detail.cost}</span>,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '130px'
        },
        {
            headerName: "Action",
            wrapText: true,
            cellRenderer: params => <div className='viewEdit_btn'>
                {/* <span onClick={() => userDetailsLink(params && params.data)}><FiMonitor style={{ color: '#105684', cursor: "pointer" }} /></span> */}
                <span onClick={() => userDetailsLink(params && params.data)}><FiMonitor style={{ color: '#105684', cursor: "pointer" }} /></span>
            </div>,
            autoHeight: true,
            width: '130px',
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },

    ])

    const adminCommission = () => {
        if (adminCommissionRes && adminCommissionRes.success) {
            setLoading(false)
        } else {
            setLoading(false)

        }
    }

    useEffect(() => {
        setLoading(true)
        dispatch(adminCommissionTodo()).then(() => adminCommission())
    }, [])

    return (
        <div className="container-flued">

            <div className='scnd_header'>
                {/* <span className='icon1'>Booking</span> */}
                <span className='icon1'>Booking / </span>
                <span className='icon2'>My Commission</span>
            </div>
            {loading ?
                <div className='d-flex justify-content-center mt-5'>
                    <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#105684"
                        ariaLabel="ball-triangle-loading"
                        wrapperclassName="justify-content-center"
                        wrapperStyle={{}}
                        visible={true}
                    />
                </div>
                :
                <div>
                    <div className='wrapper mt-2'>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card totl_review_section text-center " >
                                    <div className='d-flex justify-content-between align-items-center '>
                                        <p>Total Booking</p>
                                        <span>{tototalCommission && tototalCommission.total_booking ? tototalCommission && tototalCommission.total_booking : '0'}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card totl_review_section text-center " >
                                    <div className='d-flex justify-content-between align-items-center '>
                                        <p>Receipt Amount</p>
                                        <span>{tototalCommission && tototalCommission.total_Recipt_amt ? tototalCommission && tototalCommission.total_Recipt_amt : '0'}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card totl_review_section text-center " >
                                    <div className='d-flex justify-content-between align-items-center '>
                                        <p>Commission Amount</p>
                                        <span>{tototalCommission && tototalCommission.total_commission_amt ? tototalCommission && tototalCommission.total_commission_amt : '0'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {data && data ?
                        <div className='wrapper mt-2'>
                            <DataTbl
                                columnDefs={columnDefs1}
                                data={data ? data : ""}
                            exportcolumn={exportcolumn}

                            />
                        </div> :
                        <div className='empty_data_msg text-center mt-5'>
                            <h4>Commission List Not Found</h4>
                        </div>}
                </div>}
        </div>
    )
}
