import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { logInSchema } from './LoginYup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { userLoginRes, userLoginTodo } from '../redux/slices/user_login';

export default function Login() {

  const navigate = useNavigate();

  const initialValues = {
    userName: '',
    password: '',
  };

  const dispatch = useDispatch()

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: logInSchema,
    onSubmit: (values, action, state) => {
      dispatch(userLoginTodo({
        "username": values.userName,
        "password": values.password
      })).then(() => login_user());

      // console.log(userLoginRes)

      action.resetForm();
    },
  })

  const login_user = () => {
    if (userLoginRes && userLoginRes.success) {
      localStorage.setItem('user_data', JSON.stringify(userLoginRes.data[0]))
      navigate('/Dashboard')
    } else {
      toast.error('Something Went Wrong!!..', { position: "bottom-right" })
    }
  }


  return (
    <>
      <div className="container-fluid g-0">
        <div className="row justify-content-center h-100 g-0">
          <div className="col-lg-7 col-md-6 d-none d-lg-block d-md-block my-auto">
            <div className='login-side'>
              <img src="../G-icon_2.png" alt="base-logo" className='img-fluid' />
            </div>
          </div>
          <div className="col-12 col-lg-5 col-md-6 my-auto">
            <div className="login-details">
              <div>
                <div className='logo-img-section '>
                  <img src="../gagan-logo.jpg" className='img-fluid logo-img' alt="logo" />
                </div>
                <div className='login-details1'>
                  <h2>LOG IN</h2>
                  {/* onSubmit={(e) => { e.preventDefault(); handleSubmit(); }} */}
                  {/* method="post" */}
                  <form onSubmit={handleSubmit}>
                    <div className="form-floating">
                      <input type="text" className="form-control login-input" placeholder="User Name" name='userName' value={values.userName}
                        onChange={handleChange}
                        onBlur={handleBlur} />
                      <label htmlFor="floatingInput" style={{ fontWeight: 'normal' }}>User Name</label>
                      {errors.userName && touched.userName ? (<span className='text-danger' >{errors.userName}</span>) : null}
                    </div>

                    <div className="form-floating">
                      <input type="password" className="form-control login-input" placeholder="Password" name='password' value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="on"
                        />

                      <label htmlFor="floatingInput" style={{ fontWeight: 'normal' }}>Password</label>
                      {errors.password && touched.password ? (<span className='text-danger' >{errors.password}</span>) : null}
                    </div>
                    <button type='submit' className='btn in-btn'>LOG IN</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}
