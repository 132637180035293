import React, { useEffect, useState } from 'react';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { designationListTodo } from '../../redux/slices/designationListSlice';
import { useLocation } from 'react-router';
import { projectCommissionTodo } from '../../redux/slices/AddProjectCommission';
import { addCommissionSchema } from './addCommissionYup';
import { updateProjectCommissionRes, updateProjectCommissionTodo } from '../../redux/slices/project/updateProjectCommission';
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2'
import { BallTriangle } from 'react-loader-spinner'

const AddProjectCommission = () => {
    const { state } = useLocation()
    const [loading, setLoading] = useState(false)
    var ProjectId = state && state.id
    var ttl_commission = state && state.t_commission
    const [reset, setReset] = useState(true)
    // console.log('ttl_commission',ttl_commission)

    console.log(state)

    const dispatch = useDispatch();
    const commissionData = useSelector(state => state && state.projectCommission && state.projectCommission.data && state.projectCommission.data.data)
    // console.log("commissionData",commissionData)

    const initialValues = {
        total_commission: reset  ? ttl_commission || '' : 1,
        project_id:  ProjectId || '' ,
        commission: [
            {
                designtaion: commissionData && commissionData[0] && commissionData[0].designation,
                option_idA: commissionData && commissionData[0] && commissionData[0].order && commissionData[0].order[0] && commissionData[0].order[0].id ? commissionData[0].order[0].id : 0,
                optionA: reset  ? commissionData && commissionData[0] && commissionData[0].order && commissionData[0].order[0] && commissionData[0].order[0].commission ? commissionData[0].order[0].commission : 0 : 0,
                option_idB: commissionData && commissionData[0] && commissionData[0].order && commissionData[0].order[1] && commissionData[0].order[1].id ? commissionData[0].order[1].id : 0,
                optionB: reset  ? commissionData && commissionData[0] && commissionData[0].order && commissionData[0].order[1] && commissionData[0].order[1].commission ? commissionData[0].order[1].commission : 0 : 0,
                option_idC: commissionData && commissionData[0] && commissionData[0].order && commissionData[0].order[2] && commissionData[0].order[2].id ? commissionData[0].order[2].id : 0,
                optionC: reset  ? commissionData && commissionData[0] && commissionData[0].order && commissionData[0].order[2] && commissionData[0].order[2].commission ? commissionData[0].order[2].commission : 0 : 0,
                option_idD: commissionData && commissionData[0] && commissionData[0].order && commissionData[0].order[3] && commissionData[0].order[3].id ? commissionData[0].order[3].id : 0,
                optionD: reset  ? commissionData && commissionData[0] && commissionData[0].order && commissionData[0].order[3] && commissionData[0].order[3].commission ? commissionData[0].order[3].commission : 0 : 0,
                option_idE: commissionData && commissionData[0] && commissionData[0].order && commissionData[0].order[4] && commissionData[0].order[4].id ? commissionData[0].order[4].id : 0,
                optionE: reset  ? commissionData && commissionData[0] && commissionData[0].order && commissionData[0].order[4] && commissionData[0].order[4].commission ? commissionData[0].order[4].commission : 0 : 0,
                option_idF: commissionData && commissionData[0] && commissionData[0].order && commissionData[0].order[5] && commissionData[0].order[5].id ? commissionData[0].order[5].id : 0,
                optionF: reset  ? commissionData && commissionData[0] && commissionData[0].order && commissionData[0].order[5] && commissionData[0].order[5].commission ? commissionData[0].order[5].commission : 0 : 0,
            },
            {
                designtaion: commissionData && commissionData[1] && commissionData[1].designation,
                option_idA: commissionData && commissionData[1] && commissionData[1].order && commissionData[1].order[0] && commissionData[1].order[0].id ? commissionData[1].order[0].id : 0,
                optionA: reset  ? commissionData && commissionData[1] && commissionData[1].order && commissionData[1].order[0] && commissionData[1].order[0].commission ? commissionData[1].order[0].commission : 0 : 0,
                option_idB: commissionData && commissionData[1] && commissionData[1].order && commissionData[1].order[1] && commissionData[1].order[1].id ? commissionData[1].order[1].id : 0,
                optionB: reset  ? commissionData && commissionData[1] && commissionData[1].order && commissionData[1].order[1] && commissionData[1].order[1].commission ? commissionData[1].order[1].commission : 0 : 0,
                option_idC: commissionData && commissionData[1] && commissionData[1].order && commissionData[1].order[2] && commissionData[1].order[2].id ? commissionData[1].order[2].id : 0,
                optionC: reset  ? commissionData && commissionData[1] && commissionData[1].order && commissionData[1].order[2] && commissionData[1].order[2].commission ? commissionData[1].order[2].commission : 0 : 0,
                option_idD: commissionData && commissionData[1] && commissionData[1].order && commissionData[1].order[3] && commissionData[1].order[3].id ? commissionData[1].order[3].id : 0,
                optionD: reset  ? commissionData && commissionData[1] && commissionData[1].order && commissionData[1].order[3] && commissionData[1].order[3].commission ? commissionData[1].order[3].commission : 0 : 0,
                option_idE: commissionData && commissionData[1] && commissionData[1].order && commissionData[1].order[4] && commissionData[1].order[4].id ? commissionData[1].order[4].id : 0,
                optionE: reset  ? commissionData && commissionData[1] && commissionData[1].order && commissionData[1].order[4] && commissionData[1].order[4].commission ? commissionData[1].order[4].commission : 0 : 0,
                option_idF: commissionData && commissionData[1] && commissionData[1].order && commissionData[1].order[5] && commissionData[1].order[5].id ? commissionData[1].order[5].id : 0,
                optionF: reset  ? commissionData && commissionData[1] && commissionData[1].order && commissionData[1].order[5] && commissionData[1].order[5].commission ? commissionData[1].order[5].commission : 0 : 0,
            },
            {
                designtaion: commissionData && commissionData[2] && commissionData[2].designation,
                option_idA: commissionData && commissionData[2] && commissionData[2].order && commissionData[2].order[0] && commissionData[2].order[0].id ? commissionData[2].order[0].id : 0,
                optionA: reset  ? commissionData && commissionData[2] && commissionData[2].order && commissionData[2].order[0] && commissionData[2].order[0].commission ? commissionData[2].order[0].commission : 0 : 0,
                option_idB: commissionData && commissionData[2] && commissionData[2].order && commissionData[2].order[1] && commissionData[2].order[1].id ? commissionData[2].order[1].id : 0,
                optionB: reset  ? commissionData && commissionData[2] && commissionData[2].order && commissionData[2].order[1] && commissionData[2].order[1].commission ? commissionData[2].order[1].commission : 0 : 0,
                option_idC: commissionData && commissionData[2] && commissionData[2].order && commissionData[2].order[2] && commissionData[2].order[2].id ? commissionData[2].order[2].id : 0,
                optionC: reset  ? commissionData && commissionData[2] && commissionData[2].order && commissionData[2].order[2] && commissionData[2].order[2].commission ? commissionData[2].order[2].commission : 0 : 0,
                option_idD: commissionData && commissionData[2] && commissionData[2].order && commissionData[2].order[3] && commissionData[2].order[3].id ? commissionData[2].order[3].id : 0,
                optionD: reset  ? commissionData && commissionData[2] && commissionData[2].order && commissionData[2].order[3] && commissionData[2].order[3].commission ? commissionData[2].order[3].commission : 0 : 0,
                option_idE: commissionData && commissionData[2] && commissionData[2].order && commissionData[2].order[4] && commissionData[2].order[4].id ? commissionData[2].order[4].id : 0,
                optionE: reset  ? commissionData && commissionData[2] && commissionData[2].order && commissionData[2].order[4] && commissionData[2].order[4].commission ? commissionData[2].order[4].commission : 0 : 0,
                option_idF: commissionData && commissionData[2] && commissionData[2].order && commissionData[2].order[5] && commissionData[2].order[5].id ? commissionData[2].order[5].id : 0,
                optionF: reset  ? commissionData && commissionData[2] && commissionData[2].order && commissionData[2].order[5] && commissionData[2].order[5].commission ? commissionData[2].order[5].commission : 0 : 0,
            },
            {
                designtaion: commissionData && commissionData[3] && commissionData[3].designation,
                option_idA: commissionData && commissionData[3] && commissionData[3].order && commissionData[3].order[0] && commissionData[3].order[0].id ? commissionData[3].order[0].id : 0,
                optionA: reset  ? commissionData && commissionData[3] && commissionData[3].order && commissionData[3].order[0] && commissionData[3].order[0].commission ? commissionData[3].order[0].commission : 0 : 0,
                option_idB: commissionData && commissionData[3] && commissionData[3].order && commissionData[3].order[1] && commissionData[3].order[1].id ? commissionData[3].order[1].id : 0,
                optionB: reset  ? commissionData && commissionData[3] && commissionData[3].order && commissionData[3].order[1] && commissionData[3].order[1].commission ? commissionData[3].order[1].commission : 0 : 0,
                option_idC: commissionData && commissionData[3] && commissionData[3].order && commissionData[3].order[2] && commissionData[3].order[2].id ? commissionData[3].order[2].id : 0,
                optionC: reset  ? commissionData && commissionData[3] && commissionData[3].order && commissionData[3].order[2] && commissionData[3].order[2].commission ? commissionData[3].order[2].commission : 0 : 0,
                option_idD: commissionData && commissionData[3] && commissionData[3].order && commissionData[3].order[3] && commissionData[3].order[3].id ? commissionData[3].order[3].id : 0,
                optionD: reset  ? commissionData && commissionData[3] && commissionData[3].order && commissionData[3].order[3] && commissionData[3].order[3].commission ? commissionData[3].order[3].commission : 0 : 0,
                option_idE: commissionData && commissionData[3] && commissionData[3].order && commissionData[3].order[4] && commissionData[3].order[4].id ? commissionData[3].order[4].id : 0,
                optionE: reset  ? commissionData && commissionData[3] && commissionData[3].order && commissionData[3].order[4] && commissionData[3].order[4].commission ? commissionData[3].order[4].commission : 0 : 0,
                option_idF: commissionData && commissionData[3] && commissionData[3].order && commissionData[3].order[5] && commissionData[3].order[5].id ? commissionData[3].order[5].id : 0,
                optionF: reset  ? commissionData && commissionData[3] && commissionData[3].order && commissionData[3].order[5] && commissionData[3].order[5].commission ? commissionData[3].order[5].commission : 0 : 0,
            },
            {
                designtaion: commissionData && commissionData[4] && commissionData[4].designation,
                option_idA: commissionData && commissionData[4] && commissionData[4].order && commissionData[4].order[0] && commissionData[4].order[0].id ? commissionData[4].order[0].id : 0,
                optionA: reset  ? commissionData && commissionData[4] && commissionData[4].order && commissionData[4].order[0] && commissionData[4].order[0].commission ? commissionData[4].order[0].commission : 0 : 0,
                option_idB: commissionData && commissionData[4] && commissionData[4].order && commissionData[4].order[1] && commissionData[4].order[1].id ? commissionData[4].order[1].id : 0,
                optionB: reset  ? commissionData && commissionData[4] && commissionData[4].order && commissionData[4].order[1] && commissionData[4].order[1].commission ? commissionData[4].order[1].commission : 0 : 0,
                option_idC: commissionData && commissionData[4] && commissionData[4].order && commissionData[4].order[2] && commissionData[4].order[2].id ? commissionData[4].order[2].id : 0,
                optionC: reset  ? commissionData && commissionData[4] && commissionData[4].order && commissionData[4].order[2] && commissionData[4].order[2].commission ? commissionData[4].order[2].commission : 0 : 0,
                option_idD: commissionData && commissionData[4] && commissionData[4].order && commissionData[4].order[3] && commissionData[4].order[3].id ? commissionData[4].order[3].id : 0,
                optionD: reset  ? commissionData && commissionData[4] && commissionData[4].order && commissionData[4].order[3] && commissionData[4].order[3].commission ? commissionData[4].order[3].commission : 0 : 0,
                option_idE: commissionData && commissionData[4] && commissionData[4].order && commissionData[4].order[4] && commissionData[4].order[4].id ? commissionData[4].order[4].id : 0,
                optionE: reset  ? commissionData && commissionData[4] && commissionData[4].order && commissionData[4].order[4] && commissionData[4].order[4].commission ? commissionData[4].order[4].commission : 0 : 0,
                option_idF: commissionData && commissionData[4] && commissionData[4].order && commissionData[4].order[5] && commissionData[4].order[5].id ? commissionData[4].order[5].id : 0,
                optionF: reset  ? commissionData && commissionData[4] && commissionData[4].order && commissionData[4].order[5] && commissionData[4].order[5].commission ? commissionData[4].order[5].commission : 0 : 0,
            },
            {
                designtaion: commissionData && commissionData[5] && commissionData[5].designation,
                option_idA: commissionData && commissionData[5] && commissionData[5].order && commissionData[5].order[0] && commissionData[5].order[0].id ? commissionData[5].order[0].id : 0,
                optionA: reset  ? commissionData && commissionData[5] && commissionData[5].order && commissionData[5].order[0] && commissionData[5].order[0].commission ? commissionData[5].order[0].commission : 0 : 0,
                option_idB: commissionData && commissionData[5] && commissionData[5].order && commissionData[5].order[1] && commissionData[5].order[1].id ? commissionData[5].order[1].id : 0,
                optionB: reset  ? commissionData && commissionData[5] && commissionData[5].order && commissionData[5].order[1] && commissionData[5].order[1].commission ? commissionData[5].order[1].commission : 0 : 0,
                option_idC: commissionData && commissionData[5] && commissionData[5].order && commissionData[5].order[2] && commissionData[5].order[2].id ? commissionData[5].order[2].id : 0,
                optionC: reset  ? commissionData && commissionData[5] && commissionData[5].order && commissionData[5].order[2] && commissionData[5].order[2].commission ? commissionData[5].order[2].commission : 0 : 0,
                option_idD: commissionData && commissionData[5] && commissionData[5].order && commissionData[5].order[3] && commissionData[5].order[3].id ? commissionData[5].order[3].id : 0,
                optionD: reset  ? commissionData && commissionData[5] && commissionData[5].order && commissionData[5].order[3] && commissionData[5].order[3].commission ? commissionData[5].order[3].commission : 0 : 0,
                option_idE: commissionData && commissionData[5] && commissionData[5].order && commissionData[5].order[4] && commissionData[5].order[4].id ? commissionData[5].order[4].id : 0,
                optionE: reset  ? commissionData && commissionData[5] && commissionData[5].order && commissionData[5].order[4] && commissionData[5].order[4].commission ? commissionData[5].order[4].commission : 0 : 0,
                option_idF: commissionData && commissionData[5] && commissionData[5].order && commissionData[5].order[5] && commissionData[5].order[5].id ? commissionData[5].order[5].id : 0,
                optionF: reset  ? commissionData && commissionData[5] && commissionData[5].order && commissionData[5].order[5] && commissionData[5].order[5].commission ? commissionData[5].order[5].commission : 0 : 0,
            }
        ],

    };




    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: addCommissionSchema,
        enableReinitialize: true,

        onSubmit: (values, action) => {
            console.log("new", values)
            setLoading(true)
            dispatch(updateProjectCommissionTodo(values)).then(() => updateProjectCommission())
            // action.resetForm();
        },
    })

    const updateProjectCommission = () => {
        if (updateProjectCommissionRes && updateProjectCommissionRes.success) {
            setLoading(false)
            toast.success(updateProjectCommissionRes && updateProjectCommissionRes.message, { position: "bottom-right" })
        } else {
            setLoading(false)
            toast.error(updateProjectCommissionRes && updateProjectCommissionRes.message, { position: "bottom-right" })
        }
    }

    const calculateTotalA = () => {
        const { commission } = formik.values;
        return parseFloat(commission[0].optionA) + parseFloat(commission[1].optionA) + parseFloat(commission[2].optionA) + parseFloat(commission[3].optionA) + parseFloat(commission[4].optionA) + parseFloat(commission[5].optionA);
    };
    const calculateTotalB = () => {
        const { commission } = formik.values;
        return parseFloat(commission[0].optionB) + parseFloat(commission[1].optionB) + parseFloat(commission[2].optionB) + parseFloat(commission[3].optionB) + parseFloat(commission[4].optionB) + parseFloat(commission[5].optionB);
    };
    const calculateTotalC = () => {
        const { commission } = formik.values;
        return parseFloat(commission[0].optionC) + parseFloat(commission[1].optionC) + parseFloat(commission[2].optionC) + parseFloat(commission[3].optionC) + parseFloat(commission[4].optionC) + parseFloat(commission[5].optionC);
    };
    const calculateTotalD = () => {
        const { commission } = formik.values;
        return parseFloat(commission[0].optionD) + parseFloat(commission[1].optionD) + parseFloat(commission[2].optionD) + parseFloat(commission[3].optionD) + parseFloat(commission[4].optionD) + parseFloat(commission[5].optionD);
    };
    const calculateTotalE = () => {
        const { commission } = formik.values;
        return parseFloat(commission[0].optionE) + parseFloat(commission[1].optionE) + parseFloat(commission[2].optionE) + parseFloat(commission[3].optionE) + parseFloat(commission[4].optionE) + parseFloat(commission[5].optionE);
    };
    const calculateTotalF = () => {
        const { commission } = formik.values;
        return parseFloat(commission[0].optionF) + parseFloat(commission[1].optionF) + parseFloat(commission[2].optionF) + parseFloat(commission[3].optionF) + parseFloat(commission[4].optionF) + parseFloat(commission[5].optionF);
    };
    // console.log('calc', calculateTotal())


    useEffect(() => {
        // dispatch(designationListTodo())
        dispatch(projectCommissionTodo(ProjectId))
    }, [])

    const editCommission = () => {

        Swal.fire({
            title: 'Update Commission',
            text: "Are you sure want to Reset Commission ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#105684',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'CANCLE',
            confirmButtonText: 'Reset Commission!'
        }).then((result) => {
            if (result.isConfirmed) {
                setReset(false)
                Swal.fire(
                    'Commission',
                    'Commission Reset Successfull.',
                    'success'
                )
               


            }
        })
    }




    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className='wrapper'>

                    {loading ?
                        <div>
                            <div className='d-flex justify-content-center mt-5'>
                                <BallTriangle
                                    height={100}
                                    width={100}
                                    radius={5}
                                    color="#105684"
                                    ariaLabel="ball-triangle-loading"
                                    wrapperclassName="justify-content-center"
                                    wrapperStyle={{}}
                                    visible={true}
                                />
                            </div>
                        </div>
                        :

                        <FormikProvider value={formik}>
                            <form onSubmit={(e) => { e.preventDefault(); formik.handleSubmit(); }}>
                                {/* <div className="row"> */}
                                <h5 style={{color:'#636262'}}>{state?.project_name}</h5>
                                <div className='d-flex align-items-center mb-2' >
                                    <label htmlFor="property_number" style={{ fontSize: "20px" }} className="form-label gh_label">Total Commission :</label>
                                    <input type="text" style={{ width: '250px' }} className="form-control gh_form ms-2" name='total_commission'
                                        value={formik.values.total_commission}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        readOnly={reset ? true :''}
                                    />
                                    <button className='btn gh_btn  ms-2' disabled={!reset ? true :''} onClick={editCommission} type='button' >Edit</button>

                                </div>
                                <FieldArray
                                    control
                                    setValue
                                    name="commission"
                                    render={(arrayHelpers) => (
                                        <>
                                            {formik.values.commission.map((field, index) => (
                                                <div className="row" key={index}>
                                                    <div className="col ">
                                                        <div className="mb-3">
                                                            {index == 0 ?
                                                                <label htmlFor="property_number" className="form-label gh_label">Designation</label>
                                                                : ''}
                                                            <input type="text" className="form-control gh_form" placeholder=""
                                                                name={`commission[${index}].designtaion`}
                                                                value={formik.values.commission[index].designtaion}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col">
                                                        <div className="mb-3">
                                                            {index == 0 ?
                                                                <label htmlFor="property_number" className="form-label gh_label">A</label>
                                                                : ''}
                                                            <input type="number" className="form-control gh_form" placeholder=""
                                                                name={`commission[${index}].optionA`}
                                                                id='totalA'
                                                                value={formik.values.commission[index].optionA}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                            {formik.touched.commission && formik.touched.commission[index] && formik.touched.commission[index].optionA && formik.errors.commission && formik.errors.commission[index] && formik.errors.commission[index].optionA ?
                                                                (<span className='text-danger form_label'> {formik.errors.commission[index].optionA}</span>) : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="mb-3">
                                                            {index == 0 ?
                                                                <label htmlFor="property_number" className="form-label gh_label">B</label>
                                                                : ''}
                                                            <input type="number" className="form-control gh_form" placeholder=""
                                                                name={`commission[${index}].optionB`}
                                                                value={formik.values.commission[index].optionB}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                             {formik.touched.commission && formik.touched.commission[index] && formik.touched.commission[index].optionB && formik.errors.commission && formik.errors.commission[index] && formik.errors.commission[index].optionB ?
                                                                (<span className='text-danger form_label'> {formik.errors.commission[index].optionB}</span>) : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="mb-3">
                                                            {index == 0 ?
                                                                <label htmlFor="property_number" className="form-label gh_label">C</label>
                                                                : ''}
                                                            <input type="number" className="form-control gh_form" placeholder=""
                                                                name={`commission[${index}].optionC`}
                                                                value={formik.values.commission[index].optionC}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                               {formik.touched.commission && formik.touched.commission[index] && formik.touched.commission[index].optionC && formik.errors.commission && formik.errors.commission[index] && formik.errors.commission[index].optionC ?
                                                                (<span className='text-danger form_label'> {formik.errors.commission[index].optionC}</span>) : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="mb-3">
                                                            {index == 0 ?
                                                                <label htmlFor="property_number" className="form-label gh_label">D</label>
                                                                : ''}
                                                            <input type="number" className="form-control gh_form" placeholder=""
                                                                name={`commission[${index}].optionD`}
                                                                value={formik.values.commission[index].optionD}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                              {formik.touched.commission && formik.touched.commission[index] && formik.touched.commission[index].optionD && formik.errors.commission && formik.errors.commission[index] && formik.errors.commission[index].optionD ?
                                                                (<span className='text-danger form_label'> {formik.errors.commission[index].optionD}</span>) : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="mb-3">
                                                            {index == 0 ?
                                                                <label htmlFor="property_number" className="form-label gh_label">E</label>
                                                                : ''}
                                                            <input type="number" className="form-control gh_form" placeholder=""
                                                                name={`commission[${index}].optionE`}
                                                                value={formik.values.commission[index].optionE}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                             {formik.touched.commission && formik.touched.commission[index] && formik.touched.commission[index].optionE && formik.errors.commission && formik.errors.commission[index] && formik.errors.commission[index].optionE ?
                                                                (<span className='text-danger form_label'> {formik.errors.commission[index].optionE}</span>) : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="mb-3">
                                                            {index == 0 ?
                                                                <label htmlFor="property_number" className="form-label gh_label">F</label>
                                                                : ''}
                                                            <input type="number" className="form-control gh_form" placeholder=""
                                                                name={`commission[${index}].optionF`}
                                                                value={formik.values.commission[index].optionF}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                             {formik.touched.commission && formik.touched.commission[index] && formik.touched.commission[index].optionF && formik.errors.commission && formik.errors.commission[index] && formik.errors.commission[index].optionF ?
                                                                (<span className='text-danger form_label'> {formik.errors.commission[index].optionF}</span>) : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                />
                                <hr />
                                <div className="row">
                                    <div className="col">
                                        <span className="form-control gh_form">
                                            Total
                                        </span>
                                    </div>
                                    <div className="col">
                                        <span className="form-control gh_form" >
                                            {calculateTotalA().toFixed(2)}
                                        </span>
                                    </div>
                                    <div className="col">
                                        <span className="form-control gh_form" >
                                            {calculateTotalB().toFixed(2)}
                                        </span>
                                    </div>
                                    <div className="col">
                                        <span className="form-control gh_form" >
                                            {calculateTotalC().toFixed(2)}
                                        </span>
                                    </div>
                                    <div className="col">
                                        <span className="form-control gh_form" >
                                            {calculateTotalD().toFixed(2)}

                                        </span>
                                    </div>
                                    <div className="col">
                                        <span className="form-control gh_form" >
                                            {calculateTotalE().toFixed(2)}

                                        </span>
                                    </div>
                                    <div className="col">
                                        <span className="form-control gh_form" >
                                            {calculateTotalF().toFixed(2)}
                                        </span>
                                    </div>
                                </div>
                                <div className='text-end'>
                                    {/* {console.log('A1', calculateTotalA(), calculateTotalB(), calculateTotalC(), calculateTotalD(), calculateTotalE(), calculateTotalF())} */}

                                    <button className='btn gh_btn mt-3' disabled={((formik.values.total_commission == calculateTotalA()) && (formik.values.total_commission == calculateTotalB()) && (formik.values.total_commission == calculateTotalC()) && (formik.values.total_commission == calculateTotalD()) && (formik.values.total_commission == calculateTotalE()) && (formik.values.total_commission == calculateTotalF())) ? '' : true} type='submit' >Submit</button>
                                </div>
                            </form>
                        </FormikProvider>}
                    </div>
                </div>
            </div >
            <ToastContainer />
        </>
    );
}

export default AddProjectCommission;
