import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var updatePropertyRes;
export const updatePropertyTodo = createAsyncThunk('updateProperty', async (data) => {
    console.log("data--", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'update_propertyid',
            data: data,
            headers: { "Content-Type": "application/json" }
        })
        return updatePropertyRes = res.data

    } catch (error) {
        return updatePropertyRes = error.response.data
    }
})