import React, { useState } from 'react';
import DataTbl from '../../table/table';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { bookingLIstRes, bookingLIstTodo } from '../../redux/slices/bookingListSlice';
import { Link, useNavigate } from 'react-router-dom';
import { BiSolidEdit } from "react-icons/bi";
import Swal from 'sweetalert2'
import { FiMonitor } from "react-icons/fi";
import { BallTriangle } from 'react-loader-spinner'
import { commissionSetRes, commissionSetTodo } from '../../redux/slices/booking/commissionSet';
import { ToastContainer, toast } from 'react-toastify';
import { BsCheckCircle } from "react-icons/bs";
import { RxCrossCircled } from "react-icons/rx";
import { bookingRejectRes, bookingRejectTodo } from '../../redux/slices/booking/bookingReject';

const BookingList = () => {

    const [loading, setLoading] = useState(true)
    const [bool, setBool] = useState(false)
    const [editBooking, setEditBooking] = useState(false)
    const [bookingData, setBookingData] = useState('')

    const dispatch = useDispatch()
    const data = useSelector(state => state && state.bookingList && state.bookingList.data && state.bookingList.data.data && state.bookingList.data.data[1]);

    const navigate = useNavigate();

    const userDetailsLink = (value) => {
        navigate('../BookingDetails', { state: value })
    }

    const handleUpdateDeal = (val) => {
        setEditBooking(true)
        setBookingData(val)
    }

    const booking_reject = (id) => {
        Swal.fire({
            title: "Booking Reject",
            text: "Are You Sure Want To Reject This Booking ? All Commissions will be Deleted",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Reject'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(bookingRejectTodo(id)).then(() => delete_res())
            }
        })
    }
    const delete_res = () => {
        if (bookingRejectRes && bookingRejectRes.success) {
            Swal.fire(
                "Booking",
                'Booking Rejected Successfully',
                'success'
            )
            setBool(!bool)
        } else {
            toast.error(bookingRejectRes && bookingRejectRes.message, { position: "bottom-right" })
        }
    }

    const exportcolumn = ['project_master.project_name', 'property_detail.property_number', "customer.name", "booking_amount", "user.username", "added_date", "booking_status"]

    const [columnDefs1, setColumnDefs1] = useState([
        {
            headerName: 'S.No.',
            valueGetter: 'node.rowIndex + 1',
            wrapText: true,
            maxWidth: 90,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },
        {
            headerName: "Project Name",
            field: "project_master.project_name",
            wrapText: true,
            cellRenderer: params => <span style={{ textTransform: 'capitalize' }}>{params && params.data && params.data.project_master && params.data.project_master.project_name}</span>,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '200px'
        },
        {
            headerName: "Plot No.",
            field: "property_detail.property_number",
            wrapText: true,
            cellRenderer: params => <span style={{ textTransform: 'capitalize' }}>{params && params.data && params.data.property_detail && params.data.property_detail.property_number}</span>,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '100px'
        },

        {
            headerName: "Customer",
            field: "customer.name",
            cellRenderer: params => <span style={{ textTransform: 'capitalize' }}>{params && params.data && params.data.customer && params.data.customer.name}</span>,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '130px'
        },
        {
            headerName: "Deal Amount",
            field: "booking_amount",

            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '130px'
        },
        {
            headerName: "Booked By",
            field: "user.username",
            cellRenderer: params => <span style={{ textTransform: 'capitalize' }}>{params && params.data && params.data.user && params.data.user.username}</span>,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '220px'
        },
        {
            headerName: "Booking Date",
            field: "booking_date",
            cellRenderer: params => params?.data?.booking_date === null ? '-' : <span style={{ textTransform: 'capitalize' }}>{new Date(params?.data?.booking_date).toLocaleDateString('en-GB')}</span>,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '130px'
        },
        {
            headerName: "Added Date",
            field: "added_date",
            cellRenderer: params => params?.data?.added_date === null ? '-' : <span style={{ textTransform: 'capitalize' }}>{new Date(params && params.data && params.data.added_date).toLocaleDateString('en-GB')}</span>,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '130px'
        },
        {
            headerName: "Booking Status",
            field: "booking_status",
            cellRenderer: params => <span style={{ textTransform: "capitalize" }} className={params && params.data && params.data.booking_status === 'available' ? 'status_availabel' : (params && params.data && params.data.booking_status) === 'hold' ? 'status_hold' : (params && params.data && params.data.booking_status) === 'booked' ? 'status_booked' : (params && params.data && params.data.booking_status) === 'rejected' ? 'status_rejected' : ''} >
                {params.data.booking_status}</span>,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, width: '130px'
        },

        {
            headerName: "Action",
            wrapText: true,
            cellRenderer: params => <div className='viewEdit_btn'>

                {/* <span><BiSolidEdit /></span> */}
                {params && params.data && params.data.booking_status === 'hold' ? <span> <BiSolidEdit onClick={() => handleUpdateDeal(params && params.data)} size={22} style={{ color: '#47BB4C', cursor: "pointer" }} /></span> : ''}
                {/* {params && params.data && params.data.booking_status  === 'hold' ?  <span> <RxCrossCircled onClick={() => { booking_reject(params && params.data && params.data.id) }} size={20} className='ms-1' style={{ color: '#EF3D50', cursor: "pointer" }} /></span>:''} */}
                <span className='ms-1' onClick={() => userDetailsLink(params && params.data)}><FiMonitor style={{ color: '#105684', cursor: "pointer" }} /></span>
                {/* <button style={{fontSize: '12px', padding: '5px', margin: '0px'}} className='btn gh_btn' onClick={() => approved_fun(params && params.data)}>Approve</button> */}
                {params && params.data && params.data.booking_status === 'hold' || params && params.data && params.data.booking_status === 'booked' ? <span> <RxCrossCircled onClick={() => { booking_reject(params && params.data && params.data.id) }} size={20} className='ms-1' style={{ color: '#EF3D50', cursor: "pointer" }} /></span> : ''}
            </div>,
            autoHeight: true,
            width: '100px',
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true,
        },

    ])

    useEffect(() => {
        dispatch(bookingLIstTodo()).then(() => BookingList_fun())
    }, [bool])

    const BookingList_fun = () => {
        if (bookingLIstRes && bookingLIstRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='scnd_header'>
                            <span className='icon1'>Booking / </span>
                            <span className='icon2'> Booking List</span>
                        </div>
                        {loading ?
                            <div className='d-flex justify-content-center mt-5'>
                                <BallTriangle
                                    height={100}
                                    width={100}
                                    radius={5}
                                    color="#105684"
                                    ariaLabel="ball-triangle-loading"
                                    wrapperclassName="justify-content-center"
                                    wrapperStyle={{}}
                                    visible={true}
                                />
                            </div>
                            :
                            data && data.length
                                ?
                                <div className='wrapper'>
                                    <DataTbl
                                        data={data ? data : ''}
                                        columnDefs={columnDefs1}
                                        exportcolumn={exportcolumn}
                                    />
                                </div>
                                :
                                <div className='empty_data_msg text-center mt-5'>
                                    <h4>Booking List Not Found</h4>
                                </div>
                        }
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default BookingList;
