import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var bookingInstallmentListRes;
export const bookingInstallmentListTodo = createAsyncThunk('bookingInstallmentList', async (id) => {
    // console.log("data", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'instalment',
            data: {
                "booking_id": id
            },
            headers: { "Content-Type": "application/json" }
        })
        return bookingInstallmentListRes = res.data

    } catch (error) {
        return bookingInstallmentListRes = error.response.data
    }
});

const bookingInstallmentListSlice = createSlice({
    name: 'bookingInstallmentList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(bookingInstallmentListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('userList', state.data)
        });
        builder.addCase(bookingInstallmentListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default bookingInstallmentListSlice.reducer; 
